import React from "react";
import ItemComponent from "../../components/final_components/ItemDetailComponent";

const ItemDetailPage = () => {
  return (
    <div className="">
      <ItemComponent />
    </div>
  );
};

export default ItemDetailPage;
