import React from "react";
import "./spinner.css"; // Custom CSS for keyframes

const LoadingSpinner = () => {
  return (
    /* From Uiverse.io by bociKond */
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
