import React from "react";
import CheckoutForm from "../../components/final_components/BillingDetails";
import { BsCheckAll } from "react-icons/bs";

const BillingPage = () => {
  return (
    <div>
      <div
        className="relative bg-cover bg-center h-64 flex flex-col justify-center items-center text-center"
        style={{
          backgroundImage: "url('./shop_hero.jpg')", // Replace with your image path
          backgroundSize: "cover",
          backgroundPosition: "center",
          //   filter: "blur(4px)",
        }}
      >
        {/* Overlay to darken the image */}
        <div className="absolute inset-0 bg-black opacity-20"></div>

        {/* Content */}
        <div className="relative z-10">
          <div className="mb-2">
            {/* Placeholder for the icon, replace with actual SVG or image */}
            <BsCheckAll size={40} className="text-white inline-block" />
          </div>
          <h1 className="text-4xl font-bold text-white">Checkout</h1>
          <div className="mt-2 text-sm text-white">
            <a href="/" className="hover:underline">
              Home
            </a>{" "}
            &gt; <span>Contact</span>
          </div>
        </div>
      </div>
      <CheckoutForm />
    </div>
  );
};

export default BillingPage;
