// src/components/Auth/Login.jsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../api"; // Ensure this function is correctly implemented
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // Loading state to disable the button while processing
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const { data } = await loginUser({ email, password });
      toast.success("Login successful! Redirecting to your profile...");
      setIsLoading(false); // Stop loading

      // Update AuthContext with the new user data
      // Assuming `loginUser` sets the necessary authentication tokens (e.g., cookies)

      // Redirect to the Profile page after a short delay to allow the toast to display
      setTimeout(() => {
        window.location.href = "/"; // This will reload the page
      }, 1500); // 1.5 seconds delay
    } catch (error) {
      console.error("Login failed", error);
      const errorMessage =
        error.response?.data?.message || "Failed to log in. Please try again.";
      toast.error(`Login failed: ${errorMessage}`);
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      {/* Helmet for SEO optimization */}
      <Helmet>
        <title>Login - Knots & Krafts</title>
        <meta
          name="description"
          content="Sign in to your Knots & Krafts account to access your profile, track orders, and enjoy personalized shopping."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Left Column: Image */}
      <div className=" lg:block w-full lg:w-1/2 lg:h-screen">
        <img
          src="./hero2.webp" // Replace with your image path
          alt="Login to Knots & Krafts"
          className="w-full lg:h-full h-[30vh] object-cover"
        />
      </div>

      {/* Right Column: Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center px-8 py-12">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-900">Welcome Back</h1>
            <p className="mt-2 text-sm text-gray-600">
              Don't have an account?{" "}
              <a
                href="/register"
                className="font-medium text-pink-600 hover:text-pink-500"
              >
                Sign up
              </a>
            </p>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-5">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                  placeholder="you@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                  placeholder="Your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className={`w-full flex justify-center py-3 px-6 border border-transparent rounded-md shadow-sm text-lg font-medium bg-pink text-white bg-pink-600 hover:bg-pink-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading ? "Signing In..." : "Sign In"}
              </button>
            </div>
          </form>
          {/* ToastContainer for notifications */}
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
