// src/components/CategoryManagement.jsx

import React, { useEffect, useState, useRef } from "react";
import {
  getAllCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import { FaEdit, FaTrash, FaPlus, FaTimes } from "react-icons/fa";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Custom Styles for Modal
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    width: "90%",
    padding: "20px",
    borderRadius: "8px",
    border: "none",
    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

// Helper function to validate category data
const validateCategory = ({ name }) => {
  if (!name.trim()) return "Category name is required.";
  return null;
};

// Placeholder image for categories without an image (if applicable)
const placeholderImage = "https://via.placeholder.com/64";

// Lazy load images using the loading attribute
const LazyImage = ({ src, alt }) => (
  <img
    src={src || placeholderImage}
    alt={alt || "Category Image"}
    loading="lazy"
    className="w-16 h-16 object-cover rounded-full"
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = placeholderImage;
    }}
  />
);

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
  });
  const [formError, setFormError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const formRef = useRef(null);
  const listRef = useRef(null);

  // Fetch all categories
  const fetchCategories = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getAllCategories();
      setCategories(response.data);
    } catch (error) {
      setError("Error fetching categories.");
      toast.error("Error fetching categories.");
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // GSAP Animation for Form and List
  useEffect(() => {
    if (formRef.current) {
      gsap.fromTo(
        formRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 0.5, ease: "power3.out" }
      );
    }
    if (listRef.current) {
      gsap.fromTo(
        listRef.current.children,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power3.out",
          stagger: 0.1,
          scrollTrigger: {
            trigger: listRef.current,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }
  }, [categories]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission for create
  const handleCreateCategory = async (e) => {
    e.preventDefault();
    setFormError("");

    const validationError = validateCategory(formData);
    if (validationError) {
      setFormError(validationError);
      toast.error(validationError);
      return;
    }

    try {
      const { data } = await createCategory({ name: formData.name });
      setCategories([...categories, data]);
      setFormData({ name: "" });
      toast.success("Category created successfully.");
    } catch (error) {
      setFormError("Error creating category.");
      toast.error("Error creating category.");
      console.error("Error creating category:", error);
    }
  };

  // Open delete confirmation modal
  const openDeleteModal = (category) => {
    setCategoryToDelete(category);
    setDeleteModalIsOpen(true);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setCategoryToDelete(null);
    setDeleteModalIsOpen(false);
  };

  // Handle delete confirmation
  const handleDeleteCategory = async () => {
    if (!categoryToDelete) return;
    try {
      await deleteCategory(categoryToDelete._id);
      setCategories(categories.filter((cat) => cat._id !== categoryToDelete._id));
      toast.success("Category deleted successfully.");
    } catch (error) {
      toast.error("Error deleting category.");
      console.error("Error deleting category:", error);
    } finally {
      closeDeleteModal();
    }
  };

  // Open edit modal
  const openEditModal = (category) => {
    setCategoryToEdit(category);
    setFormData({ name: category.name });
    setIsEditing(true);
    setEditCategoryId(category._id);
    setEditModalIsOpen(true);
  };

  // Close edit modal
  const closeEditModal = () => {
    setCategoryToEdit(null);
    setFormData({ name: "" });
    setIsEditing(false);
    setEditCategoryId(null);
    setEditModalIsOpen(false);
    setFormError("");
  };

  // Handle form submission for update
  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    setFormError("");

    const validationError = validateCategory(formData);
    if (validationError) {
      setFormError(validationError);
      toast.error(validationError);
      return;
    }

    try {
      await updateCategory(editCategoryId, { name: formData.name });
      setCategories(
        categories.map((category) =>
          category._id === editCategoryId
            ? { ...category, name: formData.name }
            : category
        )
      );
      toast.success("Category updated successfully.");
      closeEditModal();
    } catch (error) {
      setFormError("Error updating category.");
      toast.error("Error updating category.");
      console.error("Error updating category:", error);
    }
  };

  // Render table headers
  const renderTableHeaders = () => (
    <thead>
      <tr className="bg-gray-100 text-gray-600">
        <th className="px-4 py-2 border-b">Name</th>
        <th className="px-4 py-2 border-b text-center">Actions</th>
      </tr>
    </thead>
  );

  // Render table rows
  const renderTableRows = () =>
    categories.map((category) => (
      <tr key={category._id} className="hover:bg-gray-50 transition-colors">
        <td className="px-4 py-2 border-b text-gray-800">{category.name}</td>
        <td className="px-4 py-2 border-b text-center space-x-2">
          <button
            onClick={() => openEditModal(category)}
            className="flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-1 rounded-md shadow-md transition duration-200"
            aria-label={`Edit category ${category.name}`}
          >
            <FaEdit className="mr-1" /> Edit
          </button>
          <button
            onClick={() => openDeleteModal(category)}
            className="flex items-center justify-center bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded-md shadow-md transition duration-200"
            aria-label={`Delete category ${category.name}`}
          >
            <FaTrash className="mr-1" /> Delete
          </button>
        </td>
      </tr>
    ));

  // Render coupon cards for mobile
  const renderCategoryCards = () =>
    categories.map((category) => (
      <div
        key={category._id}
        className="bg-white p-6 rounded-lg shadow-md border border-gray-200 flex flex-col space-y-4"
      >
        <div className="flex justify-between items-center">
          <span className="text-lg font-medium text-gray-900">{category.name}</span>
          <div className="flex space-x-2">
            <button
              onClick={() => openEditModal(category)}
              className="flex items-center justify-center bg-pink hover:bg-yellow-600 text-white px-3 py-1 rounded-md shadow-md transition duration-200"
              aria-label={`Edit category ${category.name}`}
            >
              <FaEdit className="mr-1" /> Edit
            </button>
            <button
              onClick={() => openDeleteModal(category)}
              className="flex items-center justify-center bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded-md shadow-md transition duration-200"
              aria-label={`Delete category ${category.name}`}
            >
              <FaTrash className="mr-1" /> Delete
            </button>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      {/* SEO Meta Tags using react-helmet */}
      <Helmet>
        <title>Category Management | Your Store</title>
        <meta
          name="description"
          content="Manage all your store's categories, create new ones, edit existing ones, and delete unwanted categories."
        />
      </Helmet>

      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <h1 className="text-4xl font-extrabold text-gray-900 mb-8 text-center">
        Category Management
      </h1>

      {/* Create Category Form */}
      <form
        onSubmit={handleCreateCategory}
        className="bg-white p-6 rounded-lg shadow-md mb-6"
        ref={formRef}
      >
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <div className="flex-1 mb-4 sm:mb-0">
            <label
              htmlFor="name"
              className="block text-gray-700 font-semibold mb-2"
            >
              Category Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 bg-gray-100 text-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter category name"
              required
            />
          </div>
          <div className="flex-shrink-0">
            <button
              type="submit"
              className="w-full sm:w-auto flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-md shadow-md transition duration-300 ease-in-out"
              aria-label={isEditing ? "Update Category" : "Add Category"}
            >
              <FaPlus className="mr-2" />
              {isEditing ? "Update Category" : "Add Category"}
            </button>
          </div>
        </div>
        {formError && (
          <p className="text-red-500 text-sm mt-2 text-center">
            {formError}
          </p>
        )}
      </form>

      {/* Category List */}
      <div id="coupon-list" ref={listRef}>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <svg
              className="animate-spin h-10 w-10 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          </div>
        ) : error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : categories.length === 0 ? (
          <div className="text-center text-gray-600">No categories available.</div>
        ) : (
          <>
            {/* Desktop/Tablets: Table Layout */}
            <div className="hidden md:block overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                {renderTableHeaders()}
                <tbody>{renderTableRows()}</tbody>
              </table>
            </div>

            {/* Mobile: Card Layout */}
            <div className="md:hidden space-y-4">
              {renderCategoryCards()}
            </div>
          </>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirm Delete"
        style={customModalStyles}
        ariaHideApp={false}
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p className="mb-6">
          Are you sure you want to delete the category "{categoryToDelete?.name}"?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeDeleteModal}
            className="flex items-center justify-center px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200 focus:outline-none"
          >
            <FaTimes className="mr-2" />
            Cancel
          </button>
          <button
            onClick={handleDeleteCategory}
            className="flex items-center justify-center px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 focus:outline-none"
          >
            <FaTrash className="mr-2" />
            Delete
          </button>
        </div>
      </Modal>

      {/* Edit Category Modal */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Category"
        style={customModalStyles}
        ariaHideApp={false}
      >
        <h2 className="text-xl font-semibold mb-4">Edit Category</h2>
        <form onSubmit={handleUpdateCategory} className="space-y-4">
          <div>
            <label
              htmlFor="edit-name"
              className="block text-gray-700 font-semibold mb-2"
            >
              Category Name
            </label>
            <input
              type="text"
              id="edit-name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 bg-gray-100 text-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter new category name"
              required
            />
          </div>
          {formError && (
            <p className="text-red-500 text-sm text-center">
              {formError}
            </p>
          )}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={closeEditModal}
              className="flex items-center justify-center px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200 focus:outline-none"
            >
              <FaTimes className="mr-2" />
              Cancel
            </button>
            <button
              type="submit"
              className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 focus:outline-none"
            >
              <FaEdit className="mr-2" />
              Save Changes
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CategoryManagement;
