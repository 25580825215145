// src/components/FavoritesPage.js
import React, { useEffect, useState, useContext } from "react";
import { getUserFavorites, removeFromFavorites } from "../../api";
import { Link, useNavigate } from "react-router-dom";
import { FaHeart, FaTimes } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../context/AuthContext"; // Adjust the path as needed

const FavoritesPage = () => {
  const { user, loading: authLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!authLoading && !user) {
      navigate("/login");
    }
  }, [authLoading, user, navigate]);

  // Fetch user favorites on component mount
  useEffect(() => {
    // Only fetch favorites if the user is authenticated
    if (user) {
      const loadFavorites = async () => {
        try {
          const { data } = await getUserFavorites();
          setFavorites(data);
        } catch (err) {
          setError("Failed to load favorites. Please try again later.");
          console.error("Failed to load favorites:", err);
        } finally {
          setLoading(false);
        }
      };
      loadFavorites();
    }
  }, [user]);

  // Handle removing a product from favorites
  const handleRemoveFavorite = async (productId) => {
    try {
      await removeFromFavorites(productId);
      setFavorites(favorites.filter((fav) => fav._id !== productId));
    } catch (err) {
      setError("Failed to remove favorite. Please try again later.");
      console.error("Failed to remove favorite:", err);
    }
  };

  // Close error message
  const handleCloseError = () => {
    setError(null);
  };

  // Show a loading indicator while authentication is being checked
  if (authLoading || loading) {
    return (
      <div className=" bg-gray-50 text-gray-900 p-6 md:p-24 font-poppins">
        <h1 className="text-4xl font-semibold mb-6 text-center">
          My Favorites
        </h1>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 animate-pulse">
          {[...Array(8)].map((_, index) => (
            <div key={index} className="bg-gray-200 h-64 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {/* SEO Optimization with React Helmet */}
      <Helmet>
        <title>My Favorites | Knots & Krafts</title>
        <meta
          name="description"
          content="View and manage your favorite products at Knots & Krafts. Handcrafted woolen threads to support your creative projects."
        />
        <meta
          name="keywords"
          content="Favorites, Knots & Krafts, woolen threads, handcrafted, creative projects"
        />
      </Helmet>

      <div className=" bg-gray-50 text-gray-900 p-6 md:p-24 font-poppins">
        <h1 className="text-4xl font-semibold mb-6 text-center">
          My Favorites
        </h1>

        {/* Error Message */}
        {error && (
          <div className="bg-red-600 text-white p-4 rounded-md mb-6 flex items-center justify-between">
            <span>{error}</span>
            <button
              onClick={handleCloseError}
              aria-label="Close error message"
              className="text-white hover:text-gray-200 focus:outline-none"
            >
              <FaTimes />
            </button>
          </div>
        )}

        {/* Loading State with Skeleton Loaders */}
        {loading ? (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 animate-pulse">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="bg-gray-200 h-64 rounded-lg"></div>
            ))}
          </div>
        ) : favorites.length > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {favorites.map((product) => (
              <div
                key={product._id}
                className="bg-white p-4 rounded-lg shadow-md flex flex-col"
              >
                {/* Product Image */}
                <Link to={`/shop/${product._id}`} className="flex-1">
                  <img
                    src={`${process.env.REACT_APP_URL}/${product.images[0]?.url}`}
                    alt={product.name}
                    className="w-full h-48 object-cover rounded-md mb-4 transition-transform duration-300 hover:scale-105"
                    loading="lazy"
                  />
                </Link>

                {/* Product Details */}
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold">{product.name}</h3>
                    <button
                      onClick={() => handleRemoveFavorite(product._id)}
                      aria-label={`Remove ${product.name} from favorites`}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      <FaHeart className="text-2xl" />
                    </button>
                  </div>
                  <p className="text-gray-700 mb-2">
                    ₹{product.price.sale || product.price.regular}
                    {product.price.sale && (
                      <span className="line-through text-gray-500 text-sm ml-2">
                        ₹{product.price.regular}
                      </span>
                    )}
                  </p>
                </div>

                {/* View Product Link */}
                <Link
                  to={`/shop/${product._id}`}
                  className="mt-4 inline-block text-blue-600 hover:underline"
                >
                  View Product
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-xl">
            <img src="./fav.png" alt="No favorites" />
            <p className="mt-4">
              No favorites yet.{" "}
              <Link
                to="/shop"
                className="text-blue-600 hover:underline font-semibold"
              >
                Add some products
              </Link>{" "}
              to your favorites list!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default FavoritesPage;
