import React, { useState, useRef } from "react";

const ZoomImage = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");
  const imgRef = useRef(null);

  const calculatePosition = (e) => {
    const { left, top, width, height } = imgRef.current.getBoundingClientRect();
    const x = ((e.pageX - left - window.scrollX) / width) * 100;
    const y = ((e.pageY - top - window.scrollY) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  const handleMouseMove = (e) => {
    if (!isZoomed) return;
    calculatePosition(e);
  };

  const handleTouchMove = (e) => {
    if (!isZoomed) return;
    e.preventDefault(); // Prevent scrolling when touching the image
    if (e.touches.length === 1) {
      calculatePosition(e.touches[0]);
    }
  };

  const handleMouseEnter = () => setIsZoomed(true);
  const handleMouseLeave = () => setIsZoomed(false);

  const handleTouchStart = () => setIsZoomed(true);
  const handleTouchEnd = () => setIsZoomed(false);

  return (
    <div>
      <div
        className="w-full h-auto overflow-hidden mb-4"
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        ref={imgRef}
      >
        <img
          src={src}
          alt={alt}
          className={`w-full h-auto transition-transform duration-300 ease-in-out ${
            isZoomed ? "scale-150" : "scale-100"
          }`}
          style={{
            transformOrigin: backgroundPosition,
          }}
        />
      </div>
    </div>
  );
};

export default ZoomImage;
