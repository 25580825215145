// src/components/Shop.jsx

import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  fetchProducts,
  getAllCategories,
  getAllTags,
  getAllBrands,
} from "../../api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BsFilter } from "react-icons/bs";
import FilterComponents from "./FilterComponents";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Lazy load the ProductCard component
const ProductCard = lazy(() =>
  import("../../components/final_components/ProductCard")
);

// Reusable Section Component
const Section = ({ title, products }) => (
  <div className="mb-16">
    <h2 className="text-2xl sm:text-3xl md:text-3xl font-poppins font-extrabold text-center text-gray-800 mb-8 tracking-wide">
      {title}
    </h2>
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
      {products.map((product) => (
        <ProductCard key={product._id} product={product} />
      ))}
    </div>
  </div>
);

// Skeleton Loader for Product Cards
const SkeletonProductCard = () => (
  <div className="p-4 border rounded-md shadow-sm">
    <Skeleton height={200} />
    <Skeleton height={20} style={{ marginTop: 10 }} />
    <Skeleton height={20} width={80} style={{ marginTop: 10 }} />
  </div>
);

// Skeleton Grid Fallback for Suspense
const SkeletonGrid = () => (
  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonProductCard key={index} />
    ))}
  </div>
);

const Shop = () => {
  // State variables
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize filter states from URL parameters
  const initialSelectedCategory = searchParams.get("categoryIds") || "";
  const initialSelectedTag = searchParams.get("tagIds") || "";
  const initialSelectedBrand = searchParams.get("brandIds") || "";

  const initialMinPrice = searchParams.get("minPrice")
    ? Number(searchParams.get("minPrice"))
    : 30;
  const initialMaxPrice = searchParams.get("maxPrice")
    ? Number(searchParams.get("maxPrice"))
    : 1000;
  const initialSortBy = searchParams.get("sortBy") || "bestSelling";

  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );
  const [selectedTag, setSelectedTag] = useState(initialSelectedTag);
  const [selectedBrand, setSelectedBrand] = useState(initialSelectedBrand);
  const [minPrice, setMinPrice] = useState(initialMinPrice);
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [priceRange, setPriceRange] = useState([
    initialMinPrice,
    initialMaxPrice,
  ]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const navigate = useNavigate();

  // Fetch categories, tags, and brands
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const [categoryResponse, tagResponse, brandResponse] =
          await Promise.all([getAllCategories(), getAllTags(), getAllBrands()]);

        setCategories(categoryResponse.data);
        setTags(tagResponse.data);

        // **Ensure brands are an array of strings (brand names)**
        if (Array.isArray(brandResponse.data)) {
          // Assuming each brand object has a 'name' property
          setBrands(
            brandResponse.data.map((brand) =>
              typeof brand === "string" ? brand : brand.name
            )
          );
        } else if (Array.isArray(brandResponse.data.brands)) {
          setBrands(
            brandResponse.data.brands.map((brand) =>
              typeof brand === "string" ? brand : brand.name
            )
          );
        } else {
          setBrands([]);
        }
      } catch (err) {
        console.error("Error loading initial data:", err);
        setError("Failed to load initial data.");
        toast.error("Failed to load initial data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  // Fetch products data
  const fetchProductsData = async (filters) => {
    setLoading(true);
    setError("");

    try {
      const response = await fetchProducts(filters);

      if (response.data.products.length === 0) {
        // No products found
        // Reset filters to defaults
        setSelectedCategory("");
        setSelectedTag("");
        setSelectedBrand("");
        setMinPrice(30);
        setMaxPrice(1000);
        setPriceRange([30, 1000]);
        setSortBy("bestSelling");

        // Update URL parameters
        setSearchParams(new URLSearchParams());

        // Fetch all products
        const allProductsResponse = await fetchProducts({
          pageNumber: 1,
        });
        setProducts(allProductsResponse.data.products);
        setHasMore(allProductsResponse.data.hasMore);
        setPageNumber(1);

        // Show toast notification
        toast.info(
          "No products found for the selected filters. Showing all products."
        );
      } else {
        setProducts(response.data.products);
        setHasMore(response.data.hasMore);
        setPageNumber(1);
      }
    } catch (error) {
      setError("Failed to load products.");
      toast.error("Failed to load products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch products on initial mount
  useEffect(() => {
    fetchProductsData({
      categoryIds: selectedCategory,
      tagIds: selectedTag,
      minPrice,
      maxPrice,
      sortBy,
      pageNumber: 1,
      brandIds: selectedBrand,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Define handleApplyFilters function
  const handleApplyFilters = () => {
    setIsFilterOpen(false); // Close the filter modal on mobile

    fetchProductsData({
      categoryIds: selectedCategory,
      tagIds: selectedTag,
      minPrice,
      maxPrice,
      sortBy,
      pageNumber: 1,
      brandIds: selectedBrand,
    });
  };

  // Update URL parameters when filters change
  useEffect(() => {
    const params = new URLSearchParams();

    if (selectedCategory) params.set("categoryIds", selectedCategory);
    if (selectedTag) params.set("tagIds", selectedTag);
    if (selectedBrand) params.set("brandIds", selectedBrand);
    if (minPrice !== 30) params.set("minPrice", minPrice);
    if (maxPrice !== 1000) params.set("maxPrice", maxPrice);
    if (sortBy !== "bestSelling") params.set("sortBy", sortBy);

    setSearchParams(params);
  }, [
    selectedCategory,
    selectedTag,
    selectedBrand,
    minPrice,
    maxPrice,
    sortBy,
    setSearchParams,
  ]);

  // Function to load more products
  const loadMoreProducts = async () => {
    try {
      setLoading(true);
      const response = await fetchProducts({
        categoryIds: selectedCategory,
        tagIds: selectedTag,
        minPrice,
        maxPrice,
        sortBy,
        pageNumber: pageNumber + 1,
        brandIds: selectedBrand,
      });
      setProducts((prevProducts) => [
        ...prevProducts,
        ...response.data.products,
      ]);
      setHasMore(response.data.hasMore);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } catch (err) {
      setError("Failed to load more products.");
      toast.error("Failed to load more products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Handle price range change from FilterComponents
  const handlePriceChange = (newRange) => {
    setMinPrice(newRange[0]);
    setMaxPrice(newRange[1]);
    setPriceRange(newRange);
  };

  return (
    <div className="relative">
      <Helmet>
        <title>Shop - Browse Products</title>
        <meta
          name="description"
          content="Browse a wide range of products in our online store. Filter by category, tags, brands, and price range to find exactly what you need."
        />
        <meta
          name="keywords"
          content="shop, products, buy online, categories, tags, brands, price range"
        />
      </Helmet>
      {/* Include the ToastContainer */}
      <ToastContainer position="top-right" autoClose={5000} />

      {/* Hero Section */}
      <div className="relative h-[20vh] w-screen overflow-hidden">
        <img
          src="./shop_hero.jpg"
          className="object-cover absolute top-0 left-0 w-full h-full"
          alt="hero section"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-30"></div>
        {/* Title */}
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-4xl font-bold">Shop</h1>
        </div>
      </div>

      {/* Main Container */}
      <div className="container mx-auto py-6 px-4">
        {/* Breadcrumb */}
        <nav className="text-gray-600 text-sm mb-4">
          <Link to="/" className="hover:underline">
            Home
          </Link>
          <span className="mx-2">&gt;</span>
          <span className="text-gray-800">Shop</span>
        </nav>

        {/* Filters and Products */}
        <div className="flex flex-col md:flex-row">
          {/* Filter Sidebar for Desktop */}
          <div className="hidden md:block md:w-1/4 md:pr-4">
            <div className="bg-gray-100 p-4 rounded-md">
              <h2 className="text-xl font-semibold mb-4">Filters</h2>
              <FilterComponents
                categories={categories}
                tags={tags}
                brands={brands}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                priceRange={priceRange}
                handlePriceChange={handlePriceChange}
                sortBy={sortBy}
                setSortBy={setSortBy}
                handleApplyFilters={handleApplyFilters}
              />
            </div>
          </div>

          {/* Products Grid */}
          <div className="w-full md:w-3/4">
            {loading && pageNumber === 1 ? (
              <SkeletonGrid />
            ) : error ? (
              <div className="text-red-500 text-center my-4">{error}</div>
            ) : products.length > 0 ? (
              <>
                <Suspense fallback={<SkeletonGrid />}>
                  <Section title="Products" products={products} />
                </Suspense>
                {hasMore && (
                  <div className="flex justify-center mt-6">
                    <button
                      type="button"
                      className="px-6 py-2 bg-[#b799c8] rounded-md text-white font-semibold hover:bg-[#a078b5] transition-colors"
                      onClick={loadMoreProducts}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Load More"}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="text-center text-xl mt-8">No products found.</div>
            )}
          </div>
        </div>
      </div>

      {/* Floating "Show Filters" Button for Mobile */}
      <button
        className="fixed bottom-20 right-2 bg-black text-white p-3 rounded-full shadow-lg md:hidden z-50 hover:bg-[#a078b5] transition-colors duration-300"
        onClick={() => setIsFilterOpen(true)}
        aria-label="Show Filters"
      >
        <BsFilter className="text-3xl" />
      </button>

      {/* Filter Modal for Mobile */}
      {isFilterOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-end">
          <div className="bg-white text-black w-3/4 h-full relative p-4">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-black text-2xl"
              onClick={() => setIsFilterOpen(false)}
              aria-label="Close Filters"
            >
              &times;
            </button>
            {/* Filter Components */}
            <h2 className="text-xl font-semibold mb-4">Filters</h2>
            <FilterComponents
              categories={categories}
              tags={tags}
              brands={brands}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              selectedBrand={selectedBrand}
              setSelectedBrand={setSelectedBrand}
              priceRange={priceRange}
              handlePriceChange={handlePriceChange}
              sortBy={sortBy}
              setSortBy={setSortBy}
              handleApplyFilters={handleApplyFilters}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Shop;
