import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";

import Register from "./components/final_components/Register";
import Profile from "./components/final_components/Profile";
import { AuthProvider } from "./context/AuthContext";
// import AdminDashboard from "./components/AdminDashboard";
import ShopPage from "./pages/Shop";
import ProductDetailPage from "./pages/ProductDetailPage";
import Home from "./pages/final_pages/Home";
import Navbar from "./components/final_components/Navbar";
import Footer from "./components/final_components/Footer";

import Contact from "./pages/final_pages/Contact";
import BillingPage from "./pages/final_pages/BillingPage";

import Lenis from "lenis";
import Shop from "./pages/final_pages/Shop";
import AboutUs from "./components/final_components/AboutUs";
import About from "./pages/final_pages/About";
import ItemDetailPage from "./pages/final_pages/IdemDetailPage";
import FavoritesPage from "./pages/final_pages/FavoritesPage";
import CartComponent from "./components/final_components/CartComponent";
// import OrderDetails from "./pages/final_pages/OrderDetails";
import OrderDetailscomponent from "./components/final_components/OrderDetailscomponent";
import Order from "./pages/final_pages/Order";
import AdminPage from "./pages/final_pages/AdminPage";
import Login from "./components/final_components/Login";
import FAQ from "./pages/final_pages/FAQ";
import RefundPolicy from "./pages/final_pages/RefundPolicy";
import { ToastContainer } from "react-toastify";
import AdminDashboard from "./pages/final_pages/AdminDashboard";
import ForgotPassword from "./pages/final_pages/ForgotPassword";
import ResetPassword from "./pages/final_pages/ResetPassword";
import Whatsapp from "./components/final_components/whatsapp/whatsapp";

const App = () => {
  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Whatsapp />
        {/* ToastContainer for notifications */}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <div className="w-full h-[10vh] bg-gradient-to-b from-black to-transparent absolute z-10" /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:id" element={<ItemDetailPage />} />
          <Route path="/checkout" element={<BillingPage />} />{" "}
          <Route path="/about" element={<About />} />
          <Route path="/fav" element={<FavoritesPage />} />
          <Route path="/cart" element={<CartComponent />} />
          <Route path="/cart/:id" element={<CartComponent />} />
          <Route path="/orders/:id" element={<OrderDetailscomponent />} />
          <Route path="/orders" element={<Order />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/policy/refund" element={<RefundPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
