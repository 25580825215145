import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

// Moved categories array outside the component to prevent redefinition on each render
const categories = [
  {
    title: "Tools",
    imageSrc: "./needles.webp",
    link: "/shop?categoryIds=66daedc3c96124ed76c5df06",
    span: "md:col-span-2",
  },
  {
    title: "Cake",
    imageSrc: "./cake.jpg",
    link: "/shop?categoryIds=66d1c52bc96124ed76b5d51f",
    span: "",
  },
  {
    title: "Cotton",
    imageSrc: "./cotton2.jpg",
    link: "/shop?categoryIds=66d1c52bc96124ed76b5d529",
    span: "",
  },
  {
    title: "Wool",
    imageSrc: "./wool.jpg",
    link: "/shop?categoryIds=66d1c52bc96124ed76b5d51e",
    span: "md:col-span-2",
  },
];

const Categories = () => {
  const categoriesRef = useRef([]);

  useEffect(() => {
    // Clear previous refs to prevent duplication
    categoriesRef.current = categoriesRef.current.slice(0, categories.length);

    gsap.fromTo(
      categoriesRef.current,
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power4.out",
        stagger: 0.3,
      }
    );
  }, []);

  return (
    <div className="mx-auto max-w-7xl py-16 sm:px-6 lg:px-8">
      {/* Section Title */}
      <h2 className="text-3xl md:text-4xl font-bold text-center font-poppins mb-12 text-gray-800">
        Categories
      </h2>

      {/* Categories Grid */}
      <div className="max-w-[90vw] mx-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {categories.map((category) => (
          <a
            key={category.title}
            href={category.link}
            className={`relative overflow-hidden group rounded-lg shadow-lg ${category.span}`}
            ref={(el) => {
              if (el) {
                categoriesRef.current[
                  categories.findIndex((cat) => cat.title === category.title)
                ] = el;
              }
            }}
          >
            {/* Category Image */}
            <img
              src={category.imageSrc}
              alt={category.title}
              className="w-full h-56 sm:h-64 md:h-72 object-cover transform transition-transform duration-700 group-hover:scale-110"
              loading="lazy"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-60 transition-opacity duration-500 flex items-center justify-center">
              <div className="text-center px-4">
                <button className="btn relative rounded-lg uppercase font-bold bg-transparent text-white border-2 border-white py-3 px-8 text-lg overflow-hidden transition-all duration-300 ease-in-out hover:bg-pink hover:text-black hover:shadow-lg">
                  {category.title}
                </button>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Categories;
