import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true,
});
console.log(API);

//USER OPERATIONS
export const registerUser = (data) => API.post("/api/users", data);
export const loginUser = (data) => API.post("/api/users/auth", data);
export const logoutUser = () => API.post("/api/users/logout");
export const getProfile = () => API.get("/api/users/profile");
export const updateProfile = (data) => API.put("/api/users/profile", data);

// API call to check if a guest user exists
export const checkGuestExists = async (email) => {
  try {
    const response = await API.post(
      "api/users/check-user", // Correct API endpoint
      { email },
      { withCredentials: true }
    );
    return response.data.exists; // Directly return the true/false value
  } catch (error) {
    console.error("Error checking guest user:", error.message);
    return false; // Return false in case of error
  }
};

// PASSWORD RESET OPERATIONS
export const forgotPassword = (email) =>
  API.post("/api/users/forgot-password", { email });
export const resetPassword = (token, password) =>
  API.post(`/api/users/reset-password/${token}`, { password });

export const getAllUsers = () => API.get("/api/users");
export const getUserById = (id) => API.get(`/api/users/${id}`);
export const deleteUserById = (id) => API.delete(`/api/users/${id}`);
export const toggleAdminStatus = (id, isAdmin) =>
  API.put(`/api/users/${id}`, { isAdmin });
export const updateUserDetails = (id, userData) =>
  API.put(`/api/users/${id}`, userData);

//CATEGORY OPERATIONS
export const createCategory = (data) => API.post("/api/category", data);

export const updateCategory = (id, data) =>
  API.put(`/api/category/${id}`, data);

export const deleteCategory = (id) => API.delete(`/api/category/${id}`);

// export const getAllCategories = () => API.get("/api/category/categories");

export const getCategoryById = (id) => API.get(`/api/category/${id}`);

//products
// Basic GET Requests
export const fetchProductById = (id) => API.get(`/api/products/${id}`);
export const fetchAllProducts = () => API.get("/api/products/allproducts");

// Fetch top 4 products
export const fetchTopProducts = () => API.get("/api/products/top-products");

// Fetch 4 new products
export const fetchFourNewProducts = () => API.get("/api/products/new-products");

// Fetch all unique brand names
export const getAllBrands = () => API.get("/api/products/brands");

// Fetch 8 random products
export const fetchEightRandomProducts = () =>
  API.get("/api/products/random-products");

// Fetch 6 relevant products based on category
export const fetchSixRelevantProducts = (productId) =>
  API.get(`/api/products/relevant-products/${productId}`);

// Adding a Product
export const addProduct = (data) =>
  API.post("/api/products", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

// Updating a Product by ID
export const updateProduct = (id, data) =>
  API.put(`/api/products/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

// Deleting a Product by ID
export const deleteProduct = (id) => API.delete(`/api/products/${id}`);

// Uploading an Image (if needed)
export const uploadImage = (data) =>
  API.post("/api/upload", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

// Adding a Product Review
export const addProductReview = (productId, reviewData) =>
  API.post(`/api/products/${productId}/reviews`, reviewData);

// Fetch all products with optional filters (category, sort, pagination)
export const fetchProducts = async ({
  categoryIds, // should be an array of selected category IDs
  tagIds, // should be an array of selected tag IDs
  minPrice,
  maxPrice,
  sortBy,
  pageNumber,
  brands,
}) => {
  const params = {
    pageNumber,
    sortBy,
    ...(categoryIds && categoryIds.length > 0 && { categoryIds }), // Send only if categories are selected
    ...(tagIds && tagIds.length > 0 && { tagIds }), // Send only if tags are selected
    ...(minPrice && { minPrice }), // Ensure minPrice is passed
    ...(maxPrice && { maxPrice }), // Ensure maxPrice is passed
    ...(brands && brands.length > 0 && { brands: brands.join(",") }),
  };

  return await API.get("/api/products", { params });
};

// Fetch products by parentRefID
export const fetchProductsByParentRefID = (name, parentRefID) =>
  API.get(`/api/products/name/${name}/${parentRefID}`);

// Fetch new products (sorted by date)
export const fetchNewProducts = () => API.get("/api/products/new");

// Fetch products sorted by rating
export const fetchProductsByRatingInOrder = () =>
  API.get("/api/products/rating");

// Fetch products by category IDs with optional sorting
export const fetchProductsByCategories = async (categoryIds, sortBy) => {
  const response = await API.post("/api/products/categories", {
    categories: categoryIds,
    sortBy,
  });
  return response;
};

// Fetch all categories
export const getAllCategories = async () => {
  const response = await API.get("/api/category/categories");
  return response;
};

// Search products based on a query with pagination
export const searchProducts = (query, page = 1, limit = 20) =>
  API.get(`/api/products/search`, {
    params: { q: query, page, limit },
  });

// Get search suggestions
export const getSearchSuggestions = (query) =>
  API.get("/api/products/suggestions", {
    params: { q: query },
  });
// FAVORITES OPERATIONS
export const getUserFavorites = () => API.get("/api/favorites");
export const addToFavorites = (productId) =>
  API.post(`/api/favorites/${productId}`);
export const removeFromFavorites = (productId) =>
  API.delete(`/api/favorites/${productId}`);

//orders
export const getOrderById = (id) => API.get(`/api/orders/${id}`);
export const getOrderByUser = () => API.get(`/api/orders/myorders`);
export const createOrder = (orderData) => API.post("/api/orders", orderData);
//admin orders
export const getAllOrdersAdmin = () => API.get(`/api/orders`);
export const updateOrderStatus = (id, status, trackingDetails = {}) => {
  const { trackingCompany, trackingID } = trackingDetails;
  return API.put(`/api/orders/${id}/status`, {
    status,
    trackingCompany,
    trackingID,
  });
};

//TAGS operations
// TAG OPERATIONS
export const createTag = (data) => API.post("/api/tags", data);

export const updateTag = (id, data) => API.put(`/api/tags/${id}`, data);

export const deleteTag = (id) => API.delete(`/api/tags/${id}`);

export const getTagById = (id) => API.get(`/api/tags/${id}`);

export const getAllTags = () => API.get("/api/tags/tags");

// COUPON OPERATIONS

export const createCoupon = (data) => API.post("/api/coupons", data);
export const getAllCoupons = () => API.get("/api/coupons");
export const getCouponById = (code) => API.get(`/api/coupons/${code}`);
export const updateCoupon = (id, data) => API.put(`/api/coupons/${id}`, data);
export const deleteCoupon = (id) => API.delete(`/api/coupons/${id}`);
