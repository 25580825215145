// src/components/final_components/ItemDetail.jsx

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import {
  fetchProductById,
  addProductReview,
  fetchProductsByParentRefID,
  fetchSixRelevantProducts,
  getCategoryById,
  getTagById,
  addToFavorites,
  removeFromFavorites,
  getUserFavorites,
} from "../../api";
import ZoomImage from "./ZoomImage";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import Loader from "./loaders/Loader";
import TagsDisplay from "./TagsDisplay";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse, { domToReact } from "html-react-parser";
import DOMPurify from "dompurify";
import ProductCard from "./ProductCard";

const ItemDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relevantProducts, setRelevantProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("description");
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);
  const [categoryNames, setCategoryNames] = useState([]);
  const [tagNames, setTagNames] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [cart, setCart] = useState([]);
  const [productInCart, setProductInCart] = useState(null);

  const colors = {
    softPink: "#FDA7A8",
    lightLavender: "#E6CCDB",
    creamyBeige: "#FAE6CF",
    lightCream: "#FEF5E8",
    softLilac: "#F7D1FE",
  };

  useEffect(() => {
    // GSAP animation
    gsap.fromTo(
      containerRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );

    const loadProduct = async () => {
      setLoading(true);
      setError(null); // Reset error state at the start

      // Fetch Product Details
      let productData = null;
      try {
        const response = await fetchProductById(id);
        productData = response.data;
        setProduct(productData);
      } catch (err) {
        setError("Failed to load product details.");
        toast.error("Failed to load product details.");
        setLoading(false);
        return; // Exit early if product fetch fails
      }

      // Fetch User Favorites
      try {
        const favoritesResponse = await getUserFavorites();
        const isFav = favoritesResponse.data.some((fav) => fav._id === id);
        setIsFavorite(isFav);
      } catch (favError) {
        if (
          favError.response &&
          (favError.response.status === 401 || favError.response.status === 403)
        ) {
          setIsFavorite(false);
        } else {
          // Non-authentication errors
          toast.error("Failed to fetch favorites.");
          // Do not set global error; proceed without favorites
        }
      }

      // Fetch Related Products
      try {
        const relatedResponse = await fetchProductsByParentRefID(
          productData.name,
          productData.parentRefID
        );

        if (relatedResponse.data && Array.isArray(relatedResponse.data)) {
          const uniqueRelatedProducts = relatedResponse.data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.sku === item.sku)
          );

          setRelatedProducts(uniqueRelatedProducts);
        } else {
          setRelatedProducts([]);
        }
      } catch (relatedError) {
        toast.error("Failed to load related products.");
        // Set to empty array to avoid undefined issues
        setRelatedProducts([]);
      }

      // Fetch Relevant Products
      try {
        const relevantResponse = await fetchSixRelevantProducts(id);
        if (relevantResponse.data && Array.isArray(relevantResponse.data)) {
          setRelevantProducts(relevantResponse.data);
        } else {
          setRelevantProducts([]);
        }
      } catch (relevantError) {
        toast.error("Failed to load relevant products.");
        setRelevantProducts([]);
      }

      // Fetch Cart Data
      try {
        const existingCart = JSON.parse(localStorage.getItem("inCart")) || [];
        const productInCart = existingCart.find((item) => item._id === id);
        setCart(existingCart);
        setProductInCart(productInCart);
      } catch (cartError) {
        toast.error("Failed to load cart data.");
        setCart([]);
        setProductInCart(null);
      }

      setLoading(false);
    };

    loadProduct();
  }, [id]);

  // Fetch Category Names
  useEffect(() => {
    const fetchCategories = async () => {
      if (product && product.categories && Array.isArray(product.categories)) {
        try {
          const categoryPromises = product.categories.map((cat) =>
            getCategoryById(cat.categoryID)
          );
          const categoryResponses = await Promise.all(categoryPromises);
          const fetchedCategoryNames = categoryResponses.map(
            (res) => res.data.name
          );
          setCategoryNames(fetchedCategoryNames);
        } catch (categoryError) {
          toast.error("Failed to load some category names.");
          setCategoryNames(["Unknown Category"]);
        }
      } else {
        setCategoryNames([]);
      }
    };

    fetchCategories();
  }, [product]);

  // Fetch Tag Names
  useEffect(() => {
    const fetchTags = async () => {
      if (product && product.tags && Array.isArray(product.tags)) {
        try {
          const tagPromises = product.tags.map((tag) => getTagById(tag.tagID));
          const tagResponses = await Promise.all(tagPromises);
          const fetchedTagNames = tagResponses.map((res) => res.data.name);
          setTagNames(fetchedTagNames);
        } catch (tagError) {
          toast.error("Failed to load some tag names.");
          setTagNames(["Unknown Tag"]);
        }
      } else {
        setTagNames([]);
      }
    };

    fetchTags();
  }, [product]);

  const sanitizeProduct = (product) => {
    return {
      _id: product._id,
      name: product.name,
      price: product.price,
      quantity: product.quantity,
      images: product.images,
    };
  };

  const handleAddToCart = () => {
    try {
      let existingCart = JSON.parse(localStorage.getItem("inCart")) || [];
      let productInCart = existingCart.find((item) => item._id === product._id);

      if (productInCart) {
        productInCart.quantity += 1;
      } else {
        productInCart = { ...product, quantity: 1 };
        existingCart.push(productInCart);
      }

      const sanitizedCart = existingCart.map(sanitizeProduct);
      localStorage.setItem("inCart", JSON.stringify(sanitizedCart));

      setCart(sanitizedCart);
      setProductInCart(productInCart);

      toast.success("Product added to cart!");
    } catch (cartError) {
      toast.error("Failed to add product to cart.");
    }
  };

  const handleQuantityChange = (action) => {
    try {
      let existingCart = JSON.parse(localStorage.getItem("inCart")) || [];
      const productInCart = existingCart.find(
        (item) => item._id === product._id
      );

      if (productInCart) {
        if (action === "increase") {
          productInCart.quantity += 1;
          toast.success("Product quantity increased!");
        } else if (action === "decrease") {
          productInCart.quantity -= 1;
          if (productInCart.quantity === 0) {
            existingCart = existingCart.filter(
              (item) => item._id !== product._id
            );
            toast.info("Product removed from cart.");
          } else {
            toast.success("Product quantity decreased!");
          }
        }

        const sanitizedCart = existingCart.map(sanitizeProduct);
        localStorage.setItem("inCart", JSON.stringify(sanitizedCart));

        setCart(sanitizedCart);
        setProductInCart(
          existingCart.find((item) => item._id === product._id) || null
        );
      }
    } catch (quantityError) {
      toast.error("Failed to update cart quantity.");
    }
  };

  const handleAddReview = async () => {
    try {
      if (rating === 0) {
        toast.error("Please select a rating.");
        return;
      }

      const reviewData = { rating, comment: reviewText };
      await addProductReview(product._id, reviewData);

      setReviewText("");
      setRating(0);

      // Refetch product to get updated reviews
      try {
        const response = await fetchProductById(product._id);
        setProduct(response.data);
        toast.success("Review added successfully!");
      } catch (fetchError) {
        toast.error("Failed to refresh product after review.");
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to submit review.");
      toast.error("Failed to submit review.");
    }
  };

  const handleFavoriteToggle = async () => {
    try {
      if (isFavorite) {
        await removeFromFavorites(id);
        toast.info("Removed from favorites.");
      } else {
        await addToFavorites(id);
        toast.success("Added to favorites!");
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        toast.error("You need to log in to manage favorites.");
        navigate("/login");
      } else {
        toast.error("Failed to update favorite status.");
      }
    }
  };

  const getDisplayName = (name) => {
    return name.split(/-\s*/)[0].trim();
  };

  // Render Loading State
  if (loading) return <Loader />;

  // Render Error State
  if (error)
    return <div className="text-red-500 text-center mt-4">{error}</div>;

  // Render Product Not Found
  if (!product)
    return <div className="text-center mt-4">Product not found.</div>;

  const isOutOfStock = product.countInStock <= 0;

  const sanitizedDescription = DOMPurify.sanitize(product.description);

  const parserOptions = {
    replace: ({ name, attribs, children }) => {
      if (!attribs) return;
      switch (name) {
        case "ul":
          return (
            <ul className="list-disc pl-5 my-2">
              {domToReact(children, parserOptions)}
            </ul>
          );
        case "ol":
          return (
            <ol className="list-decimal pl-5 my-2">
              {domToReact(children, parserOptions)}
            </ol>
          );
        case "li":
          return (
            <li className="mb-1 font-semibold text-gray-500">
              {domToReact(children, parserOptions)}
            </li>
          );
        case "strong":
          return (
            <strong className="font-semibold">
              {domToReact(children, parserOptions)}
            </strong>
          );
        case "em":
          return (
            <em className="italic">{domToReact(children, parserOptions)}</em>
          );
        case "a":
          return (
            <a
              href={attribs.href}
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {domToReact(children, parserOptions)}
            </a>
          );
        case "p":
          return (
            <p className="text-md text-gray-500 my-4">
              {domToReact(children, parserOptions)}
            </p>
          );
        default:
          return;
      }
    },
  };

  return (
    <motion.div
      className="container mx-auto w-full px-4 lg:py-6 space-y-1"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      ref={containerRef}
    >
      {/* Breadcrumbs */}
      <div
        style={{
          fontFamily: "sans-serif",
          fontSize: "14px",
          color: "#6D6D6D",
        }}
        className="my-4"
      >
        {["Home", "Shop", getDisplayName(product.name)].map((item, index) => (
          <span
            key={index}
            className="text-md font-poppins font-thin my-4 hover:underline"
          >
            {item}
            {index < 2 && (
              <span className="text-md font-poppins font-thin my-4 mx-2">
                »
              </span>
            )}
          </span>
        ))}
      </div>

      {/* Toast Notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
      />

      {/* SEO Meta Tags */}
      <Helmet>
        <title>{`${product.name} | Your Store`}</title>
        <meta
          name="description"
          content={`Buy ${product.name} for just ₹${
            product.price.sale || product.price.regular
          }. Find out more about its features and customer reviews.`}
        />
        <meta
          name="keywords"
          content={`${product.name}, ${tagNames.join(", ")}`}
        />
        <meta property="og:title" content={`${product.name} | Your Store`} />
        <meta
          property="og:description"
          content={`Buy ${product.name} for just ₹${
            product.price.sale || product.price.regular
          }. Find out more about its features and customer reviews.`}
        />
        <meta
          property="og:image"
          content={
            product.images && product.images.length > 0
              ? `${process.env.REACT_APP_URL}/${product.images[0].url}`
              : `${process.env.REACT_APP_URL}/default-image.jpg` // Fallback image
          }
        />
        <meta property="og:url" content={`http://yourstore.com/shop/${id}`} />
        <meta property="og:type" content="product" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${product.name} | Your Store`} />
        <meta
          name="twitter:description"
          content={`Buy ${product.name} for just ₹${
            product.price.sale || product.price.regular
          }. Find out more about its features and customer reviews.`}
        />
        <meta
          name="twitter:image"
          content={
            product.images && product.images.length > 0
              ? `${process.env.REACT_APP_URL}/${product.images[0].url}`
              : `${process.env.REACT_APP_URL}/default-image.jpg` // Fallback image
          }
        />
      </Helmet>

      {/* Structured Data for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "Product",
          name: product.name,
          image:
            product.images && product.images.length > 0
              ? `${process.env.REACT_APP_URL}/${product.images[0].url}`
              : `${process.env.REACT_APP_URL}/default-image.jpg`, // Fallback image
          description: product.description,
          sku: product.sku,
          brand: { "@type": "Brand", name: "Your Brand" },
          offers: {
            "@type": "Offer",
            price: product.price.sale || product.price.regular,
            priceCurrency: "INR",
            availability:
              product.countInStock > 0
                ? "https://schema.org/InStock"
                : "https://schema.org/OutOfStock",
          },
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: product.rating,
            reviewCount: product.numReviews,
          },
          review: product.reviews.map((review) => ({
            "@type": "Review",
            author: review.name,
            reviewRating: {
              "@type": "Rating",
              ratingValue: review.rating,
            },
            reviewBody: review.comment,
          })),
        })}
      </script>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row relative">
        {/* Product Image */}
        <aside className="lg:w-1/2 p-2 lg:p-20 flex flex-col items-center mb-8 lg:mb-0">
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            className="sticky top-5 border-5 border-black w-full lg:max-w-1/3"
            style={{ zIndex: 10 }}
          >
            {product.images && product.images.length > 0 ? (
              <ZoomImage
                src={`${process.env.REACT_APP_URL}/${product.images[0].url}`}
                alt={product.name}
                className="w-[50%] flex justify-center items-center h-[80vh] object-cover"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_URL}/default-image.jpg`}
                alt="Default Product"
                className="w-[50%] flex justify-center items-center h-[80vh] object-cover"
              />
            )}
          </motion.div>
        </aside>

        {/* Product Details */}
        <section className="lg:w-1/2 lg:pl-10">
          {/* Category Name */}
          <div className="text-md text-gray-500 mb-2">
            {categoryNames.length > 0 ? categoryNames[0] : "Uncategorized"}
          </div>

          {/* Product Name and Favorite Button */}
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-semibold text-gray-800">
              {product.name}
            </h1>
            <motion.button
              onClick={handleFavoriteToggle}
              className="focus:outline-none text-[#ff64b9]"
              whileTap={{ scale: 0.9 }}
            >
              {isFavorite ? <BsHeartFill size={28} /> : <BsHeart size={28} />}
              <span className="sr-only">Toggle Favorite</span>
            </motion.button>
          </div>

          {/* Price Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            {product.price.sale ? (
              <>
                <span
                  className="text-2xl"
                  style={{ fontWeight: "600", color: "#000" }}
                >
                  ₹{product.price.sale}
                </span>
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "#6d6d6d",
                    marginLeft: "12px",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  <span style={{ marginLeft: "8px" }} className="text-black">
                    MRP&nbsp;
                  </span>
                  ₹{product.price.regular}
                </span>
              </>
            ) : (
              <span
                className="text-2xl"
                style={{ fontWeight: "600", color: "#000" }}
              >
                MRP ₹{product.price.regular}
              </span>
            )}
          </div>

          {/* Rating Section */}
          <div className="flex items-center mb-6">
            <div className="flex items-center text-yellow-500">
              {Array.from({ length: 5 }, (_, index) => (
                <span key={index}>
                  {index < Math.round(product.rating) ? "★" : "☆"}
                </span>
              ))}
            </div>
            <a
              href="#reviews"
              className="text-gray-600 ml-2 hover:text-blue-500"
            >
              {product.numReviews} Customer Review(s)
            </a>
          </div>

          {/* Product Description */}
          <div className="text-gray-700 mb-10 w-full leading-relaxed overflow-hidden">
            <div className="prose max-w-none">
              {parse(sanitizedDescription, parserOptions)}
            </div>
          </div>

          {/* Related Products (Available Variations) */}
          {relatedProducts.length > 0 ? (
            <div className="mb-6">
              <h3 className="font-semibold mb-3 text-lg">
                Available Variations:
              </h3>
              <div className="flex flex-wrap">
                {relatedProducts.map((relatedProduct, index) => (
                  <motion.div
                    key={index}
                    className="relative w-12 h-12 object-cover cursor-pointer border-2 overflow-hidden m-1"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => navigate(`/shop/${relatedProduct._id}`)}
                    style={{
                      borderColor:
                        relatedProduct._id === product._id
                          ? "purple"
                          : colors.creamyBeige,
                      transition: "border-color 0.3s ease",
                    }}
                  >
                    {relatedProduct.images &&
                    relatedProduct.images.length > 0 ? (
                      <img
                        src={`${process.env.REACT_APP_URL}/${relatedProduct.images[0].url}`}
                        alt={relatedProduct.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_URL}/default-image.jpg`}
                        alt="Default Variation"
                        className="w-full h-full object-cover"
                      />
                    )}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                      <span className="bg-[#FDA7A8] text-black text-lg rounded-sm">
                        {relatedProduct.colors || "N/A"}
                      </span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mb-6">
              <h3 className="font-semibold mb-3 text-lg">
                Available Variations:
              </h3>
              <p className="text-gray-600">No variations available.</p>
            </div>
          )}

          {/* Add to Cart Section */}
          {isOutOfStock ? (
            <div className="w-[50%] mx-auto mb-6 p-3 bg-red-500 text-white text-lg font-bold text-center">
              Out of Stock
            </div>
          ) : productInCart ? (
            <div className="flex items-center space-x-4 mb-6">
              <button
                onClick={() => handleQuantityChange("decrease")}
                className="w-10 h-10 text-xl text-black hover:bg-gray-300 transition-all duration-300"
                aria-label="Decrease Quantity"
              >
                -
              </button>
              <span className="text-xl font-poppins">
                {productInCart.quantity}
              </span>
              <button
                onClick={() => handleQuantityChange("increase")}
                className="w-10 h-10 text-xl text-black hover:bg-gray-300 transition-all duration-300"
                aria-label="Increase Quantity"
              >
                +
              </button>
            </div>
          ) : (
            <button
              onClick={handleAddToCart}
              className={`w-[50%] mx-auto mb-6 p-3 text-lg font-bold ${
                isOutOfStock
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-black text-white hover:bg-[#F7D1FE] hover:text-black transition-all duration-300 ease-in-out"
              }`}
              disabled={isOutOfStock}
            >
              Add to Cart
            </button>
          )}

          {/* Product Details: SKU, Categories, Tags */}
          <div className="text-gray-700 mb-8">
            <p className="mb-2">
              <span className="font-semibold text-[#b799c8]">SKU:</span>{" "}
              {product.sku}
            </p>

            {categoryNames.length > 0 && (
              <div className="mb-2">
                <span className="font-semibold text-[#b799c8]">
                  Categories:
                </span>
                <div className="flex flex-wrap mt-1">
                  {categoryNames.map((category, idx) => (
                    <span
                      key={idx}
                      className="bg-gray-200 text-gray-700 text-sm font-medium mr-2 mb-2 px-3 py-1"
                    >
                      {category}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {tagNames.length > 0 && (
              <div className="mb-2">
                <span className="font-semibold text-[#b799c8]">Tags:</span>
                <div className="flex flex-wrap mt-1">
                  {tagNames.map((tag, idx) => (
                    <span
                      key={idx}
                      className="bg-blue-100 text-blue-700 text-sm font-medium mr-2 mb-2 px-3 py-1"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Free Delivery Notice */}
          <div className="bg-gray-200 text-gray-700 text-center text-lg font-medium mr-2 mb-2 py-2">
            Free delivery for order over ₹2500
          </div>

          {/* Tabs for Description, Additional Info, Reviews */}
          <div className="mx-auto py-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <div className="border-b border-gray-200">
                <nav className="flex space-x-8 overflow-x-auto pb-2">
                  <button
                    onClick={() => setActiveTab("description")}
                    className={`text-lg font-medium ${
                      activeTab === "description"
                        ? "text-blue-500"
                        : "text-gray-900"
                    } hover:text-blue-500 transition-colors whitespace-nowrap`}
                  >
                    Description
                  </button>
                  <button
                    onClick={() => setActiveTab("additional-info")}
                    className={`text-lg font-medium ${
                      activeTab === "additional-info"
                        ? "text-blue-500"
                        : "text-gray-900"
                    } hover:text-blue-500 transition-colors whitespace-nowrap`}
                  >
                    Additional Information
                  </button>
                  <button
                    onClick={() => setActiveTab("reviews")}
                    className={`text-lg font-medium ${
                      activeTab === "reviews"
                        ? "text-blue-500"
                        : "text-gray-900"
                    } hover:text-blue-500 transition-colors whitespace-nowrap`}
                  >
                    Reviews ({product.numReviews})
                  </button>
                </nav>
              </div>
              <div className="py-8">
                {/* Description Tab */}
                {activeTab === "description" && (
                  <article>
                    <h3 className="text-xl font-semibold mb-4">Description</h3>
                    <div className="prose">
                      {parse(sanitizedDescription, parserOptions)}
                    </div>
                  </article>
                )}

                {/* Additional Information Tab */}
                {activeTab === "additional-info" && (
                  <article>
                    <h3 className="text-xl font-semibold mb-4">
                      Additional Information
                    </h3>
                    <p>Weight: {product.weight} kg</p>
                  </article>
                )}

                {/* Reviews Tab */}
                {activeTab === "reviews" && (
                  <section className="p-6 bg-white ">
                    {/* Existing Reviews */}
                    {product.reviews.length > 0 ? (
                      product.reviews.map((review, index) => (
                        <motion.div
                          key={index}
                          className="mb-4 p-4 bg-gray-50 rounded-md shadow-sm"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <div className="flex justify-between items-center mb-2">
                            <span className="font-semibold text-gray-900">
                              {review.name}
                            </span>
                            <span className="text-yellow-500">
                              {"★".repeat(review.rating)}
                              {"☆".repeat(5 - review.rating)}
                            </span>
                          </div>
                          <p className="text-gray-700">{review.comment}</p>
                        </motion.div>
                      ))
                    ) : (
                      <p className="text-gray-600">There are no reviews yet.</p>
                    )}

                    {/* Add Review Form */}
                    <h3 className="text-2xl font-semibold mb-4 mt-6 text-gray-700 text-center">
                      Be the first to review “{product.name}”
                    </h3>
                    <div className="space-y-4">
                      <div>
                        <label className="text-gray-700 font-semibold">
                          Your Rating *
                        </label>
                        <select
                          className="ml-2 border border-gray-300 rounded p-2"
                          value={rating}
                          onChange={(e) => setRating(Number(e.target.value))}
                        >
                          <option value="0">Choose Rating</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </select>
                      </div>
                      <div>
                        <label className="text-gray-700 font-semibold">
                          Your Review *
                        </label>
                        <textarea
                          className="w-full border border-gray-300 rounded p-2"
                          rows="5"
                          value={reviewText}
                          onChange={(e) => setReviewText(e.target.value)}
                          placeholder="Write your review here..."
                        ></textarea>
                      </div>

                      <motion.button
                        onClick={handleAddReview}
                        className="mt-4 w-full bg-black text-white rounded py-2 hover:bg-[#FDA7A8]"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Submit Review
                      </motion.button>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>

          {/* Similar Products Section */}
          {relevantProducts.length > 0 ? (
            <div className="mt-2">
              <h3 className="text-2xl font-poppins font-semibold text-gray-800 mb-6">
                Similar Products
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
                {relevantProducts.map((product) => (
                  <motion.div
                    key={product._id}
                    className="bg-white overflow-hidden transition-shadow duration-300"
                    whileHover={{ scale: 1.05 }}
                  >
                    <ProductCard product={product} />
                  </motion.div>
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <h3 className="text-2xl font-poppins font-semibold text-gray-800 mb-6">
                Similar Products
              </h3>
              <p className="text-gray-600">No similar products found.</p>
            </div>
          )}
        </section>
      </div>
    </motion.div>
  );
};

export default ItemDetail;
