import React, { useRef, useEffect } from "react";
import "./InfiniteScroll.css"; // Import the CSS file

const InfiniteScroll = () => {
  const companies = [
    { name: "Krafty", logo: "/companies/krafty.jpg" },
    { name: "StyleKraft", logo: "/companies/stylekraft.jpg" },
    { name: "Papatya", logo: "/companies/papatya.jpg" },
    { name: "Ice", logo: "/companies/ice.jpg" },
    // { name: "Tulip", logo: "/companies/tulip.jpg" },
    { name: "cicibebe", logo: "/companies/cicibebe.jpg" },
    { name: "Pony", logo: "/companies/pony.jpg" },
    // Add more companies as needed
  ];

  // Duplicate the companies array to create a seamless loop
  const companyList = [...companies, ...companies];

  const scrollRef = useRef(null);

  // Handle pause and resume on user interaction
  useEffect(() => {
    const handleMouseEnter = () => {
      if (scrollRef.current) {
        scrollRef.current.classList.add("paused");
      }
    };

    const handleMouseLeave = () => {
      if (scrollRef.current) {
        scrollRef.current.classList.remove("paused");
      }
    };

    const handleTouchStart = () => {
      if (scrollRef.current) {
        scrollRef.current.classList.add("paused");
      }
    };

    const handleTouchEnd = () => {
      if (scrollRef.current) {
        scrollRef.current.classList.remove("paused");
      }
    };

    const scrollElement = scrollRef.current;

    if (scrollElement) {
      scrollElement.addEventListener("mouseenter", handleMouseEnter);
      scrollElement.addEventListener("mouseleave", handleMouseLeave);
      scrollElement.addEventListener("touchstart", handleTouchStart);
      scrollElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("mouseenter", handleMouseEnter);
        scrollElement.removeEventListener("mouseleave", handleMouseLeave);
        scrollElement.removeEventListener("touchstart", handleTouchStart);
        scrollElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  return (
    <div className="relative max-w-7xl overflow-hidden my-1 mx-auto">
      {/* Scroll Container */}
      <div className="scroll-container flex" ref={scrollRef}>
        {companyList.map((company, index) => (
          <div key={index} className="scroll-item md:mx-4">
            <img
              src={company.logo}
              alt={company.name}
              className="h-36 w-36 object-contain"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Left Fade */}
      <div className="fade-left"></div>

      {/* Right Fade */}
      <div className="fade-right"></div>
    </div>
  );
};

export default InfiniteScroll;
