// src/components/final_components/HeroSlider.jsx

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { gsap } from "gsap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import custom styles
import "./HeroSlider.css"; // Ensure this path is correct

const HeroSlider = () => {
  const slideRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const slideData = useMemo(
    () => [
      {
        title: "Krafty Chenille Baby",
        img: "./chenille.jpg",
        text: "Luxuriously soft, perfect for delicate and cuddly creations.",
        link: "http://knotsandkrafts.in/shop/66d40068c96124ed761689ad",
      },
      {
        title: "Krafty baby milk cotton",
        img: "./krafty-milk-cotton.jpg",
        text: "designed for the softest, snuggliest creations.",
        link: "http://knotsandkrafts.in/shop/66d40068c96124ed761689ad",
      },
      {
        title: "T Shirt Yarn",
        img: "./tshirt-yarn.webp",
        text: "Soft and stretchy, ideal for crafting bags, rugs, and home decor.",
        link: "http://knotsandkrafts.in/shop/66d40079c96124ed76169ea4",
      },
      {
        title: "Softly Baby",
        img: "./softly-baby.webp",
        text: "Light and airy, designed for crafting tender, cuddly projects.",
        link: "http://knotsandkrafts.in/shop/66d40068c96124ed7616857e",
      },
      {
        title: "Stylecraft Special DK",
        img: "./stylecraft-dk.jpg",
        text: "rich colours and superior softness for all your crafting needs.",
        link: "http://knotsandkrafts.in/shop/66d40068c96124ed76168851",
      },
      {
        title: "Papatya Cake Silver",
        img: "./papatya-cake.jpg",
        text: "Perfect for making all kinds of projects, from cardigans and blankets to scarves, sweaters and more!",
        link: "http://knotsandkrafts.in/shop/66d40068c96124ed76168975",
      },
      {
        title: "Cicibebe Batik",
        img: "./cicibebe-batik.jpg",
        text: "Richly coloured batik yarn, soft and perfect for unique craftwork.",
        link: "http://knotsandkrafts.in/shop/66d40068c96124ed7616893f",
      },
    ],
    []
  );

  useEffect(() => {
    // Animate the active slide's content
    gsap.fromTo(
      slideRefs.current[activeIndex],
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
      }
    );

    // Reset animation for previous slides
    slideData.forEach((_, index) => {
      if (index !== activeIndex && slideRefs.current[index]) {
        gsap.to(slideRefs.current[index], {
          opacity: 0,
          y: -50,
          duration: 0.5,
          ease: "power3.in",
        });
      }
    });
  }, [activeIndex, slideData]);

  return (
    <div className="hero-slider-container w-full">
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        pagination={{ clickable: true }}
        className="hero-swiper"
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
      >
        {slideData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              className="slide relative w-full h-[60vh] md:h-[90vh] bg-cover bg-center flex items-center justify-center"
              style={{
                backgroundImage: `url(${slide.img})`,
              }}
            >
              {/* Overlay */}
              <div className="overlay absolute inset-0 bg-black bg-opacity-10 transition-opacity duration-500"></div>

              {/* Slide Content */}
              <div
                ref={(el) => (slideRefs.current[index] = el)}
                className="slide-content z-10 text-center px-4 opacity-0 transform -translate-y-10"
              >
                <h1 className="slide-title font-semibold font-noto text-white text-2xl md:text-5xl mb-2">
                  {slide.title}
                </h1>
                <p className="slide-text text-white text-md md:text-lg mb-16">
                  {slide.text}
                </p>
                <a
                  href={slide.link}
                  className="btn relative uppercase font-bold text-[#F7D1FE] border-2 border-white py-2 px-6 text-lg overflow-hidden transition-all duration-300 ease-in-out hover:text-[#E6CCDB]"
                >
                  {slide.buttonText || "Shop Now"}
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
