// src/components/ProductCard.jsx

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { BsEye, BsHeart, BsHeartFill, BsBag } from "react-icons/bs";
import "./ProductCard.css"; // Ensure this CSS is optimized and uses Tailwind if possible
import {
  addToFavorites,
  removeFromFavorites,
  getUserFavorites,
  // addToCartAPI, // Uncomment if you have an API function to handle adding to cart
} from "../../api"; // Adjust the import path as necessary
import { toast } from "react-toastify"; // Import toast notifications

// Lazy load QuickViewPopup to improve initial load times
const QuickViewPopup = lazy(() => import("./QuickViewPopup"));

const ProductCard = React.memo(({ product }) => {
  const cardRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showQuickView, setShowQuickView] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const navigate = useNavigate();

  // Function to extract display name
  const getDisplayName = useCallback((name) => {
    return name.split(" - ")[0].trim();
  }, []);

  // Effect to handle animations and initial data fetching
  useEffect(() => {
    // GSAP animation for card entry
    gsap.fromTo(
      cardRef.current,
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );

    // Fetch user's favorite status for this product
    const fetchFavoriteStatus = async () => {
      try {
        const favoritesResponse = await getUserFavorites();
        const isFav = favoritesResponse.data.some(
          (fav) => fav._id === product._id
        );
        setIsFavorite(isFav);
      } catch (error) {
        console.error("Error fetching favorites:", error);
        // Optionally handle error (e.g., user not logged in)
      }
    };

    fetchFavoriteStatus();

    // Check if the product is in the cart
    const checkIfInCart = () => {
      try {
        const cart = JSON.parse(localStorage.getItem("inCart")) || [];
        const inCart = cart.some((item) => item._id === product._id);
        setIsInCart(inCart);
      } catch (error) {
        console.error("Error checking cart status:", error);
      }
    };

    checkIfInCart();

    // Listen for changes to localStorage to update cart status in real-time
    const handleStorageChange = (e) => {
      if (e.key === "inCart") {
        checkIfInCart();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [product._id]);

  // Function to open Quick View popup
  const openQuickView = useCallback(
    (e) => {
      e.preventDefault(); // Prevent link navigation
      e.stopPropagation(); // Prevent event from bubbling to Link
      setShowQuickView(true);
      document.body.style.overflow = "hidden"; // Prevent background scrolling
      console.log("Quick View Opened for:", product.name); // Debugging
    },
    [product.name]
  );

  // Function to close Quick View popup
  const closeQuickView = useCallback(() => {
    setShowQuickView(false);
    document.body.style.overflow = "auto"; // Restore background scrolling
    console.log("Quick View Closed for:", product.name); // Debugging
  }, [product.name]);

  // Function to handle favorite toggle
  const handleFavoriteToggle = useCallback(
    async (e) => {
      e.preventDefault(); // Prevent link navigation
      e.stopPropagation(); // Prevent event from bubbling to Link
      try {
        if (isFavorite) {
          await removeFromFavorites(product._id);
          toast.info("Removed from favorites.");
        } else {
          await addToFavorites(product._id);
          toast.success("Added to favorites!");
        }
        setIsFavorite((prev) => !prev);
        console.log(
          `${isFavorite ? "Removed" : "Added"} favorite for:`,
          product.name
        ); // Debugging
      } catch (error) {
        console.error("Failed to update favorite status:", error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          // User is not authenticated, navigate to login
          toast.error("You need to log in to manage favorites.");
          navigate("/login");
        } else {
          // Show error toast for other errors
          toast.error("Failed to update favorite status.");
        }
      }
    },
    [isFavorite, product._id, product.name, navigate]
  );

  // Function to handle adding product to cart
  const handleAddToCart = useCallback(
    async (e) => {
      e.preventDefault(); // Prevent link navigation
      e.stopPropagation(); // Prevent event from bubbling to Link
      try {
        let existingCart = JSON.parse(localStorage.getItem("inCart")) || [];
        let productInCart = existingCart.find(
          (item) => item._id === product._id
        );

        if (productInCart) {
          productInCart.quantity += 1;
          toast.info("Increased product quantity in cart.");
        } else {
          // Add the product to cart if it's not there
          productInCart = { ...product, quantity: 1 };
          existingCart.push(productInCart);
          toast.success("Product added to cart!");
        }

        // Sanitize and save the cart
        const sanitizedCart = existingCart.map((prod) => ({
          _id: prod._id,
          name: prod.name,
          price: prod.price,
          quantity: prod.quantity,
          images: prod.images,
        }));
        localStorage.setItem("inCart", JSON.stringify(sanitizedCart));
        console.log("Cart Updated:", sanitizedCart); // Debugging

        // Update isInCart state
        setIsInCart(true);

        // Optionally, you can notify the server about cart updates
        // await addToCartAPI(product._id, 1); // Example API call
      } catch (error) {
        console.error("Failed to add to cart:", error);
        toast.error("Failed to add product to cart.");
      }
    },
    [product]
  );

  return (
    <div
      ref={cardRef}
      className="relative block w-full my-4 p-1 lg:h-84 h-80 overflow-hidden transition-all duration-300 focus:outline-none product-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Animated Borders */}
      <div className="absolute inset-0 border-container pointer-events-none">
        <div className="border-line top-line"></div>
        <div className="border-line right-line"></div>
        <div className="border-line bottom-line"></div>
        <div className="border-line left-line"></div>
      </div>

      {/* Sale Badge */}
      {product.price.sale && (
        <span className="sale-badge top-[5%] left-0 bg-black text-white font-poppins text-xs p-1 text-center w-[30%] z-30 rounded">
          SALE
        </span>
      )}

      <Link
        to={`/shop/${product._id}`}
        aria-label={`View details of ${product.name}`}
        className="flex flex-col h-full relative z-10"
      >
        {/* Product Image */}
        <img
          src={`${process.env.REACT_APP_URL}/${product.images[0].url}`}
          alt={product.name}
          className="w-full border-gray-400 border-[1px] rounded-md h-2/3 lg:h-full object-cover transition-transform duration-300"
          loading="lazy" // Enable native lazy loading
        />

        {/* Quick View Icon */}
        <div
          className={`absolute right-2 top-2 ${
            isHovered ? "opacity-100" : "opacity-50"
          } transition-opacity duration-300`}
        >
          <BsEye
            size={24}
            onClick={openQuickView}
            className="text-black cursor-pointer"
          />
        </div>

        {/* Overlay Icons */}
        <div
          className={`absolute w-[50%] hidden md:flex mx-auto bottom-20 bg-gray-200 p-2 rounded-md left-2 right-2 justify-around items-center opacity-0 ${
            isHovered ? "opacity-100" : "opacity-0"
          } transition-opacity duration-300 z-20`}
        >
          {/* Wishlist Icon */}
          <div onClick={handleFavoriteToggle}>
            {isFavorite ? (
              <BsHeartFill
                size={24}
                className="text-red-500 cursor-pointer hover:text-red-700"
              />
            ) : (
              <BsHeart
                size={24}
                className="text-black cursor-pointer hover:text-red-500"
              />
            )}
          </div>
          {/* Add to Cart Icon */}
          <BsBag
            size={24}
            className={`cursor-pointer hover:text-black transition-colors duration-300 ${
              isInCart ? "text-green-500" : "text-black"
            }`}
            onClick={handleAddToCart}
            aria-label={isInCart ? "Added to cart" : "Add to cart"}
          />
          {/* Quick View Icon */}
          <BsEye
            size={24}
            className="text-black cursor-pointer hover:text-blue-500"
            onClick={openQuickView}
          />
        </div>

        {/* Product Details */}
        <div className="flex-1 flex flex-col pt-4 justify-between">
          {/* Product Name */}
          <h3
            className="text-md text-center text-gray-700 font-semibold truncate"
            title={getDisplayName(product.name)}
          >
            {getDisplayName(product.name)}
          </h3>

          {/* Price Section */}
          <div className="flex items-center justify-center space-x-2 text-md">
            {product.countInStock > 0 ? (
              <>
                {product.price.sale ? (
                  <>
                    <span className="font-bold text-black">
                      ₹{product.price.sale}
                    </span>
                    <span className="text-gray-500 font-bold text-sm">
                      MRP{" "}
                    </span>
                    <span className="line-through text-gray-500">
                      ₹{product.price.regular}
                    </span>
                  </>
                ) : (
                  <span className="font-bold text-sm text-black">
                    <span className="text-gray-500 font-bold text-sm">
                      MRP{" "}
                    </span>
                    ₹{product.price.regular || product.price}
                  </span>
                )}
              </>
            ) : (
              <span className="text-red-600 font-bold text-md">
                Out of Stock
              </span>
            )}
          </div>

          {/* Select Options Button */}
          <button
            className="mt-auto w-full lg:hidden rounded-md bg-[#b799c8] text-white hover:bg-[#fbe682] hover:text-black py-2 text-center font-bold cursor-pointer transition duration-300 ease-in-out transform active:scale-95 animate-bounce-once"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/shop/${product._id}`);
            }}
          >
            Select options
          </button>
        </div>
      </Link>

      {/* Quick View Popup */}
      {showQuickView && (
        <Suspense fallback={<div className="loader">Loading...</div>}>
          <QuickViewPopup product={product} onClose={closeQuickView} />
        </Suspense>
      )}
    </div>
  );
});

export default ProductCard;
