// src/components/Profile.jsx

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { logoutUser, updateProfile, getProfile } from "../../api";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { FaUserEdit, FaSignOutAlt, FaShoppingBag } from "react-icons/fa";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if user is not authenticated
    if (!user) {
      toast.info("Please log in to access your profile.");
      navigate("/login");
    } else {
      setUsername(user.username);
      setEmail(user.email);
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      const { data } = await updateProfile({ username, email, password });

      if (data && data._id) {
        setUser(data);
        toast.success("Profile updated successfully!");
        setPassword("");
        setConfirmPassword("");
      } else {
        const { data: updatedUser } = await getProfile();
        setUser(updatedUser);
        toast.success("Profile updated successfully!");
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      console.error("Profile update failed", error);
      const errorMessage =
        error.response?.data?.message || "Failed to update profile.";
      toast.error(`Profile update failed: ${errorMessage}`);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      setUser(null);
      navigate("/login");
      toast.success("Logged out successfully!");
    } catch (error) {
      console.error("Failed to log out", error);
      toast.error("Failed to log out. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-[#FAF3F3] font-poppins">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-pink text-center mb-6">
            My Profile
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Username
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <AiOutlineUser className="ml-3 text-gray-500" size={20} />
                <input
                  id="username"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#FDA7A8] rounded-lg"
                  required
                />
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Email
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <AiOutlineMail className="ml-3 text-gray-500" size={20} />
                <input
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#FDA7A8] rounded-lg"
                  required
                />
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                New Password
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <RiLockPasswordLine className="ml-3 text-gray-500" size={20} />
                <input
                  id="password"
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#FDA7A8] rounded-lg"
                />
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Confirm Password
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <RiLockPasswordLine className="ml-3 text-gray-500" size={20} />
                <input
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#FDA7A8] rounded-lg"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-pink hover:bg-yellow text-white hover:text-black font-medium py-3 rounded-lg transition duration-300"
            >
              <FaUserEdit className="inline-block mr-2" />
              Update Profile
            </button>
          </form>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-8 bg-[#FAF3F3]">
        {user && (
          <>
            <p className="text-xl text-gray-800 mb-6 text-center">
              Hello, <span className="font-bold">{user.username}</span>!
            </p>
            <button
              onClick={handleLogout}
              className="w-full max-w-xs bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-3 rounded-lg flex items-center justify-center transition duration-300 mb-4"
            >
              <FaSignOutAlt className="mr-2" />
              Logout
            </button>
          </>
        )}
        <button
          onClick={() => navigate("/orders")}
          className="w-full max-w-xs bg-pink hover:bg-yellow text-white font-medium py-3 rounded-lg flex items-center justify-center transition duration-300"
        >
          <FaShoppingBag className="mr-2" />
          View My Orders
        </button>
      </div>
    </div>
  );
};

export default Profile;
