import React, { useEffect, useRef } from "react";

import ProductGrid from "../../components/final_components/ProductGrid";

import WhyKnotsAndKrafts from "../../components/final_components/WhyKnotsAndKrafts";
import Catagories from "../../components/final_components/Catagories";
import Memories from "../../components/final_components/Memories";
import TopAndLatestProducts from "../../components/final_components/TopAndLatestProducts";
import HeroSlider from "../../components/final_components/HeroSlider";
import GotBulkOrder from "../../components/final_components/GotBulkOrder";
import InfiniteScroll from "../../components/final_components/InfiniteScroll";
import Accessories from "../../components/final_components/Accessories";
import BackToTop from "../../components/final_components/BackToTop";

const Home = () => {
  return (
    <div className="relative bg-no-repeat bg-cover min-h-screen">
      {/* Background Layer */}
      {/* <div
        className="absolute inset-0 h-full bg-repeat bg-[size:600px] z-0"
        style={{
          backgroundImage: `url('./pattern_japanese-pattern-1_1_2_0-0_0_1__ffffff_000000.png')`,
          opacity: 0.05,
        }}
      ></div> */}
      <HeroSlider />
      <TopAndLatestProducts />
      <Accessories />
      <InfiniteScroll />
      {/* <WhyKnotsAndKrafts /> */}
      <Catagories />
      <Memories />
      <ProductGrid />
      <GotBulkOrder />
      <BackToTop />
    </div>
  );
};

export default Home;
