import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const imageRef = useRef(null);
  const ballRefs = useRef([]);

  useEffect(() => {
    const container = containerRef.current;
    const text = textRef.current;
    const image = imageRef.current;
    const balls = ballRefs.current;

    // GSAP animation timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: "top 80%",
        end: "bottom 20%",
        scrub: true,
      },
    });

    tl.fromTo(
      text,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: "power3.out",
      }
    )
      .fromTo(
        image,
        { opacity: 0, x: 100 },
        {
          opacity: 1,
          x: 0,
          duration: 1.5,
          ease: "power3.out",
        },
        "-=1.2" // Overlap the animations
      )
      .fromTo(
        image,
        { scale: 1.1 },
        {
          scale: 1,
          duration: 2,
          ease: "power3.out",
        },
        "-=1.0"
      );

    // Parallax effect on the SVG balls
    balls.forEach((ball, i) => {
      gsap.to(ball, {
        yPercent: i % 2 === 0 ? 20 : -20,
        xPercent: i % 2 === 0 ? -20 : 20,
        ease: "none",
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      });
    });

    // Hover effect for the image
    gsap.set(image, { scale: 1 });
    image.addEventListener("mouseenter", () => {
      gsap.to(image, {
        scale: 1.05,
        duration: 0.3,
        ease: "power2.out",
      });
    });

    image.addEventListener("mouseleave", () => {
      gsap.to(image, {
        scale: 1,
        duration: 0.3,
        ease: "power2.out",
      });
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !ballRefs.current.includes(el)) {
      ballRefs.current.push(el);
    }
  };

  return (
    <div
      ref={containerRef}
      className="relative bg-gray-50 py-12 overflow-hidden"
    >
      {/* SVG Balls for Background Decoration */}
      <div
        ref={addToRefs}
        className="absolute top-10 left-10 w-10 h-10 rounded-full bg-orange-600 opacity-70"
      ></div>
      <div
        ref={addToRefs}
        className="absolute top-20 right-20 w-16 h-16 rounded-full bg-blue-300 opacity-70"
      ></div>
      <div
        ref={addToRefs}
        className="absolute bottom-20 left-20 w-24 h-24 rounded-full bg-green-300 opacity-70"
      ></div>
      <div
        ref={addToRefs}
        className="absolute bottom-10 right-10 w-12 h-12 rounded-full bg-pink-300 opacity-70"
      ></div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">
        {/* Text Section */}
        <div
          ref={textRef}
          className="md:w-2/3 text-pretty md:text-left mb-8 md:mb-0 bg-orange-200 mr-10 p-8 h-[45vh] rounded-lg shadow-lg"
        >
          <h2 className="text-4xl font-poppins font-semibold text-gray-900 mb-4">
            About us
          </h2>
          <p className="text-2xl text-gray-700 leading-relaxed">
            Knots and Krafts was born out of the idea of bringing novelty yarns
            outside India by an enthusiastic yarn hoarder for the enthusiastic
            and budding crochet and knitting community of India. It is an effort
            undertaken to curate and bring you the best available yarn that the
            world has to offer at your doorstep in a cost-effective manner.
          </p>
        </div>
        {/* Image Section */}
        <div ref={imageRef} className="md:w-2/3">
          <img
            src="./isometric.png" // Replace with your image path
            alt="Knots & Krafts"
            className="rounded-lg shadow-lg object-cover transform transition-transform duration-300"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
