// Accordion.jsx
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Accordion = ({ sections }) => {
  const [openSection, setOpenSection] = useState(null); // Only one section open at a time

  const toggleOpen = (index) => {
    setOpenSection((prev) => (prev === index ? null : index));
  };

  return (
    <div className="space-y-4">
      {sections.map((section, index) => (
        <div
          key={index}
          className="border border-pink rounded-lg shadow-sm overflow-hidden"
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <button
            onClick={() => toggleOpen(index)}
            className="w-full px-6 py-4 flex justify-between items-center text-left bg-pink bg-opacity-10 hover:bg-pink hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-pink-500 transition-colors"
            aria-expanded={openSection === index}
            aria-controls={`accordion-content-${index}`}
          >
            <span className="text-lg font-medium text-gray-500">
              {section.title}
            </span>
            {openSection === index ? (
              <FaChevronUp className="text-pink" />
            ) : (
              <FaChevronDown className="text-pink" />
            )}
          </button>
          <AnimatePresence initial={false}>
            {openSection === index && (
              <motion.div
                key={`content-${index}`}
                id={`accordion-content-${index}`}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { height: "auto", opacity: 1 },
                  collapsed: { height: 0, opacity: 0 },
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="px-6 overflow-hidden bg-white"
                itemProp="acceptedAnswer"
                itemScope
                itemType="https://schema.org/Answer"
              >
                <div className="py-4 text-gray-500" itemProp="text">
                  {section.content}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
