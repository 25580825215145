import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DesignCircle from "./DesignCircle";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Memories = () => {
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Animation for the image
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, scale: 0.9, y: 50 },
      {
        opacity: 1,
        scale: 1,
        y: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    // Animation for the text section
    gsap.fromTo(
      textRef.current,
      { opacity: 0, x: -10 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    // Optional: Fade-in background decorative elements
    gsap.fromTo(
      sectionRef.current,
      { opacity: 0.9 },
      {
        opacity: 1,
        duration: 2,
        ease: "none",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top bottom",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <section
      ref={sectionRef}
      className="relative px-6 md:px-12 lg:px-20 my-2 bg-white overflow-hidden"
    >
      {/* Decorative Circles */}
      {/* <DesignCircle /> */}

      <div className="max-w-7xl mx-auto flex flex-col-reverse md:flex-row items-center justify-between gap-12">
        {/* Text Section */}
        <div
          ref={textRef}
          className="w-full md:w-1/2 text-gray-800 leading-relaxed text-md lg:text-lg flex flex-col"
        >
          <h2 className="lg:text-4xl text-center md:text-5xl font-bold mb-6 text-[32px] font-heading text-gray-700">
            Memories at Knots & Krafts
          </h2>
          <p className="mb-4 font-poppins text-gray-600">
            At Knots & Krafts, we are passionate about offering high-quality,
            handcrafted woolen threads that not only support creative projects
            but also preserve the rich tradition of wool craftsmanship.
          </p>
          <p className="mb-6 font-poppins text-gray-600">
            Each thread is a link to the past, evoking memories of cherished
            heirlooms, cozy garments, and handmade treasures passed down through
            generations. By choosing our woolen threads, you’re not just
            crafting; you’re weaving stories and connecting to a timeless
            heritage, where every stitch becomes a personal memory.
          </p>
          <Link
            to="/more-about-memories"
            className="self-start mx-auto  mt-4 px-6 py-3 bg-pink text-white rounded-md font-semibold transition-all duration-300 ease-in-out hover:bg-yellow hover:shadow-lg"
          >
            Read More
          </Link>
        </div>

        {/* Image Section */}
        <div
          ref={imageRef}
          className="w-full md:w-1/2 relative overflow-hidden rounded-lg shadow-2xl"
        >
          <img
            src="./memories.jpg"
            alt="Cherished memories captured in woolen threads"
            className="w-full h-72 sm:h-80 md:h-96 lg:h-[28rem] object-cover transform transition-transform duration-700 hover:scale-110"
            loading="lazy"
          />
          {/* Optional: Add an Overlay for Better Text Visibility */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-30"></div>
        </div>
      </div>

      {/* Decorative Triangle */}
      <div className="absolute left-0 top-[100%] w-full h-0 border-t-[10vh] border-t-transparent border-r-[100vw] border-r-[#FAE6CF] transform -scale-y-100 z-10"></div>
    </section>
  );
};

export default Memories;
