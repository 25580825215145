// src/components/Order.jsx

import React, { useState, useEffect } from "react";
import { getOrderByUser } from "../../api";
import { Link } from "react-router-dom";
import { FaBox, FaCalendarAlt, FaRupeeSign, FaTruck } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const { data } = await getOrderByUser();
        setOrders(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-lg font-semibold text-gray-600">Loading...</div>
      </div>
    );
  if (error)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-lg font-semibold text-red-500">Error: {error}</div>
      </div>
    );

  return (
    <div className="max-w-7xl mx-auto pt-20 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>My Orders - Customer Account</title>
        <meta
          name="description"
          content="View your recent orders, order details, and tracking information. Manage your orders from your customer account."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <h2 className="text-3xl font-extrabold text-gray-900 mb-8 text-center md:text-left">
        My Orders
      </h2>
      {orders.length === 0 ? (
        <p className="text-center text-gray-600">You have no orders.</p>
      ) : (
        <div className="space-y-8">
          {orders.map((order) => (
            <div
              key={order._id}
              className="bg-white p-4 md:p-6 rounded-lg shadow-lg border border-gray-200"
            >
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                <div>
                  <h3 className="text-xl font-semibold text-gray-800">
                    <FaBox className="inline-block mr-2 text-blue-500" />
                    Order ID: {order._id}
                  </h3>
                  <p className="text-gray-500 flex items-center mt-2">
                    <FaCalendarAlt className="inline-block mr-2 text-gray-400" />
                    Placed on: {new Date(order.createdAt).toLocaleDateString()}
                  </p>
                  <p className="text-gray-500 flex items-center mt-2">
                    <FaTruck className="inline-block mr-2 text-gray-400" />
                    Status: {order.status}
                  </p>
                  <p className="text-gray-500 flex items-center mt-2">
                    <FaRupeeSign className="inline-block mr-2 text-gray-400" />
                    Total: Rs. {order.totalPrice.toFixed(2)}
                  </p>

                  {/* Added Customer Details */}
                  <p className="text-gray-600 mt-4">
                    <strong>Customer:</strong>{" "}
                    {order.customerDetails
                      ? `${order.customerDetails.firstName} ${order.customerDetails.lastName}`
                      : "N/A"}
                  </p>
                  <p className="text-gray-600">
                    <strong>Email:</strong>{" "}
                    {order.customerDetails
                      ? order.customerDetails.email
                      : order.user?.email || "N/A"}
                  </p>
                  <p className="text-gray-600">
                    <strong>Phone:</strong>{" "}
                    {order.customerDetails
                      ? order.customerDetails.phone
                      : order.user?.phone || "N/A"}
                  </p>

                  {/* Added Payment Method */}
                  <p className="text-gray-600 mt-2">
                    <strong>Payment Method:</strong>{" "}
                    {order.paymentMethod || "N/A"}
                  </p>
                </div>
                <div className="text-right">
                  <Link
                    to={`/orders/${order._id}`}
                    className="text-blue-500 font-semibold hover:underline"
                  >
                    View Order Details
                  </Link>
                </div>
              </div>
              <div className="mt-4">
                {order.orderItems.map((item) => (
                  <div
                    key={item._id}
                    className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 border-t border-gray-200 pt-4 mt-4"
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/${item.image}`}
                      alt={item.name}
                      className="w-24 h-24 md:w-16 md:h-16 object-cover rounded"
                      loading="lazy"
                    />
                    <div className="flex-1">
                      <p className="text-gray-800 font-medium">{item.name}</p>
                      <p className="text-gray-500">Quantity: {item.quantity}</p>
                      <p className="text-gray-500">
                        Price: Rs. {(item.price * item.quantity).toFixed(2)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Order;
