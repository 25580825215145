import React from "react";
import Accordian from "../../components/final_components/accordian/Acordian";
import { Helmet } from "react-helmet";
import Accordion from "../../components/final_components/accordian/Acordian";
const FAQ = () => {
  const accordionData = [
    {
      title: "Do you have any discount codes available?",
      content: (
        <div>
          <p>
            Based on your cart value, all applicable discount coupons are
            automatically displayed on the cart and checkout pages.
          </p>
          <a
            href="#"
            className="text-blue-600 underline"
            rel="noopener noreferrer"
          >
            Some of our new launches and offers can be found here!
          </a>
          <div className="mt-2">
            <p className="font-semibold">Bulk Discount: 10% OFF</p>
            <p>on orders above ₹10,000*</p>
            <p className="text-sm">
              *Discounts apply to specific brands and categories. Once your
              order reaches the bulk discount threshold, you'll see the final
              discounted amount in your cart.
            </p>
            <div className="mt-2">
              <p>
                Stay tuned for sales, new products, and restocks by following us
                on:
              </p>
              <ul className="list-none">
                <li>
                  <a
                    href="https://www.facebook.com/magicneedlesindia"
                    className="text-blue-600"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Facebook @magicneedlesindia
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/magicneedlesindia"
                    className="text-blue-600"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Instagram @magicneedlesindia
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/hobbystoreindia"
                    className="text-blue-600"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Facebook Group @hobbystoreindia
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "How fast is shipping and how much does it cost?",
      content: (
        <div>
          <p className="font-semibold">Shipping</p>
          <p>
            Your order will be sent to our warehouse for packing at 8 am the
            next working day following your order placement. Delivery can take
            1-14 days depending on your location / proximity to Mumbai. Concerns
            or claims related to delivery must be brought forward within 7 days
            of the confirmed delivery date.
          </p>
          <p>
            <strong>Free Shipping</strong> is available for orders above ₹2500.
          </p>
        </div>
      ),
    },
    {
      title: "I need to cancel or modify my order.",
      content: (
        <p>
          Once an order is placed, it cannot be cancelled. If you wish to make
          modifications, such as changes in quantity or equivalent value
          adjustments, notify us before 8 am. No refunds will be issued for
          modifications, so ensure the total order value remains equivalent or
          higher.
        </p>
      ),
    },
    {
      title: "Where are Knots & Kraft® products made?",
      content: <p>Knots & Kraft® products are exclusively made in India.</p>,
    },
    {
      title: "Is your site secure?",
      content: (
        <p>
          Our secure servers protect your information using advanced encryption
          and firewall technology. Look for the lock icon in your browser’s
          address bar on secure pages. We use SSL encryption to protect personal
          information and do not store credit card details.
        </p>
      ),
    },
    {
      title: "What payment methods do you accept?",
      content: (
        <p>
          We accept prepaid orders and offer leading online payment options like
          Indian and International credit cards, debit cards, net banking, UPI,
          and wallets.
        </p>
      ),
    },
    {
      title: "Can I order a custom size or color to an existing design?",
      content: (
        <p>
          Generally, we do not accept custom designs unless you are a wholesaler
          able to meet our minimums. However, you can contact us, and we will
          try our best to accommodate your request.
        </p>
      ),
    },
    {
      title: "What happens if I order something and it's out of stock?",
      content: (
        <p>
          If an item is unavailable, we will inform you regarding the delay. If
          the yarn for hand-knitted products is available, we will hand-knit one
          on priority, possibly delaying the shipment by 1-2 days with your
          approval.
        </p>
      ),
    },
    {
      title: "I entered the wrong address, what should I do?",
      content: (
        <p>
          Contact us immediately. In many cases, we may be able to change the
          address if your order hasn’t already been processed or shipped.
        </p>
      ),
    },
    {
      title: "Where are you located?",
      content: (
        <p>
          We are located at:
          <br />
          <strong>Knots & Kraft®</strong>
          <br />
          21, Shiv Krupa Ind. Estate, LBS Road, Vikhroli West
          <br />
          Mumbai 400083, Maharashtra, India
        </p>
      ),
    },
    {
      title: "Do your photos match the product exactly?",
      content: (
        <p>
          Due to monitor settings, digital photography, and dye lot variations,
          we cannot guarantee that the color on your screen is an exact match to
          the product. We strive for accuracy, but screen images are intended as
          a guide only.
        </p>
      ),
    },
    {
      title: "How do I return an order?",
      content: (
        <p>
          Please read our{" "}
          <a
            href="/policy/refund"
            className="text-blue-600 underline"
            rel="noopener noreferrer"
          >
            Return & Refund Policy
          </a>{" "}
          for complete details.
        </p>
      ),
    },
    {
      title: "What is Knots & Kraft' Fair Usage Policy?",
      content: (
        <p>
          We strive to provide the best experience, but we have noticed that
          some accounts abuse our payment option and returns policy. Accounts
          with a high percentage of returns or unaccepted shipments may be
          disabled to protect regular customers.
        </p>
      ),
    },
  ];
  return (
    <div className=" mx-auto">
      {/* SEO Optimization with React Helmet */}
      <Helmet>
        <title>Frequently Asked Questions - Knots & Krafts</title>
        <meta
          name="description"
          content="Find answers to the most commonly asked questions about Knots & Krafts products, shipping, returns, and more."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Header Section */}
      <header className="relative h-64 sm:h-80 md:h-96 flex flex-col justify-center items-center text-center">
        {/* Background Image */}
        <img
          src="./shop_hero.jpg"
          alt="FAQ - Knots & Krafts"
          className="absolute inset-0 w-full h-full object-cover opacity-100"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        {/* Content */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
            Frequently Asked Questions
          </h1>
          <nav className="mt-4 text-sm sm:text-base">
            <a href="/" className="hover:underline">
              Home
            </a>{" "}
            &gt; <span>FAQ</span>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-4xl mx-auto py-12 px-6 sm:px-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-pink text-center mb-8">
          How Can We Help You?
        </h2>
        <div className="mt-6" itemScope itemType="https://schema.org/FAQPage">
          <Accordion sections={accordionData} />
        </div>
      </main>
    </div>
  );
};

export default FAQ;
