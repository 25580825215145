// src/components/CartComponent.jsx

import React, { useState, useEffect, useRef } from "react";
import {
  getCartItems,
  removeFromCart,
  updateCartQuantity,
} from "../../utils/cartUtils";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { getCouponById } from "../../api";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Placeholder image for products without an image
const placeholderImage = "https://via.placeholder.com/64";

// Lazy load images using the loading attribute
const LazyImage = ({ src, alt }) => (
  <img
    src={src || placeholderImage}
    alt={alt || "Product Image"}
    loading="lazy"
    className="w-20 h-20 object-cover rounded-md"
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = placeholderImage;
    }}
  />
);

const CartComponent = () => {
  // State Hooks
  const [cartItems, setCartItems] = useState([]);
  const [couponCode, setCouponCode] = useState(""); // Coupon code input
  const [discount, setDiscount] = useState(0); // Discount amount
  const [couponValid, setCouponValid] = useState(false); // Track if coupon is valid
  const [appliedCoupon, setAppliedCoupon] = useState(null); // Track applied coupon
  const [finalPrice, setFinalPrice] = useState(0); // Final price after coupon deduction
  const [applyingCoupon, setApplyingCoupon] = useState(false); // Track coupon application state

  const FREE_DELIVERY_THRESHOLD = 2500; // Threshold for free delivery

  const navigate = useNavigate();
  const gridRef = useRef(null);

  // Function to notify other components about cart updates
  const notifyCartUpdate = () => {
    window.dispatchEvent(new Event("cartUpdated"));
  };

  // Fetch cart items on component mount
  useEffect(() => {
    const loadCartItems = () => {
      try {
        const items = getCartItems();
        setCartItems(items);
      } catch (err) {
        toast.error("Failed to load cart items.");
        console.error("Failed to load cart items:", err);
      }
    };
    loadCartItems();
  }, []);

  // Recalculate the final price whenever the cart items or discount change
  useEffect(() => {
    setFinalPrice(calculateTotalPrice());
  }, [cartItems, discount]);

  // GSAP Animation for Cart Items
  useEffect(() => {
    if (cartItems.length > 0 && gridRef.current) {
      gsap.fromTo(
        gridRef.current.children,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: gridRef.current,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }
  }, [cartItems]);

  // Handle removing an item from the cart
  const handleRemoveItem = (productId) => {
    removeFromCart(productId);
    setCartItems(getCartItems());
    notifyCartUpdate(); // Notify Navbar
    toast.info("Item removed from cart.");
  };

  // Handle quantity change for a cart item
  const handleQuantityChange = (productId, quantity) => {
    if (quantity < 1) {
      toast.error("Quantity cannot be less than 1.");
      return;
    }
    updateCartQuantity(productId, quantity);
    setCartItems(getCartItems());
    notifyCartUpdate(); // Notify Navbar
    toast.success("Quantity updated.");
  };

  // Handle proceeding to checkout
  const handleCheckout = () => {
    // Set the final price for the checkout process
    const checkoutFinalPrice = calculateTotalPrice();
    navigate("/checkout", { state: { finalPrice: checkoutFinalPrice } }); // Pass final price to the checkout page
  };

  // Calculate subtotal for an item
  const calculateSubtotal = (item) => {
    const price = parseFloat(item?.price?.sale || item?.price?.regular || 0);
    const quantity = parseInt(item?.quantity || 1, 10);
    return price * quantity;
  };

  // Calculate total price after discount
  const calculateTotalPrice = () => {
    const total = cartItems
      .reduce((total, item) => total + calculateSubtotal(item), 0)
      .toFixed(2);
    const final = (total - discount).toFixed(2);
    return final < 0 ? "0.00" : final; // Ensure final price is not negative
  };

  // Calculate remaining amount for free delivery
  const calculateRemainingForFreeDelivery = () => {
    const remaining = FREE_DELIVERY_THRESHOLD - finalPrice;
    return remaining > 0 ? remaining.toFixed(2) : 0;
  };

  // Handle applying the coupon code
  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      toast.error("Please enter a coupon code.");
      return;
    }

    setApplyingCoupon(true);
    try {
      const formattedCode = couponCode.trim().toUpperCase();
      console.log(formattedCode); // Standardize coupon code format
      const response = await getCouponById(formattedCode); // Use the existing API function

      if (response.data && parseInt(response.data.available, 10) > 0) {
        const discountValue = parseFloat(response.data.value) || 0; // Ensure the discount is a valid number

        // Prevent discount from exceeding total
        const totalBeforeDiscount = cartItems.reduce(
          (sum, item) => sum + calculateSubtotal(item),
          0
        );
        const applicableDiscount =
          discountValue > totalBeforeDiscount
            ? totalBeforeDiscount
            : discountValue;

        setDiscount(applicableDiscount); // Apply the coupon discount
        setCouponValid(true);
        setAppliedCoupon(formattedCode); // Store the applied coupon code
        toast.success(`Coupon applied! You saved ₹${applicableDiscount}`);
        notifyCartUpdate(); // Notify Navbar if total changes
      } else {
        setDiscount(0);
        setCouponValid(false);
        setAppliedCoupon(null);
        toast.error("Invalid or unavailable coupon code.");
      }
    } catch (error) {
      setDiscount(0);
      setCouponValid(false);
      setAppliedCoupon(null);
      if (error.response && error.response.status === 404) {
        toast.error("Coupon code not found.");
      } else {
        toast.error("Failed to apply coupon. Please try again later.");
      }
      console.error("Failed to apply coupon:", error);
    } finally {
      setApplyingCoupon(false);
    }
  };

  // Handle removing the applied coupon
  const handleRemoveCoupon = () => {
    setDiscount(0); // Reset the discount
    setCouponValid(false); // Mark coupon as invalid
    setAppliedCoupon(null); // Clear the applied coupon
    setCouponCode(""); // Clear the coupon input field
    notifyCartUpdate(); // Notify Navbar
    toast.info("Coupon removed.");
  };

  // Render empty cart state
  if (cartItems.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center bg-gray-50 min-h-screen">
        <img
          src="./empty_cart.jpeg"
          height={300}
          width={300}
          alt="Empty Cart"
        />
        <h2 className="text-3xl font-noto font-semibold mb-2 text-gray-800">
          Your cart is empty
        </h2>
        <p className="text-gray-600 mb-6 text-center font-poppins px-4">
          Looks like you haven't added anything to your cart yet.
        </p>
        <Link to="/shop">
          <button className="px-6 py-3 bg-pink text-white rounded-md shadow hover:bg-pink-600 transition-colors duration-300">
            Start Shopping
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* SEO Meta Tags using react-helmet */}
      <Helmet>
        <title>Your Cart | Your Store</title>
        <meta
          name="description"
          content="Review the items in your shopping cart before proceeding to checkout."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-semibold mb-8 text-center text-gray-800">
          Your Cart
        </h2>
        <div className="flex flex-col lg:flex-row">
          {/* Left Section: Cart Items */}
          <div className="lg:w-3/4 lg:mr-8">
            {/* Table Layout for Desktop */}
            <div className="hidden lg:block overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-100 text-gray-600">
                    <th className="p-4 text-left">Product</th>
                    <th className="p-4 text-left">Price</th>
                    <th className="p-4 text-left">Quantity</th>
                    <th className="p-4 text-left">Subtotal</th>
                    <th className="p-4"></th>
                  </tr>
                </thead>
                <tbody ref={gridRef}>
                  {cartItems.map((item) => (
                    <tr
                      key={item._id}
                      className="border-b hover:bg-gray-50 transition-colors"
                    >
                      <td className="p-4 flex items-center space-x-4">
                        <Link to={`/shop/${item._id}`}>
                          <LazyImage
                            src={
                              item.images && item.images.length > 0
                                ? `${process.env.REACT_APP_URL}/${item.images[0].url}`
                                : placeholderImage
                            }
                            alt={item.name}
                          />
                        </Link>
                        <Link to={`/shop/${item._id}`}>
                          <span className="font-medium text-gray-800 hover:text-pink-500 transition-colors duration-300">
                            {item.name}
                          </span>
                        </Link>
                      </td>
                      <td className="p-4 text-gray-700">
                        ₹{" "}
                        {parseFloat(
                          item.price.sale || item.price.regular || 0
                        ).toFixed(2)}
                      </td>
                      <td className="p-4">
                        <input
                          type="number"
                          value={item.quantity}
                          min="1"
                          className="w-16 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                          onChange={(e) =>
                            handleQuantityChange(
                              item._id,
                              parseInt(e.target.value, 10)
                            )
                          }
                          aria-label={`Change quantity for ${item.name}`}
                        />
                      </td>
                      <td className="p-4 text-gray-700">
                        ₹ {calculateSubtotal(item).toFixed(2)}
                      </td>
                      <td className="p-4">
                        <button
                          onClick={() => handleRemoveItem(item._id)}
                          className="text-red-500 hover:text-red-700 transition-colors"
                          aria-label={`Remove ${item.name} from cart`}
                        >
                          <FaTrash className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Card Layout for Mobile and Tablet */}
            <div className="lg:hidden grid grid-cols-1 gap-6" ref={gridRef}>
              {cartItems.map((item) => (
                <div
                  key={item._id}
                  className="bg-white shadow-md rounded-lg overflow-hidden flex flex-row"
                >
                  {/* Product Image */}
                  <Link to={`/shop/${item._id}`} className="w-1/3">
                    <LazyImage
                      src={
                        item.images && item.images.length > 0
                          ? `${process.env.REACT_APP_URL}/${item.images[0].url}`
                          : placeholderImage
                      }
                      alt={item.name}
                    />
                  </Link>

                  {/* Product Details */}
                  <div className="w-2/3 p-4 flex flex-col justify-between">
                    <div>
                      <Link to={`/shop/${item._id}`}>
                        <h3 className="text-lg font-semibold text-gray-800 hover:text-pink-500 transition-colors duration-300">
                          {item.name}
                        </h3>
                      </Link>
                      <p className="text-gray-700 mt-2">
                        ₹{" "}
                        {parseFloat(
                          item.price.sale || item.price.regular || 0
                        ).toFixed(2)}
                      </p>
                    </div>

                    <div className="mt-4 flex items-center justify-between">
                      <div className="flex items-center">
                        <label
                          htmlFor={`quantity-${item._id}`}
                          className="mr-2 text-gray-600"
                        >
                          Qty:
                        </label>
                        <input
                          type="number"
                          id={`quantity-${item._id}`}
                          value={item.quantity}
                          min="1"
                          className="w-12 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                          onChange={(e) =>
                            handleQuantityChange(
                              item._id,
                              parseInt(e.target.value, 10)
                            )
                          }
                          aria-label={`Change quantity for ${item.name}`}
                        />
                      </div>
                      <button
                        onClick={() => handleRemoveItem(item._id)}
                        className="text-red-500 hover:text-red-700 transition-colors"
                        aria-label={`Remove ${item.name} from cart`}
                      >
                        <FaTrash className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Desktop Checkout Button */}
            <div className="hidden lg:block mt-6">
              <button
                onClick={handleCheckout}
                className="w-[50%] mx-[25%] bg-pink text-white py-3 rounded-md hover:bg-pink-600 transition-colors duration-300"
                aria-label="Proceed to Checkout"
              >
                Proceed to Checkout
              </button>
            </div>
          </div>

          {/* Right Section: Cart Totals and Coupon Code */}
          <div className="lg:w-1/3 p-8 bg-gray-100 rounded-lg mt-8 lg:mt-0">
            <h3 className="text-2xl font-semibold mb-6 text-gray-800">
              Cart Totals
            </h3>
            <div className="flex justify-between mb-4">
              <span className="text-gray-700">Subtotal</span>
              <span className="font-semibold text-gray-700">
                ₹{" "}
                {cartItems
                  .reduce((sum, item) => sum + calculateSubtotal(item), 0)
                  .toFixed(2)}
              </span>
            </div>

            {/* Coupon Input */}
            {!appliedCoupon ? (
              <div className="mb-6">
                <label
                  htmlFor="couponCode"
                  className="block mb-2 font-medium text-gray-700"
                >
                  Apply Coupon
                </label>
                <div className="flex">
                  <input
                    type="text"
                    id="couponCode"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    placeholder="Enter coupon code"
                    className="flex-1 p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    aria-label="Coupon Code"
                  />
                  <button
                    onClick={handleApplyCoupon}
                    className="px-4 bg-pink text-white rounded-r-md hover:bg-pink-600 transition-colors duration-300 disabled:bg-pink-300"
                    disabled={applyingCoupon}
                    aria-label="Apply Coupon"
                  >
                    {applyingCoupon ? "Applying..." : "Apply"}
                  </button>
                </div>
                {couponValid && appliedCoupon && (
                  <p className="text-green-500 mt-2">
                    Coupon "{appliedCoupon}" applied! You saved ₹{discount}.
                  </p>
                )}
              </div>
            ) : (
              <div className="mb-6">
                <div className="flex justify-between items-center bg-green-100 p-3 rounded-md">
                  <span className="text-green-700 font-medium">
                    Coupon "{appliedCoupon}" Applied
                  </span>
                  <button
                    onClick={handleRemoveCoupon}
                    className="text-red-600 hover:text-red-800 transition-colors"
                    aria-label="Remove Coupon"
                  >
                    <FaTrash className="w-4 h-4" />
                  </button>
                </div>
              </div>
            )}

            <div className="flex justify-between mb-6">
              <span className="text-gray-700">Total</span>
              <span className="font-bold text-xl text-gray-700">
                ₹ {finalPrice}
              </span>
            </div>

            {/* Dynamic Free Delivery Message */}
            <div className="mb-6">
              <div className="bg-gray-200 text-gray-700 text-center text-lg font-medium mr-2 mb-2 py-2">
                {finalPrice >= FREE_DELIVERY_THRESHOLD
                  ? "Congratulations! You qualify for free delivery."
                  : `Add ₹${calculateRemainingForFreeDelivery()} more to get free delivery.`}
              </div>
            </div>

            {/* Mobile Checkout Button */}
            <div className="lg:hidden mt-6">
              <button
                onClick={handleCheckout}
                className="w-full bg-pink text-white py-3 rounded-md hover:bg-pink-600 transition-colors duration-300"
                aria-label="Proceed to Checkout"
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartComponent;
