// src/components/ProductGrid.js
import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ProductCard from "./ProductCard";
import { fetchEightRandomProducts } from "../../api";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

gsap.registerPlugin(ScrollTrigger);

const ProductGrid = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const productRefs = useRef([]);

  // Fetch products data
  useEffect(() => {
    const loadProducts = async () => {
      try {
        const response = await fetchEightRandomProducts();
        setProducts(response.data);
      } catch {
        setError("Failed to load products.");
      } finally {
        setLoading(false);
      }
    };
    loadProducts();
  }, []);

  // GSAP Animation for Product Cards
  useEffect(() => {
    if (products.length > 0) {
      // Clear previous refs to prevent duplication
      productRefs.current = productRefs.current.slice(0, products.length);

      gsap.fromTo(
        productRefs.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: "power3.out",
          stagger: 0.2,
          scrollTrigger: {
            trigger: gridRef.current,
            start: "top 85%",
            end: "bottom 25%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }
  }, [products]);

  // Function to add refs to productRefs
  const addToRefs = (el) => {
    if (el && !productRefs.current.includes(el)) {
      productRefs.current.push(el);
    }
  };

  if (loading)
    return (
      <div className="text-center text-2xl py-20 text-gray-700">
        Loading products...
      </div>
    );

  if (error)
    return (
      <div className="text-center text-red-500 text-xl py-20">{error}</div>
    );

  return (
    <section className="w-full">
      <div
        ref={gridRef}
        className="mx-auto max-w-6xl py-12 p-2 sm:px-6 pt-16 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          {/* Section Title */}
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-center text-gray-900 mb-4">
            OUR PRODUCTS
          </h2>
          <p className="text-xl sm:text-2xl font-poppins text-center text-gray-800 mt-2">
            Browse our collection of different products
          </p>

          {/* Product Grid with Corrected Class Names */}
          <div className="mt-10 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-5 mx-auto">
            {products.map((product) => (
              <div key={product._id} ref={addToRefs} className="">
                <ProductCard product={product} />
              </div>
            ))}
          </div>

          {/* Shop Now Button */}
          <div className="mt-12 text-center">
            <Link
              to="/shop"
              className="inline-block rounded-md bg-black text-white font-semibold px-8 py-3 hover:bg-[#A6781E] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B88E2F]"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

// Skeleton Loader for Product Cards
const SkeletonProductCard = () => (
  <div className="p-4 border rounded-md shadow-sm">
    <Skeleton height={200} />
    <Skeleton height={20} style={{ marginTop: 10 }} />
    <Skeleton height={20} width={80} style={{ marginTop: 10 }} />
  </div>
);

export default ProductGrid;
