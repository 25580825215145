// src/context/AuthContext.jsx

import { createContext, useState, useEffect } from "react";
import { getProfile } from "../api";
import { toast } from "react-toastify"; // Import toast for notifications

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data } = await getProfile();
        setUser(data);
      } catch (error) {
        setUser(null);
        // console.error("Failed to fetch user profile:", error);
        // toast.error("Failed to fetch user profile.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
