import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DesignCircle from "./DesignCircle";
import { Link } from "react-router-dom";
import { GiWool } from "react-icons/gi";

gsap.registerPlugin(ScrollTrigger);

const WhyKnotsAndKrafts = () => {
  const imageRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    // Image animation
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, scale: 0.95 },
      {
        opacity: 1,
        scale: 1,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );

    // Text animation
    gsap.fromTo(
      textRef.current,
      { opacity: 0, x: 50 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <section className="relative py-16 px-4 max-w-7xl mx-auto sm:px-6 lg:px-8">
      {/* <DesignCircle /> */}
      <div className="flex flex-col md:flex-row gap-10 items-center">
        {/* Image Section */}
        <div className="flex justify-center w-full md:w-1/2">
          <div className="shadow-lg transition-transform duration-500 hover:scale-105">
            <img
              src="./WhyKnotsAndKrafts.webp" // Ensure the correct image path
              alt="High-quality yarns and knitted fabrics"
              className="object-cover h-104 w-full rounded-md"
              loading="lazy"
            />
          </div>
        </div>

        {/* Text Section */}
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="flex justify-center md:justify-start items-center text-3xl md:text-4xl p-2 sm:text-3xl lg:text-5xl font-heading font-bold text-gray-500 mb-6">
            Why Knots and Krafts
            <GiWool
              size={60}
              className="inline-block mb-2 ml-2 text-pink-500"
            />
          </h2>

          <p className="text-md sm:text-xl text-gray-700 leading-relaxed mb-6">
            All Knots and Krafts yarns, whether imported or locally sourced,
            undergo rigorous quality checks to ensure superior durability and
            performance.
          </p>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed">
            Enhance the lifespan of your crocheted or knitted garments and
            articles with our premium yarns.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyKnotsAndKrafts;
