import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { gsap } from "gsap";

const RefundPolicy = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    // GSAP Animations
    gsap.fromTo(
      containerRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 0.8, ease: "power3.out" }
    );
  }, []);

  return (
    <div
      ref={containerRef}
      className="max-w-7xl mx-auto p-6 sm:p-8"
      itemScope
      itemType="https://schema.org/WebPage"
    >
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Refund Policy - Knots & Krafts</title>
        <meta
          name="description"
          content="Read Knots & Krafts' refund policy for hand-knitted and crochet products. Learn about our order cancellation, return, and refund procedures."
        />
        <meta
          name="keywords"
          content="Knots & Krafts, Refund Policy, Return Policy, Cancellation Policy, Hand-Knitted Products, Crochet Products, Shipping Policy"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://www.knotsandkrafts.com/refund-policy"
        />
      </Helmet>

      {/* Refund Policy Content */}
      <header className="mb-8 text-center pt-[10vh]">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-900">
          Refund Policy
        </h1>
        {/* <p className="mt-2 text-lg text-gray-700">
          Last updated: [Insert Date]
        </p> */}
      </header>

      <main className="prose  prose-pink max-w-none">
        <p>
          At <strong>Knots & Krafts</strong>, we are committed to providing you
          with high-quality hand-knitted and crochet products. Please read our
          refund policy carefully to understand your rights and our obligations.
        </p>

        <h2 className="mt-8">Order Cancellation & Modification Policy</h2>

        <h3 className="mt-6">Non-Cancellation of Orders</h3>
        <p>
          Once an order is placed, it cannot be cancelled. We initiate the
          packing process promptly to ensure timely dispatch and delivery of
          your products.
        </p>

        <h3 className="mt-6">Order Modifications</h3>
        <p>
          If you wish to make any modifications, such as changing quantities or
          adjusting the order value, please contact us before 8 AM on the next
          working day following your order placement. Note that no refunds will
          be issued for modifications; ensure the total order value remains
          equivalent or higher.
        </p>

        <h3 className="mt-6">Processing Time</h3>
        <p>
          Orders are sent to our warehouse for packing at 8 AM on the next
          working day after your order is placed.
        </p>

        <h3 className="mt-6">Delivery Timeframe</h3>
        <ul className="list-disc ml-6">
          <li>
            <strong>Domestic Shipping:</strong> Delivery within India typically
            takes 2-14 days, depending on your proximity to Bengaluru.
          </li>
          <li>
            <strong>International Shipping:</strong> Delivery outside India can
            take 2-4 weeks, depending on your location.
          </li>
        </ul>

        <h3 className="mt-6">Order Accuracy</h3>
        <p>
          Please review all order details carefully, including product
          specifications, quantities, and shipping addresses, before confirming
          your order.
        </p>

        <h2 className="mt-10">Return Policy</h2>

        <h3 className="mt-6">
          Knitting and Crochet Supplies & Related Products
        </h3>
        <p>
          All yarns, threads, needles, hooks, accessories, and items related to
          knitting, crochet, and sewing are <strong>non-refundable</strong>,
          <strong> non-exchangeable</strong>, and{" "}
          <strong>non-returnable</strong>. If the packaging is damaged or
          tampered with upon delivery, please refuse to accept the shipment and
          notify us immediately by sending images via WhatsApp at{" "}
          <a href="tel:+919820293979" className="text-pink-600">
            +91 98202 93979
          </a>
          .
        </p>

        <h3 className="mt-6">Hand-Knitted & Crocheted Products</h3>
        <p>
          If you are not completely satisfied with our hand-knitted or crocheted
          products, you may return them within <strong>7 days</strong> of
          delivery for a refund. The items must be in their original, unused,
          and unworn condition with all tags attached.
        </p>

        <h3 className="mt-6">Return Instructions</h3>
        <p>
          To initiate a return, please contact our customer support at{" "}
          <a href="mailto:support@knotsandkrafts.com" className="text-pink-600">
            support@knotsandkrafts.com
          </a>{" "}
          with your order details and reason for return.
        </p>

        <h3 className="mt-6">Damaged or Defective Products</h3>
        <p>
          If you receive a defective or damaged product, please provide an
          unpacking video as proof and contact us within 7 days of delivery. We
          will review your claim and, if valid, issue a refund to your original
          payment method.
        </p>

        <h2 className="mt-10">Refund Policy</h2>
        <p>
          Once we receive and inspect your returned item, we will notify you of
          the approval or rejection of your refund. Approved refunds will be
          processed, and a credit will automatically be applied to your original
          payment method within a certain number of days.
        </p>

        <h3 className="mt-6">Non-Refundable Situations</h3>
        <p>Refunds will not be issued in the following cases:</p>
        <ul className="list-disc ml-6">
          <li>Incorrect or incomplete shipping address provided.</li>
          <li>Consignee unavailable or uncontactable during delivery.</li>
          <li>Shipment refused by the consignee.</li>
          <li>
            Items not in their original condition, damaged, or missing parts for
            reasons not due to our error.
          </li>
          <li>Items returned after 7 days of delivery.</li>
        </ul>

        <h3 className="mt-6">Shipping Returns</h3>
        <p>
          To return your product, please mail it to:
          <br />
          <strong>
            Knots & Krafts,
            <br />
            302, JSJ Opal Apartments,
            <br />
            BHEL Officers Layout, Jayanagar,
            <br />
            Bengaluru, Karnataka 560029, India
          </strong>
          .
        </p>
        <p>
          You will be responsible for paying your own shipping costs for
          returning your item. Shipping costs are non-refundable.
        </p>

        <h3 className="mt-6">Need Help?</h3>
        <p>
          For any questions regarding our refund policy, please contact us at{" "}
          <a href="mailto:support@knotsandkrafts.com" className="text-pink-600">
            support@knotsandkrafts.com
          </a>{" "}
          or call us at{" "}
          <a href="tel:+919480424751" className="text-pink-600">
            +91 94804 24751
          </a>
          .
        </p>
      </main>
    </div>
  );
};

export default RefundPolicy;
