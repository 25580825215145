// src/components/OrderDetails.jsx

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrderById } from "../../api";
import {
  FaBox,
  FaTruck,
  FaRupeeSign,
  FaMapMarkerAlt,
  FaClipboardList,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaCreditCard,
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const OrderDetailsComponent = () => {
  const { id } = useParams(); // Get order ID from URL
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const { data } = await getOrderById(id);
        if (!data) {
          setError("Order not found.");
        } else {
          setOrder(data);
        }
      } catch (err) {
        setError(
          err.response?.data?.message ||
            "An error occurred while fetching order details."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  if (loading)
    return (
      <div className="max-w-5xl pt-[20vh] mx-auto px-4 sm:px-6 lg:px-8">
        <Skeleton height={40} count={8} />
        <Skeleton height={30} count={5} className="mt-4" />
      </div>
    );
  if (error)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-lg font-semibold text-red-500">Error: {error}</div>
      </div>
    );

  // If order data is missing, do not show the details
  if (!order) return <div>No order details available.</div>;

  return (
    <div className="max-w-7xl pt-[10vh] mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Order Details - Order #{id}</title>
        <meta
          name="description"
          content={`View details for order ${id}, including the items ordered, shipping information, and status.`}
        />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href={`http://localhost:3000/orders/${id}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Order",
            orderNumber: order._id,
            orderStatus: order.status,
            priceCurrency: "INR",
            price: order.totalPrice.toFixed(2),
            orderedItem: order.orderItems.map((item) => ({
              "@type": "Product",
              name: item.name,
              price: item.price,
              quantity: item.quantity,
              image: `${process.env.REACT_APP_URL}/${item.image}`,
            })),
            customer: {
              "@type": "Person",
              name: order.customerDetails
                ? `${order.customerDetails.firstName} ${order.customerDetails.lastName}`
                : order.user?.name || "N/A",
              email: order.customerDetails
                ? order.customerDetails.email
                : order.user?.email || "N/A",
              telephone: order.customerDetails
                ? order.customerDetails.phone
                : order.user?.phone || "N/A",
            },
            orderDelivery: {
              "@type": "ParcelDelivery",
              deliveryAddress: {
                "@type": "PostalAddress",
                addressLocality: order.shippingAddress.city,
                addressCountry: order.shippingAddress.country,
              },
            },
          })}
        </script>
      </Helmet>

      <h2 className="text-3xl font-extrabold text-gray-900 mb-8">
        Order Details
      </h2>

      <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 space-y-4">
        {/* Order Summary */}
        {order._id && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaBox className="text-blue-500 mr-2" />
              <span className="font-semibold">Order ID:</span>
            </div>
            <span>{order._id}</span>
          </div>
        )}
        {order.status && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaClipboardList className="text-blue-500 mr-2" />
              <span className="font-semibold">Order Status:</span>
            </div>
            <span className="capitalize">{order.status}</span>
          </div>
        )}
        {order.trackingDetails?.trackingCompany && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaTruck className="text-blue-500 mr-2" />
              <span className="font-semibold">Tracking Company:</span>
            </div>
            <span>{order.trackingDetails.trackingCompany}</span>
          </div>
        )}
        {order.trackingDetails?.trackingID && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaTruck className="text-blue-500 mr-2" />
              <span className="font-semibold">Tracking ID:</span>
            </div>
            <span>{order.trackingDetails.trackingID}</span>
          </div>
        )}
        {order.totalPrice != null && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaRupeeSign className="text-blue-500 mr-2" />
              <span className="font-semibold">Total Price:</span>
            </div>
            <span>Rs. {order.totalPrice.toFixed(2)}</span>
          </div>
        )}
        {order.shippingAddress && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-blue-500 mr-2" />
              <span className="font-semibold">Shipping Address:</span>
            </div>
            <span>
              {order.shippingAddress.address || "N/A"},{" "}
              {order.shippingAddress.city || "N/A"},
              {order.shippingAddress.postalCode || "N/A"},{" "}
              {order.shippingAddress.country || "N/A"}
            </span>
          </div>
        )}

        {/* Added Customer Details */}
        {order.customerDetails || order.user ? (
          <>
            <div className="flex justify-between text-gray-700">
              <div className="flex items-center">
                <FaUser className="text-blue-500 mr-2" />
                <span className="font-semibold">Customer:</span>
              </div>
              <span>
                {order.customerDetails
                  ? `${order.customerDetails.firstName} ${order.customerDetails.lastName}`
                  : order.user?.name || "N/A"}
              </span>
            </div>
            <div className="flex justify-between text-gray-700">
              <div className="flex items-center">
                <FaEnvelope className="text-blue-500 mr-2" />
                <span className="font-semibold">Email:</span>
              </div>
              <span>
                {order.customerDetails
                  ? order.customerDetails.email
                  : order.user?.email || "N/A"}
              </span>
            </div>
            <div className="flex justify-between text-gray-700">
              <div className="flex items-center">
                <FaPhone className="text-blue-500 mr-2" />
                <span className="font-semibold">Phone:</span>
              </div>
              <span>
                {order.customerDetails
                  ? order.customerDetails.phone
                  : order.user?.phone || "N/A"}
              </span>
            </div>
          </>
        ) : null}

        {/* Added Payment Method */}
        {order.paymentMethod && (
          <div className="flex justify-between text-gray-700">
            <div className="flex items-center">
              <FaCreditCard className="text-blue-500 mr-2" />
              <span className="font-semibold">Payment Method:</span>
            </div>
            <span>{order.paymentMethod || "N/A"}</span>
          </div>
        )}

        {/* Items Ordered */}
        {order.orderItems && order.orderItems.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-4">
              <FaClipboardList className="text-blue-500 mr-2 inline" />
              Items Ordered:
            </h3>
            <div className="space-y-4">
              {order.orderItems.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-4 border-t border-gray-200 pt-4"
                >
                  {item.image && (
                    <img
                      src={
                        item.image.startsWith("http")
                          ? item.image
                          : `${process.env.REACT_APP_URL}/${item.image}`
                      }
                      alt={item.name || "Product Image"}
                      className="w-20 h-20 object-cover rounded"
                      loading="lazy"
                    />
                  )}
                  <div className="flex-1">
                    <p className="text-gray-800 font-medium">
                      {item.name || "Item Name"}
                    </p>
                    {item.quantity != null && (
                      <p className="text-gray-500">Quantity: {item.quantity}</p>
                    )}
                    {item.price != null && (
                      <p className="text-gray-500">
                        Price: Rs. {item.price.toFixed(2)}
                      </p>
                    )}
                  </div>
                  {item.price != null && item.quantity != null && (
                    <div className="text-gray-700 font-semibold">
                      Rs. {(item.price * item.quantity).toFixed(2)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetailsComponent;
