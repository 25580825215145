// src/components/Navbar.jsx

import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Knots&KraftsLogo.png";
import { getSearchSuggestions, searchProducts } from "../../api";
import { getCartItems } from "../../utils/cartUtils"; // Import getCartItems
import debounce from "lodash.debounce";
import {
  IoBagHandleOutline,
  IoHeartOutline,
  IoPersonOutline,
  IoSearchOutline,
  IoHomeOutline,
} from "react-icons/io5";
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import { GiSpaceNeedle, GiWool } from "react-icons/gi";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isShopDropdownOpen, setIsShopDropdownOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navigate = useNavigate();

  // State for cart count
  const [cartCount, setCartCount] = useState(0);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    // Reset search state when opening/closing
    if (!isSearchOpen) {
      setSearchQuery("");
      setSearchResults([]);
      setSearchSuggestions([]);
      setError(null);
      setCurrentPage(1);
      setTotalPages(1);
    }
  };

  // Function to calculate total items in cart
  const calculateCartCount = () => {
    const items = getCartItems();
    const count = items.reduce(
      (total, item) => total + (item.quantity || 1),
      0
    );
    setCartCount(count);
  };

  // Fetch cart count on mount and listen for cart updates
  useEffect(() => {
    calculateCartCount();

    // Listener for cart updates
    const handleCartUpdate = () => {
      calculateCartCount();
    };

    window.addEventListener("cartUpdated", handleCartUpdate);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []);

  // Debounced function for fetching search suggestions
  const debouncedFetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.trim() === "") {
        setSearchSuggestions([]);
        return;
      }

      try {
        const { data } = await getSearchSuggestions(query);
        setSearchSuggestions(data);
      } catch (err) {
        console.error("Error fetching search suggestions:", err);
        // Optionally handle suggestion errors
      }
    }, 300), // 300ms debounce
    []
  );

  const handleSearchInput = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFetchSuggestions(query);
  };

  // Helper function to extract name before "-"
  const getDisplayName = (name) => {
    if (typeof name !== "string") return "";
    const index = name.indexOf("-");
    return index !== -1 ? name.substring(0, index).trim() : name.trim();
  };

  const performSearch = async (query, page = 1) => {
    if (query.trim() === "") return;

    setIsSearching(true);
    setError(null);
    try {
      const { data } = await searchProducts(query, page, 20); // Assuming limit is 20
      setSearchResults(data.products);
      setTotalPages(data.pages);
      setCurrentPage(data.page);
    } catch (err) {
      console.error("Error searching products:", err);
      setError("Failed to fetch search results.");
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    performSearch(searchQuery, 1);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setSearchSuggestions([]);
    performSearch(suggestion, 1);
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    performSearch(searchQuery, newPage);
  };

  // **New Function to Toggle Shop Dropdown**
  const toggleShopDropdown = () => {
    setIsShopDropdownOpen(!isShopDropdownOpen);
  };

  return (
    <nav className="sticky top-0 left-0 w-full bg-white shadow-md z-50">
      {/* Main container */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex items-center justify-between relative">
        {/* Mobile Navbar */}
        <div className="md:hidden flex items-center justify-between w-full">
          {/* Left: Menu Button */}
          <button
            onClick={toggleMenu}
            aria-label="Toggle menu"
            className="focus:outline-none text-black"
          >
            {isMenuOpen ? (
              <RxCross1 className="text-2xl text-black" />
            ) : (
              <RxHamburgerMenu className="text-2xl text-black" />
            )}
          </button>

          {/* Center: Logo */}
          <Link to="/" aria-label="Knots & Krafts Home">
            <img src={logo} alt="Knots & Krafts Logo" className="h-8" />
          </Link>

          {/* Right: Bag Icon with Badge */}
          <Link to="/cart" aria-label="Cart" className="relative">
            <IoBagHandleOutline className="text-2xl text-black hover:text-[#FDA7A8] transition-colors duration-300" />
            {cartCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                {cartCount}
              </span>
            )}
          </Link>
        </div>

        {/* Desktop Navbar */}
        <div className="hidden md:flex justify-between items-center w-full">
          {/* Left: Navigation Links */}
          <div className="flex space-x-4 lg:space-x-6">
            <Link
              to="/"
              className="text-base lg:text-lg font-medium text-gray-700 hover:text-[#FDA7A8] transition-colors duration-200"
            >
              Home
            </Link>
            {/* **Shop Link with Dropdown (Optional) */}
            <div className="relative">
              <button
                onClick={toggleShopDropdown}
                className="text-base lg:text-lg font-medium text-gray-700 hover:text-[#FDA7A8] transition-colors duration-200 flex items-center focus:outline-none"
                aria-haspopup="true"
                aria-expanded={isShopDropdownOpen}
              >
                Shop
                {/* Dropdown Indicator */}
                <svg
                  className={`w-4 h-4 ml-1 transform transition-transform ${
                    isShopDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {/* Dropdown Menu */}
              {isShopDropdownOpen && (
                <div className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg z-20">
                  <Link
                    to="/shop?categoryIds=66daedc3c96124ed76c5df06"
                    className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsShopDropdownOpen(false)}
                  >
                    <GiSpaceNeedle className="mr-2" />
                    Accessories / Tools
                  </Link>
                  <Link
                    to="/shop"
                    className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsShopDropdownOpen(false)}
                  >
                    <GiWool className="mr-2" />
                    All Shops
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/about"
              className="text-base lg:text-lg font-medium text-gray-700 hover:text-[#FDA7A8] transition-colors duration-200"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-base lg:text-lg font-medium text-gray-700 hover:text-[#FDA7A8] transition-colors duration-200"
            >
              Contact
            </Link>
            <Link
              to="/faq"
              className="text-base lg:text-lg font-medium text-gray-700 hover:text-[#FDA7A8] transition-colors duration-200"
            >
              FAQ
            </Link>
          </div>

          {/* Center: Logo */}
          <Link to="/" aria-label="Knots & Krafts Home">
            <img src={logo} alt="Knots & Krafts Logo" className="h-10 w-auto" />
          </Link>

          {/* Right: Icons */}
          <div className="flex items-center space-x-4 lg:space-x-6">
            {/* Search Icon */}
            <button
              onClick={toggleSearch}
              aria-label="Search"
              className="focus:outline-none text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300"
            >
              <IoSearchOutline className="text-xl" />
            </button>
            {/* Favorites Icon */}
            <Link to="/fav" aria-label="Favorites">
              <IoHeartOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
            </Link>
            {/* Cart Icon with Badge */}
            <Link to="/cart" aria-label="Cart" className="relative">
              <IoBagHandleOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
              {cartCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                  {cartCount}
                </span>
              )}
            </Link>
            {/* Profile Icon */}
            <Link to="/profile" aria-label="Profile">
              <IoPersonOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Bottom Navbar */}
      <div className="fixed inset-x-0 bottom-0 bg-white border-t md:hidden z-50">
        <div className="flex justify-around items-center py-3">
          <Link to="/" aria-label="Home">
            <IoHomeOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
          </Link>
          <button
            onClick={toggleSearch}
            aria-label="Search"
            className="focus:outline-none"
          >
            <IoSearchOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
          </button>
          <Link to="/fav" aria-label="Favorites">
            <IoHeartOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
          </Link>
          <Link to="/profile" aria-label="Profile">
            <IoPersonOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
          </Link>
          {/* Cart Icon with Badge */}
          {/* <Link to="/cart" aria-label="Cart" className="relative">
            <IoBagHandleOutline className="text-xl text-gray-700 hover:text-[#FDA7A8] transition-colors duration-300" />
            {cartCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                {cartCount}
              </span>
            )}
          </Link> */}
        </div>
      </div>

      {/* Overlay for Menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-40 z-30"
          onClick={toggleMenu}
          aria-hidden="true"
        ></div>
      )}

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-3/4 max-w-xs h-full bg-[#FEF5E8] text-black transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40 shadow-lg`}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-300">
            {/* Logo */}
            <Link to="/" aria-label="Knots & Krafts Home" onClick={toggleMenu}>
              <img src={logo} alt="Knots & Krafts Logo" className="h-10" />
            </Link>
            {/* Close Button */}
            <button
              onClick={toggleMenu}
              aria-label="Close menu"
              className="text-black focus:outline-none hover:text-[#FDA7A8] transition-colors duration-300"
            >
              <RxCross1 className="text-2xl" />
            </button>
          </div>

          {/* Navigation Links */}
          <nav className="flex flex-col px-6 py-6 space-y-4">
            <Link
              to="/"
              className="text-2xl lg:text-3xl text-black font-poppins hover:text-[#FDA7A8] transition-colors duration-200"
              onClick={toggleMenu}
            >
              Home
            </Link>
            {/* **Modified Shop Link with Dropdown** */}
            <div className="w-full">
              <button
                onClick={toggleShopDropdown}
                className="w-full text-left text-2xl lg:text-3xl text-black font-poppins hover:text-[#FDA7A8] transition-colors duration-200 flex items-center justify-between focus:outline-none"
              >
                Shop
                {/* Icon to indicate dropdown */}
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isShopDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {/* Dropdown Items */}
              {isShopDropdownOpen && (
                <div className="mt-2 pl-4">
                  <div
                    className="flex items-center text-xl lg:text-2xl text-black font-poppins py-2 cursor-pointer hover:text-[#FDA7A8] transition-colors duration-200"
                    onClick={() => {
                      toggleMenu();
                      navigate("/shop?categoryIds=66daedc3c96124ed76c5df06");
                    }}
                  >
                    <GiSpaceNeedle className="inline-block text-xl mr-2" />{" "}
                    Accessories / Tools
                  </div>

                  <div
                    className="flex items-center text-xl lg:text-2xl text-black font-poppins cursor-pointer hover:text-[#FDA7A8] transition-colors duration-200"
                    onClick={() => {
                      toggleMenu();
                      navigate("/shop");
                    }}
                  >
                    <GiWool className="inline-block text-xl mr-2" /> Yarns
                  </div>
                </div>
              )}
            </div>
            {/* End of Modified Shop Link */}
            <Link
              to="/about"
              className="text-2xl lg:text-3xl text-black font-poppins hover:text-[#FDA7A8] transition-colors duration-200"
              onClick={toggleMenu}
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-2xl lg:text-3xl text-black font-poppins hover:text-[#FDA7A8] transition-colors duration-200"
              onClick={toggleMenu}
            >
              Contact
            </Link>
            <Link
              to="/faq"
              className="text-2xl lg:text-3xl text-black font-poppins hover:text-[#FDA7A8] transition-colors duration-200"
              onClick={toggleMenu}
            >
              FAQ
            </Link>
          </nav>
        </div>
      </div>

      {/* Overlay for Search */}
      {isSearchOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-40 z-30"
          onClick={toggleSearch}
          aria-hidden="true"
        ></div>
      )}

      {/* Search Popup */}
      <div
        className={`fixed top-0 right-0 w-full sm:w-3/4 max-w-md h-full bg-white transform ${
          isSearchOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-40 shadow-lg`}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-300">
            {/* Title */}
            <h2 className="text-xl sm:text-2xl font-semibold">Search</h2>
            {/* Close Button */}
            <button
              onClick={toggleSearch}
              aria-label="Close search"
              className="text-black focus:outline-none hover:text-[#FDA7A8] transition-colors duration-300"
            >
              <RxCross1 className="text-2xl" />
            </button>
          </div>

          {/* Search Input and Suggestions */}
          <div className="px-6 py-4 relative">
            <form onSubmit={handleSearchSubmit} className="flex flex-col">
              <input
                type="text"
                placeholder="Search products..."
                value={searchQuery}
                onChange={handleSearchInput}
                className="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FDA7A8] transition"
                autoFocus
              />
              <button
                type="submit"
                className="mt-4 w-full bg-[#FDA7A8] text-white py-3 rounded-md hover:bg-pink-600 transition-colors duration-300"
              >
                {isSearching ? "Searching..." : "Search"}
              </button>
            </form>

            {/* Autocomplete Suggestions */}
            {isSearchOpen &&
              searchSuggestions.length > 0 &&
              searchQuery.trim() !== "" && (
                <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md mt-2 max-h-60 overflow-y-auto z-10 shadow-lg">
                  {searchSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
          </div>

          {/* Search Results */}
          <div className="flex-1 px-6 py-4 overflow-y-auto">
            {error && <div className="text-red-500 mb-4">{error}</div>}
            {searchResults.length > 0 ? (
              <ul>
                {searchResults.map((product) => (
                  <li key={product._id} className="mb-4">
                    <Link
                      to={`/shop/${product._id}`}
                      onClick={toggleSearch}
                      className="flex items-center space-x-4 hover:bg-gray-100 p-3 rounded-md transition-colors duration-200"
                    >
                      <img
                        src={`${process.env.REACT_APP_URL}/${product.images[0]?.url}`}
                        alt={getDisplayName(product.name)}
                        className="w-16 h-16 object-cover rounded-md"
                      />
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {getDisplayName(product.name)}
                        </h3>
                        <p className="text-sm text-gray-600">
                          ₹{product.price.regular.toFixed(2)}
                        </p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              searchQuery &&
              !isSearching && (
                <div className="text-gray-500">No products found.</div>
              )
            )}
          </div>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="flex justify-center items-center py-4 space-x-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-4 py-2 rounded-md text-sm font-medium ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-[#FDA7A8] text-white hover:bg-pink-600 transition-colors duration-300"
                }`}
              >
                Previous
              </button>
              <span className="text-sm text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-4 py-2 rounded-md text-sm font-medium ${
                  currentPage === totalPages
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-[#FDA7A8] text-white hover:bg-pink-600 transition-colors duration-300"
                }`}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
