// src/components/BulkOrderCTA.jsx

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom"; // Assuming you're using react-router

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const GotBulkOrder = () => {
  const ctaRef = useRef(null);
  const headingRef = useRef(null);
  const subtextRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Entrance Animation for the entire CTA section
      gsap.from(ctaRef.current, {
        y: 50,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: ctaRef.current,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });

      // Sequential animation for heading, subtext, and button
      gsap.from([headingRef.current, subtextRef.current, buttonRef.current], {
        y: 20,
        duration: 0.8,
        ease: "power3.out",
        stagger: 0.3,
        scrollTrigger: {
          trigger: ctaRef.current,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });

      // Button Hover Animation
      gsap.to(buttonRef.current, {
        scale: 1.05,
        backgroundColor: "#E6CCDB",
        color: "#FFFFFF",
        duration: 0.3,
        paused: true,
        ease: "power3.out",
        onReverseComplete: () =>
          gsap.to(buttonRef.current, {
            scale: 1,
            backgroundColor: "#F7D1FE",
            color: "#F7D1FE",
          }),
      });

      buttonRef.current.addEventListener("mouseenter", () => {
        gsap.to(buttonRef.current, {
          scale: 1.05,
          backgroundColor: "#E6CCDB",
          color: "#FFFFFF",
          duration: 0.3,
          ease: "power3.out",
        });
      });

      buttonRef.current.addEventListener("mouseleave", () => {
        gsap.to(buttonRef.current, {
          scale: 1,
          backgroundColor: "#F7D1FE",
          color: "#F7D1FE",
          duration: 0.3,
          ease: "power3.out",
        });
      });
    }, ctaRef);

    return () => ctx.revert(); // Cleanup GSAP context on unmount
  }, []);

  return (
    <section ref={ctaRef} className="w-full py-4 pb-4 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto text-center">
        {/* Heading */}
        <h2
          ref={headingRef}
          className="text-3xl sm:text-4xl md:text-5xl font-semibold font-sans text-black mb-4"
        >
          Got a Bulk Order?
        </h2>

        {/* Subtext */}
        <p
          ref={subtextRef}
          className="text-md sm:text-xl md:text-2xl text-gray-600 mb-8"
        >
          Contact us now to discuss your requirements and get a personalized
          quote.
        </p>

        {/* Call-to-Action Button */}
        <Link to="/contact" ref={buttonRef}>
          <div className="max-w-32 mx-auto bg-transparent items-center bg-[#df9bf1] justify-center flex border-2 border-[#b791c2] shadow-lg hover:bg-[#F7D1FE] text-black hover:text-black duration-300 cursor-pointer active:scale-[0.98]">
            <button className="px-5 py-2 font-bold">Contact Us</button>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default GotBulkOrder;
