import axios from "axios";
import { getCouponById } from "../api";

// utils/cartUtils.js
export const addToCart = (product) => {
  let cart = JSON.parse(localStorage.getItem("inCart")) || [];
  const existingProductIndex = cart.findIndex(
    (item) => item._id === product._id
  );

  if (existingProductIndex >= 0) {
    cart[existingProductIndex].quantity += 1; // Increment quantity if product already in cart
  } else {
    cart.push({ ...product, quantity: 1 }); // Add new product with quantity 1
  }

  localStorage.setItem("inCart", JSON.stringify(cart));
};

export const removeFromCart = (productId) => {
  let cart = JSON.parse(localStorage.getItem("inCart")) || [];
  cart = cart.filter((item) => item._id !== productId);

  localStorage.setItem("inCart", JSON.stringify(cart));
};

export const updateCartQuantity = (productId, quantity) => {
  let cart = JSON.parse(localStorage.getItem("inCart")) || [];
  cart = cart.map((item) => {
    if (item._id === productId) {
      return { ...item, quantity };
    }
    return item;
  });

  localStorage.setItem("inCart", JSON.stringify(cart));
};

export const getCartItems = () => {
  return JSON.parse(localStorage.getItem("inCart")) || [];
};

export const getTotalPrice = () => {
  const cart = getCartItems();
  return cart.reduce((total, item) => total + item.price * item.quantity, 0);
};

export const clearCart = () => {
  localStorage.removeItem("inCart");
};

export const checkCouponCode = async (code) => {
  try {
    console.log(code);
    const response = await getCouponById(code);
    console.log(response);
    return response.data; // Returns coupon data if it exists
  } catch (error) {
    console.error("Coupon code invalid or not available", error);
    return null;
  }
};
