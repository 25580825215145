import React from "react";
import { Helmet } from "react-helmet";
import ContactSection from "../../components/final_components/ContactSection";
import MapComponent from "../../components/final_components/MapComponent";

const Contact = () => {
  return (
    <div>
      {/* SEO Optimization with React Helmet */}
      <Helmet>
        <title>Contact Us - Knots and Krafts</title>
        <meta
          name="description"
          content="Get in touch with Knots and Krafts for any inquiries, support, or feedback. We'd love to hear from you!"
        />
        <meta
          name="keywords"
          content="Knots and Krafts, Contact, Yarn Supplies, Customer Support"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Header Section */}
      <header className="relative bg-cover bg-center h-64 sm:h-80 md:h-96 flex flex-col justify-center items-center text-center">
        {/* Background Image */}
        <img
          src="./shop_hero.jpg"
          alt="Contact Knots and Krafts"
          className="absolute inset-0 w-full h-full object-cover"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        {/* Content */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">
            Contact Us
          </h1>
          <nav className="mt-4 text-sm sm:text-base">
            <a href="/" className="hover:underline">
              Home
            </a>{" "}
            &gt; <span>Contact</span>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main>
        {/* Contact Section */}
        <section className="py-12 px-6 sm:px-8">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 text-center">
              We'd Love to Hear From You
            </h2>
            <p className="mt-4 text-lg text-gray-700 text-center max-w-2xl mx-auto">
              Whether you have a question about our products, pricing, or
              anything else, our team is ready to answer all your questions.
            </p>
            <ContactSection />
          </div>
        </section>

        {/* Map Section */}
        <section className="bg-gray-100 py-12 px-6 sm:px-8">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 text-center">
              Our Location
            </h2>
            <p className="mt-4 text-lg text-gray-700 text-center max-w-2xl mx-auto">
              Visit us at our store or reach out to us through the map below.
            </p>
            <div className="mt-8">
              <MapComponent />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contact;
