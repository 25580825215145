import React from "react";

const Popup = ({ message, onClose }) => {
  if (!message) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10">
      <div className="bg-transparent p-4 rounded-md shadow-lg">
        <h4 className="text-lg font-semibold mb-2">Error</h4>
        <p className="mb-4">{message}</p>
        <button
          onClick={onClose}
          className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
