import React, { useState } from "react";
import ProductManagement from "../../components/final_components/ProductManagement";
import CategoryManagement from "../../components/final_components/CategoryManagement ";
import AdminOrders from "../../components/final_components/AdminOrders";
import UserManagement from "../../components/final_components/UserManagement";
import TagManagement from "../../components/final_components/TagsManagement";
const AdminDashboard = () => {
  const [selectedTab, setSelectedTab] = useState("tags");

  const renderContent = () => {
    switch (selectedTab) {
      case "users":
        return <UserManagement />;
      case "categories":
        return <CategoryManagement />;
      case "products":
        return <ProductManagement />;
      case "tags":
        return <TagManagement />;
      case "orders":
        return <AdminOrders />;
      default:
        return <UserManagement />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-20">
      <h2 className="text-3xl font-bold mb-6">Admin Dashboard</h2>

      <div className="flex gap-4 mb-10">
        <div>
          <input
            className="peer sr-only"
            value="users"
            name="adminTabs"
            id="users"
            type="radio"
            checked={selectedTab === "users"}
            onChange={() => setSelectedTab("users")}
          />
          <div className="flex h-16 w-28 cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-50 p-1 transition-transform duration-150 hover:border-purple-400 active:scale-95 peer-checked:border-purple-500 peer-checked:shadow-md peer-checked:shadow-purple-400">
            <label
              className="flex cursor-pointer items-center justify-center text-sm uppercase text-gray-500 peer-checked:text-purple-500"
              htmlFor="users"
            >
              Users
            </label>
          </div>
        </div>

        <div>
          <input
            className="peer sr-only"
            value="categories"
            name="adminTabs"
            id="categories"
            type="radio"
            checked={selectedTab === "categories"}
            onChange={() => setSelectedTab("categories")}
          />
          <div className="flex h-16 w-28 cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-50 p-1 transition-transform duration-150 hover:border-purple-400 active:scale-95 peer-checked:border-purple-500 peer-checked:shadow-md peer-checked:shadow-purple-400">
            <label
              className="flex cursor-pointer items-center justify-center text-sm uppercase text-gray-500 peer-checked:text-purple-500"
              htmlFor="categories"
            >
              Categories
            </label>
          </div>
        </div>

        <div>
          <input
            className="peer sr-only"
            value="products"
            name="adminTabs"
            id="products"
            type="radio"
            checked={selectedTab === "products"}
            onChange={() => setSelectedTab("products")}
          />
          <div className="flex h-16 w-28 cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-50 p-1 transition-transform duration-150 hover:border-purple-400 active:scale-95 peer-checked:border-purple-500 peer-checked:shadow-md peer-checked:shadow-purple-400">
            <label
              className="flex cursor-pointer items-center justify-center text-sm uppercase text-gray-500 peer-checked:text-purple-500"
              htmlFor="products"
            >
              Products
            </label>
          </div>
        </div>

        <div>
          <input
            className="peer sr-only"
            value="tags"
            name="adminTabs"
            id="tags"
            type="radio"
            checked={selectedTab === "tags"}
            onChange={() => setSelectedTab("tags")}
          />
          <div className="flex h-16 w-28 cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-50 p-1 transition-transform duration-150 hover:border-purple-400 active:scale-95 peer-checked:border-purple-500 peer-checked:shadow-md peer-checked:shadow-purple-400">
            <label
              className="flex cursor-pointer items-center justify-center text-sm uppercase text-gray-500 peer-checked:text-purple-500"
              htmlFor="tags"
            >
              Tags
            </label>
          </div>
        </div>

        <div>
          <input
            className="peer sr-only"
            value="orders"
            name="adminTabs"
            id="orders"
            type="radio"
            checked={selectedTab === "orders"}
            onChange={() => setSelectedTab("orders")}
          />
          <div className="flex h-16 w-28 cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-gray-300 bg-gray-50 p-1 transition-transform duration-150 hover:border-purple-400 active:scale-95 peer-checked:border-purple-500 peer-checked:shadow-md peer-checked:shadow-purple-400">
            <label
              className="flex cursor-pointer items-center justify-center text-sm uppercase text-gray-500 peer-checked:text-purple-500"
              htmlFor="orders"
            >
              Orders
            </label>
          </div>
        </div>
      </div>

      <div>{renderContent()}</div>
    </div>
  );
};

export default AdminDashboard;
