// src/components/CheckoutForm.jsx

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getCartItems } from "../../utils/cartUtils";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { checkGuestExists, createOrder } from "../../api";
import Swal from "sweetalert2"; // Import SweetAlert2
import withReactContent from "sweetalert2-react-content"; // Import React integration
import { IoIosTimer } from "react-icons/io";

const CheckoutForm = () => {
  const { user, setUser } = useContext(AuthContext);

  // Initialize SweetAlert2 with React Content
  const MySwal = withReactContent(Swal);

  // Function to split the user's full name into first and last name
  const getFirstAndLastName = (fullName) => {
    if (!fullName) return { firstName: "", lastName: "" };
    const nameParts = fullName.trim().split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
    return { firstName, lastName };
  };

  // Extract firstName and lastName from user data
  const { firstName: userFirstName, lastName: userLastName } = user
    ? getFirstAndLastName(user.username) // Ensure 'user.username' contains the full name
    : { firstName: "", lastName: "" };

  const [cartItems, setCartItems] = useState([]);
  const [itemsTotal, setItemsTotal] = useState(0); // Subtotal before discount
  const [discount, setDiscount] = useState(0); // Discount from coupons
  const [totalPrice, setTotalPrice] = useState(0); // Subtotal after discount
  const [shippingDetails, setShippingDetails] = useState({
    firstName: userFirstName,
    lastName: userLastName,
    companyName: "",
    country: "India",
    streetAddress: "",
    city: "",
    province: "Karnataka",
    postalCode: "",
    phone: "",
    email: user ? user.email : "",
    additionalInfo: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const location = useLocation(); // To access the passed state

  // New State Variables for Calculations
  const [taxAmount, setTaxAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [finalTotalPrice, setFinalTotalPrice] = useState(0);

  // State for Payment Method
  const [paymentMethod, setPaymentMethod] = useState("Razorpay");

  // Update shippingDetails when user data changes
  useEffect(() => {
    if (user) {
      const { firstName, lastName } = getFirstAndLastName(user.username);
      setShippingDetails((prevDetails) => ({
        ...prevDetails,
        firstName,
        lastName,
        email: user.email,
      }));
    }
  }, [user]);

  // Fetch cart items and set initial totals
  useEffect(() => {
    const items = getCartItems();
    setCartItems(items);

    // Calculate Subtotal (itemsTotal)
    const calculatedItemsTotal = items.reduce(
      (sum, item) =>
        sum +
        (parseFloat(item.price.sale) || parseFloat(item.price.regular) || 0) *
          item.quantity,
      0
    );
    setItemsTotal(calculatedItemsTotal);

    // Get the discounted price from the state passed via navigation
    const discountedPrice = location.state?.finalPrice || calculatedItemsTotal;
    setDiscount(calculatedItemsTotal - discountedPrice); // Assuming finalPrice is after discount
    setTotalPrice(discountedPrice);
  }, [location.state]);

  // Calculate Tax and Delivery Charges whenever shippingDetails or totalPrice changes
  useEffect(() => {
    // Calculate total weight in grams
    const totalWeightInKg = cartItems.reduce(
      (sum, item) => sum + (parseFloat(item.weight) || 0) * item.quantity,
      0
    );
    const totalWeightInGrams = totalWeightInKg * 1000;

    // Determine if the shipping is within Karnataka
    const isWithinKarnataka =
      shippingDetails.province.trim().toLowerCase() === "karnataka";

    // Calculate Tax
    let calculatedTax = 0;
    if (isWithinKarnataka) {
      // 2.5% CGST + 2.5% SGST
      calculatedTax = totalPrice * 0.025 + totalPrice * 0.025;
    } else {
      // 5% IGST
      calculatedTax = totalPrice * 0.05;
    }
    setTaxAmount(calculatedTax);

    // Calculate Delivery Charges
    let calculatedDelivery = 0;
    if (totalPrice >= 2500) {
      calculatedDelivery = 0;
    } else {
      if (totalWeightInGrams <= 500) {
        calculatedDelivery = 60;
      } else if (totalWeightInGrams <= 1000) {
        calculatedDelivery = 80;
      } else {
        calculatedDelivery = 120;
      }
    }
    setDeliveryCharge(calculatedDelivery);

    // Calculate Final Total Price
    const finalTotal =
      parseFloat(totalPrice) +
      parseFloat(calculatedTax) +
      parseFloat(calculatedDelivery);
    setFinalTotalPrice(finalTotal);
  }, [shippingDetails, totalPrice, cartItems]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      // Remove any non-digit characters
      const phoneNumber = value.replace(/\D/g, "");

      // Update the phone number in state
      setShippingDetails((prevDetails) => ({
        ...prevDetails,
        phone: phoneNumber,
      }));
    } else {
      // Handle other input fields if any
      setShippingDetails({ ...shippingDetails, [name]: value });
    }
  };

  const handleSubmit = async () => {
    // Prevent multiple submissions
    if (isLoading) return;

    setIsLoading(true); // Start loading

    try {
      // Validate Phone Number
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(shippingDetails.phone)) {
        await MySwal.fire({
          icon: "error",
          title: "Invalid Phone Number",
          text: "Please enter a valid 10-digit phone number.",
        });
        setIsLoading(false);
        return;
      }

      // Check for empty cart
      if (cartItems.length === 0) {
        // Replace alert with SweetAlert2
        await MySwal.fire({
          icon: "warning",
          title: "Empty Cart",
          text: "Your cart is empty.",
        });
        setIsLoading(false);
        return;
      }

      // Handle guest user registration logic is now managed by backend

      if (paymentMethod === "Cash On Delivery") {
        // Handle order without immediate payment
        try {
          const orderData = {
            // Remove the 'user' field
            orderItems: cartItems.map((item) => ({
              name: item.name,
              quantity: item.quantity || 1, // Ensure quantity is not null
              image: item.images[0]?.url || "",
              price: item.price.sale || item.price.regular || 0,
              product: item._id,
            })),
            shippingAddress: {
              address: shippingDetails.streetAddress,
              city: shippingDetails.city,
              postalCode: shippingDetails.postalCode,
              country: shippingDetails.country,
            },
            customerDetails: {
              // Ensure customerDetails are included
              firstName: shippingDetails.firstName,
              lastName: shippingDetails.lastName,
              email: shippingDetails.email,
              phone: shippingDetails.phone,
            },
            paymentMethod: paymentMethod,
            itemsPrice: totalPrice,
            taxPrice: taxAmount,
            shippingPrice: deliveryCharge,
            totalPrice: finalTotalPrice,
            paymentInfo: {}, // No payment info since payment is not done yet
          };

          // Send order data to the backend
          const { data } = await createOrder(orderData);

          // Update AuthContext if user data is returned
          if (data.user) {
            setUser(data.user);
          }

          localStorage.removeItem("cartItems");
          setIsLoading(false); // Stop loading
          navigate(`/orders/${data._id}`);
        } catch (error) {
          console.error(
            "Error processing order:",
            error.response?.data?.message || error.message
          );
          // Replace alert with SweetAlert2
          await MySwal.fire({
            icon: "error",
            title: "Order Error",
            text: "There was an error processing your order. Please try again.",
          });
          setIsLoading(false); // Stop loading on error
        }
      } else {
        // Handle Razorpay payment

        // Load Razorpay script
        const loadRazorpayScript = () => {
          return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
          });
        };

        const scriptLoaded = await loadRazorpayScript();
        if (!scriptLoaded) {
          // Replace alert with SweetAlert2
          await MySwal.fire({
            icon: "error",
            title: "Payment Error",
            text: "Razorpay SDK failed to load. Are you online?",
          });
          setIsLoading(false);
          return;
        }

        const orderData = {
          amount: Math.round(finalTotalPrice * 100), // amount in paise
          currency: "INR",
        };

        let order;
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/razorpay/order`,
            orderData,
            { withCredentials: true }
          );
          order = response.data;
        } catch (error) {
          console.error(
            "Error creating Razorpay order:",
            error.response?.data?.message || error.message
          );
          // Replace alert with SweetAlert2
          await MySwal.fire({
            icon: "error",
            title: "Payment Error",
            text: "There was an error initiating payment. Please try again.",
          });
          setIsLoading(false);
          return;
        }

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: order.amount,
          currency: order.currency,
          name: "Knots & Krafts",
          description: "Order Payment",
          order_id: order.id,
          handler: async (response) => {
            // Using arrow function to retain 'this' context
            try {
              const paymentData = {
                // Remove the 'user' field
                orderItems: cartItems.map((item) => ({
                  name: item.name,
                  quantity: item.quantity || 1, // Ensure quantity is not null
                  image: item.images[0]?.url || "",
                  price: item.price.sale || item.price.regular || 0,
                  product: item._id,
                })),
                shippingAddress: {
                  address: shippingDetails.streetAddress,
                  city: shippingDetails.city,
                  postalCode: shippingDetails.postalCode,
                  country: shippingDetails.country,
                },
                customerDetails: {
                  firstName: shippingDetails.firstName,
                  lastName: shippingDetails.lastName,
                  email: shippingDetails.email,
                  phone: shippingDetails.phone,
                },
                paymentMethod: "Razorpay",
                itemsPrice: totalPrice,
                taxPrice: taxAmount,
                shippingPrice: deliveryCharge,
                totalPrice: finalTotalPrice,
                paymentInfo: {
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                },
              };

              // Send order data to the backend
              const { data } = await createOrder(paymentData);

              // Update AuthContext if user data is returned
              if (data.user) {
                setUser(data.user);
              }

              localStorage.removeItem("cartItems");
              setIsLoading(false); // Stop loading
              navigate(`/orders/${data._id}`);
            } catch (error) {
              console.error(
                "Error processing order:",
                error.response?.data?.message || error.message
              );
              // Replace alert with SweetAlert2
              await MySwal.fire({
                icon: "error",
                title: "Order Error",
                text: "There was an error processing your order. Please try again.",
              });
              setIsLoading(false); // Stop loading on error
            }
          },
          prefill: {
            name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
            email: shippingDetails.email,
            contact: shippingDetails.phone,
          },
          notes: {
            address: shippingDetails.streetAddress,
          },
          theme: {
            color: "#FBBF24",
          },
          modal: {
            ondismiss: () => {
              // Handle modal dismissal
              setIsLoading(false);
              MySwal.fire({
                icon: "info",
                title: "Payment Cancelled",
                text: "You have cancelled the payment process.",
              });
            },
          },
        };

        const paymentObject = new window.Razorpay(options);

        // Optional: Handle modal events (e.g., opened, closed)
        paymentObject.on("payment.failed", (response) => {
          console.error("Payment Failed:", response.error);
          MySwal.fire({
            icon: "error",
            title: "Payment Failed",
            text:
              response.error.description ||
              "There was an error processing your payment.",
          });
          setIsLoading(false);
        });

        paymentObject.open();
      }
    } catch (error) {
      console.error(
        "Error processing payment:",
        error.response?.data?.message || error.message
      );
      // Replace alert with SweetAlert2
      await MySwal.fire({
        icon: "error",
        title: "Payment Error",
        text: "There was an error processing your payment. Please try again.",
      });
      setIsLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      {/* Helmet for SEO optimization */}
      <Helmet>
        <title>Checkout - Knots & Krafts</title>
        <meta
          name="description"
          content="Complete your purchase of handcrafted products from Knots & Krafts. Secure checkout with multiple payment options."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Processing Modal Overlay */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-xl p-8 w-11/12 md:w-2/3 lg:w-1/2 max-h-screen overflow-auto flex flex-col items-center justify-center space-y-6 animate-fade-in">
            {/* Illustrative Image */}
            <IoIosTimer size={100} />

            {/* Title */}
            <h2 className="text-2xl md:text-3xl font-extrabold text-gray-900">
              Processing Your Order
            </h2>

            {/* Description */}
            <p className="text-base md:text-lg text-gray-700 text-center px-4">
              Please do not change the page while your order is being processed.
              This might take a few moments.
            </p>

            {/* Animated Spinner */}
            <div className="flex items-center justify-center">
              <div className="w-12 h-12 border-4 border-yellow-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>

            {/* Optional: Progress Bar */}
            {/* <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-yellow-500 h-2.5 rounded-full animate-pulse"
                style={{ width: "70%" }}
              ></div>
            </div> */}
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Billing Details Form */}
        <div>
          <h1 className="text-xl sm:text-2xl font-extrabold text-gray-900 mb-6">
            Billing Details
          </h1>
          <form className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* First Name */}
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={shippingDetails.firstName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                  required
                  readOnly={!!user}
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={shippingDetails.lastName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                  required
                  readOnly={!!user}
                />
              </div>
            </div>

            {/* Company Name */}
            <div>
              <label
                htmlFor="companyName"
                className="block text-sm font-medium text-gray-700"
              >
                Company Name (Optional)
              </label>
              <input
                id="companyName"
                type="text"
                name="companyName"
                value={shippingDetails.companyName}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
              />
            </div>

            {/* Email Address */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={shippingDetails.email}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                required
                readOnly={!!user}
              />
            </div>

            {/* Country / Region */}
            <div>
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country / Region
              </label>
              <select
                id="country"
                name="country"
                value={shippingDetails.country}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
              >
                <option>India</option>
                {/* Add other countries if needed */}
              </select>
            </div>

            {/* Street Address */}
            <div>
              <label
                htmlFor="streetAddress"
                className="block text-sm font-medium text-gray-700"
              >
                Street Address
              </label>
              <input
                id="streetAddress"
                type="text"
                name="streetAddress"
                value={shippingDetails.streetAddress}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                required
              />
            </div>

            {/* Town / City */}
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                Town / City
              </label>
              <input
                id="city"
                type="text"
                name="city"
                value={shippingDetails.city}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                required
              />
            </div>

            {/* Province */}
            <div>
              <label
                htmlFor="province"
                className="block text-sm font-medium text-gray-700"
              >
                Province
              </label>
              <select
                id="province"
                name="province"
                value={shippingDetails.province}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
              >
                {/* List of provinces */}
                <option>Andhra Pradesh</option>
                <option>Arunachal Pradesh</option>
                <option>Assam</option>
                <option>Bihar</option>
                <option>Chhattisgarh</option>
                <option>Goa</option>
                <option>Gujarat</option>
                <option>Haryana</option>
                <option>Himachal Pradesh</option>
                <option>Jharkhand</option>
                <option>Karnataka</option>
                <option>Kerala</option>
                <option>Madhya Pradesh</option>
                <option>Maharashtra</option>
                <option>Manipur</option>
                <option>Meghalaya</option>
                <option>Mizoram</option>
                <option>Nagaland</option>
                <option>Odisha</option>
                <option>Punjab</option>
                <option>Rajasthan</option>
                <option>Sikkim</option>
                <option>Tamil Nadu</option>
                <option>Telangana</option>
                <option>Tripura</option>
                <option>Uttar Pradesh</option>
                <option>Uttarakhand</option>
                <option>West Bengal</option>
                <option>Andaman and Nicobar Islands</option>
                <option>Chandigarh</option>
                <option>Dadra and Nagar Haveli and Daman and Diu</option>
                <option>Delhi</option>
                <option>Lakshadweep</option>
                <option>Puducherry</option>
                {/* Add other provinces if needed */}
              </select>
            </div>

            {/* ZIP Code */}
            <div>
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                ZIP Code
              </label>
              <input
                id="postalCode"
                type="text"
                name="postalCode"
                value={shippingDetails.postalCode}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                required
              />
            </div>

            {/* Phone */}
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                id="phone"
                type="tel"
                name="phone"
                maxLength={10}
                value={shippingDetails.phone}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                required
              />
            </div>

            {/* Additional Information */}
            <div>
              <label
                htmlFor="additionalInfo"
                className="block text-sm font-medium text-gray-700"
              >
                Additional Information
              </label>
              <textarea
                id="additionalInfo"
                name="additionalInfo"
                value={shippingDetails.additionalInfo}
                onChange={handleInputChange}
                rows="3"
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
              ></textarea>
            </div>
          </form>
        </div>

        {/* Order Summary and Payment Details */}
        <div>
          <h2 className="text-xl sm:text-2xl font-extrabold text-gray-900 mb-6">
            Your Order
          </h2>
          <div className="space-y-4">
            {/* Cart Items */}
            {cartItems.map((item) => (
              <div
                className="flex justify-between text-gray-700"
                key={item._id}
              >
                <span className="text-sm sm:text-base">
                  {item.name} × {item.quantity}
                </span>
                <span className="text-sm sm:text-base">
                  Rs.{" "}
                  {(
                    (item.price.sale || item.price.regular || 0) * item.quantity
                  ).toFixed(2)}
                </span>
              </div>
            ))}

            {/* Subtotal */}
            <div className="flex justify-between text-gray-700 mt-4">
              <span className="font-medium">Subtotal</span>
              <span className="font-medium">Rs. {itemsTotal.toFixed(2)}</span>
            </div>

            {/* Discount */}
            {discount > 0 && (
              <div className="flex justify-between text-gray-700">
                <span className="font-medium">Discount</span>
                <span className="font-medium">- Rs. {discount.toFixed(2)}</span>
              </div>
            )}

            {/* Tax */}
            <div className="flex justify-between text-gray-700">
              <span className="font-medium">
                {shippingDetails.province.trim().toLowerCase() === "karnataka"
                  ? "CGST (2.5%) + SGST (2.5%)"
                  : "IGST (5%)"}
              </span>
              <span className="font-medium">Rs. {taxAmount.toFixed(2)}</span>
            </div>

            {/* Delivery Charges */}
            <div className="flex justify-between text-gray-700">
              <span className="font-medium">Delivery Charges</span>
              <span className="font-medium">
                Rs. {deliveryCharge.toFixed(2)}
              </span>
            </div>

            {/* Final Total */}
            <div className="flex justify-between text-xl font-bold text-gray-900">
              <span>Total</span>
              <span>Rs. {finalTotalPrice.toFixed(2)}</span>
            </div>

            {/* Payment Method */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-900">
                Payment Method
              </h3>
              <div className="space-y-2 mt-4">
                {/* Razorpay */}
                <div className="flex items-center">
                  <input
                    id="razorpay"
                    name="payment-method"
                    type="radio"
                    value="Razorpay"
                    checked={paymentMethod === "Razorpay"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="h-4 w-4 text-yellow-500 border-gray-300 focus:ring-yellow-500"
                  />
                  <label
                    htmlFor="razorpay"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Online Payment (Razorpay)
                  </label>
                </div>

                {/* Cash On Delivery */}
                {/* <div className="flex items-center">
                  <input
                    id="cash-on-delivery"
                    name="payment-method"
                    type="radio"
                    value="Cash On Delivery"
                    checked={paymentMethod === "Cash On Delivery"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="h-4 w-4 text-yellow-500 border-gray-300 focus:ring-yellow-500"
                  />
                  <label
                    htmlFor="cash-on-delivery"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Cash On Delivery
                  </label>
                </div> */}
              </div>
            </div>

            {/* Privacy Policy */}
            <div className="mt-6">
              <p className="text-sm text-gray-600">
                Your personal data will be used to support your experience
                throughout this website, to manage access to your account, and
                for other purposes described in our{" "}
                <a href="#" className="text-yellow-500 hover:underline">
                  privacy policy
                </a>
                .
              </p>
            </div>

            {/* Place Order Button */}
            <div className="mt-6">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={isLoading}
                className={`w-full bg-pink text-white px-4 py-3 rounded-md shadow-sm hover:bg-pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? "Processing..." : "Place Order"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
