import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  fetchAllProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  uploadImage,
  getAllCategories,
} from "../../api";
import Popup from "../Popup";
import LoadingSpinner from "./LoadingSpinner";
// import ConfirmationModal from "../ConfirmationModal"; // Hypothetical component

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productData, setProductData] = useState({
    name: "",
    price: "",
    description: "",
    categories: [],
    quantity: "",
    brand: "",
    images: [],
    type: "", // Adding type field
  });

  const [editingProductId, setEditingProductId] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageColors, setImageColors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    const fetchProductsAndCategories = async () => {
      try {
        setIsLoading(true);
        const productResponse = await fetchAllProducts();
        setProducts(productResponse.data);

        const categoryResponse = await getAllCategories();
        setCategories(categoryResponse.data);
      } catch (error) {
        setErrorMessage("Failed to fetch products or categories");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProductsAndCategories();
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleCategoryChange = useCallback((e) => {
    const { value, checked } = e.target;
    setProductData((prevData) => {
      const updatedCategories = checked
        ? [...prevData.categories, { categoryId: value }]
        : prevData.categories.filter((cat) => cat.categoryId !== value);
      return { ...prevData, categories: updatedCategories };
    });
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);

    const filePreviews = files.map((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews((prevPreviews) => [...prevPreviews, reader.result]);
      };
      reader.readAsDataURL(file);
      return reader.result;
    });
    setImagePreviews(filePreviews);
  };

  const handleColorChange = useCallback(
    (e, index) => {
      const newColors = [...imageColors];
      newColors[index] = e.target.value;
      setImageColors(newColors);
    },
    [imageColors]
  );

  const handleUploadImages = async () => {
    if (imageFiles.length === 0) return;

    const formData = new FormData();
    formData.append("name", productData.name);
    imageFiles.forEach((file) => formData.append("images", file));
    imageColors.forEach((color) => formData.append("colors", color));

    try {
      const { data } = await uploadImage(formData);
      setProductData((prevData) => ({
        ...prevData,
        images: JSON.stringify(data.images),
      }));
      setImageFiles([]);
      setImagePreviews([]);
      setImageColors([]);
    } catch (error) {
      setErrorMessage("Failed to upload images");
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedProductData = {
        ...productData,
        price: Number(productData.price),
        quantity: Number(productData.quantity),
        categories: JSON.stringify(productData.categories),
      };

      const formData = new FormData();
      Object.keys(formattedProductData).forEach((key) => {
        formData.append(key, formattedProductData[key]);
      });

      if (editingProductId) {
        await updateProduct(editingProductId, formData);
      } else {
        await addProduct(formData);
      }

      setProductData({
        name: "",
        price: "",
        description: "",
        categories: [],
        quantity: "",
        brand: "",
        images: [],
        type: "", // Resetting type field after submission
      });
      setEditingProductId(null);
      const { data } = await fetchAllProducts();
      setProducts(data);
    } catch (error) {
      setErrorMessage("Failed to submit product");
      console.error(error);
    }
  };

  const handleEdit = (product) => {
    setProductData({
      name: product.name,
      price: product.price,
      description: product.description,
      categories: product.categories.map((cat) => ({ categoryId: cat._id })),
      quantity: product.quantity,
      brand: product.brand,
      images: product.images,
      type: product.type, // Populate type field when editing
    });
    setEditingProductId(product._id);
    setImagePreviews(
      product.images.map((img) => `http://localhost:5000/${img.url}`)
    );
    setImageColors(product.images.map((img) => img.color));
  };

  const handleDelete = (id) => {
    setIsModalOpen(true);
    setProductToDelete(id);
  };

  const confirmDelete = async () => {
    try {
      await deleteProduct(productToDelete);
      setProducts(
        products.filter((product) => product._id !== productToDelete)
      );
      setIsModalOpen(false);
      setProductToDelete(null);
    } catch (error) {
      setErrorMessage("Failed to delete product");
      console.error(error);
    }
  };

  const handleClosePopup = () => {
    setErrorMessage(null);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h3 className="text-3xl font-extrabold text-gray-900 mb-8">
        Product Management
      </h3>
      <form onSubmit={handleSubmit} className="mb-6 space-y-4">
        <input
          type="text"
          name="name"
          placeholder="Product Name"
          value={productData.name}
          onChange={handleInputChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />
        <input
          type="number"
          name="price"
          placeholder="Price"
          value={productData.price}
          onChange={handleInputChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />
        <input
          type="text"
          name="type"
          placeholder="Product Type"
          value={productData.type}
          onChange={handleInputChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />
        <textarea
          name="description"
          placeholder="Description"
          value={productData.description}
          onChange={handleInputChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />
        <div className="w-full p-3 bg-gray-700 text-white rounded-md">
          <label>Select Categories:</label>
          {categories.map((category) => (
            <div key={category._id} className="flex items-center">
              <input
                type="checkbox"
                value={category._id}
                checked={productData.categories.some(
                  (cat) => cat.categoryId === category._id
                )}
                onChange={handleCategoryChange}
                className="mr-2"
              />
              <label>{category.name}</label>
            </div>
          ))}
        </div>
        <input
          type="number"
          name="quantity"
          placeholder="Quantity"
          value={productData.quantity}
          onChange={handleInputChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />
        <input
          type="text"
          name="brand"
          placeholder="Brand"
          value={productData.brand}
          onChange={handleInputChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />
        <input
          type="file"
          name="images"
          multiple
          onChange={handleImageChange}
          className="w-full p-3 bg-gray-700 text-white rounded-md"
        />

        {imagePreviews.length > 0 && (
          <div className="mb-4 grid grid-cols-3 gap-4">
            {imagePreviews.map((preview, index) => (
              <div key={index}>
                <img
                  src={preview}
                  alt={`Product Preview ${index + 1}`}
                  className="w-full h-auto rounded-md"
                />
                <input
                  type="text"
                  placeholder="Enter color for this image"
                  value={imageColors[index] || ""}
                  onChange={(e) => handleColorChange(e, index)}
                  className="mt-2 p-2 w-full bg-gray-600 text-white rounded-md"
                />
              </div>
            ))}
          </div>
        )}

        <button
          type="button"
          onClick={handleUploadImages}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 rounded-md"
        >
          Upload Images
        </button>
        <button
          type="submit"
          className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-3 rounded-md"
        >
          {editingProductId ? "Update Product" : "Add Product"}
        </button>
      </form>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product) => (
          <div
            key={product._id}
            className="bg-white p-6 rounded-lg shadow-lg border border-gray-200"
          >
            <img
              src={`http://localhost:5000/${product.images[0]?.url}`}
              alt={product.name}
              className="w-full h-48 object-cover rounded-md mb-4"
            />
            <div className="mb-4">
              <h4 className="text-xl font-semibold text-gray-900">
                {product.name}
              </h4>
              <p className="text-gray-700">Price: Rs. {product.price}</p>
              <p className="text-gray-500 mt-2">
                {product.description.length > 100
                  ? product.description.substring(0, 100) + "..."
                  : product.description}
              </p>
              <p className="text-gray-500 mt-2">Quantity: {product.quantity}</p>
              <p className="text-gray-500 mt-2">Brand: {product.brand}</p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(product)}
                className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-3 rounded-full transition duration-300 ease-in-out"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(product._id)}
                className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-full transition duration-300 ease-in-out"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      <Popup message={errorMessage} onClose={handleClosePopup} />
      {/* <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={confirmDelete}
        onCancel={() => setIsModalOpen(false)}
      /> */}
    </div>
  );
};

export default ProductManagement;
