import React from "react";
import { AiOutlineProduct } from "react-icons/ai";
import logo from "../../Knots&KraftsLogo.png";
// import { BiSupport } from "react-icons/bi";
import {
  FaFacebook,
  FaInstagram,
  FaMailBulk,
  FaShieldAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { GiIndiaGate } from "react-icons/gi";
import { MdOutlineLocalShipping } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="bg-gray-900 z-100 text-white">
      {/* <div className="bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        
          <div className="flex flex-nowrap justify-center lg:justify-start gap-1 md:gap-4 sm:gap-1 overflow-x-auto">
    
            <div className="flex flex-col items-center lg:items-start text-center lg:text-left flex-1 min-w-[100px]">
              <AiOutlineProduct className="h-8 w-8 text-violet-500" />
              <div className="mt-3">
                <h4 className="text-base sm:text-lg font-semibold text-gray-900">
                  High Quality
                </h4>
                <p className="text-xs sm:text-sm text-gray-600">
                  Crafted from top materials
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center lg:items-start text-center lg:text-left flex-1 min-w-[100px]">
              <MdOutlineLocalShipping className="h-8 w-8 text-violet-500" />
              <div className="mt-3">
                <h4 className="text-base sm:text-lg font-semibold text-gray-900">
                  Free Shipping
                </h4>
                <p className="text-xs sm:text-sm text-gray-600">
                  Order over ₹2500
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center lg:items-start text-center lg:text-left flex-1 min-w-[100px]">
              <GiIndiaGate className="h-8 w-8 text-violet-500" />
              <div className="mt-3">
                <h4 className="text-base sm:text-lg font-semibold text-gray-900">
                  PAN India Shipping
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Footer Main Section */}
      <div className="bg-gray-900 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Company Info */}
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
            <img src={logo} alt="Knots & Krafts Logo" className="h-12 mb-4" />
            <address className="not-italic text-gray-400 space-y-1">
              <strong>Knots & Krafts</strong>
              <br />
              302, JSJ Opal Apartments,
              <br />
              SR Krishnappa Garden, Jayanagar,
              <br />
              Bengaluru, Karnataka 560041
            </address>
          </div>

          {/* Social Media Links */}
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
            <h5 className="text-gray-400 font-semibold mb-4">Follow Us</h5>
            <div className="flex space-x-4">
              {/* Social buttons with hover effects */}
              <a
                href="https://www.instagram.com/knotsandkrafts/?hl=en"
                className="w-12 h-12 bg-violet-500 flex items-center justify-center rounded-full transition transform hover:-translate-y-1 hover:shadow-lg"
                aria-label="Instagram"
              >
                <FaInstagram size={20} className="text-white" />
              </a>
              <a
                href="https://wa.me/9480424751"
                className="w-12 h-12 bg-green-500 flex items-center justify-center rounded-full transition transform hover:-translate-y-1 hover:shadow-lg"
                aria-label="WhatsApp"
              >
                <FaWhatsapp size={20} className="text-white" />
              </a>
              <a
                href="https://m.facebook.com/knots.krafts/"
                className="w-12 h-12 bg-blue-600 flex items-center justify-center rounded-full transition transform hover:-translate-y-1 hover:shadow-lg"
                aria-label="Facebook"
              >
                <FaFacebook size={20} className="text-white" />
              </a>
              <a
                href="mailto:knots.krafts@gmail.com"
                className="w-12 h-12 bg-red-500 flex items-center justify-center rounded-full transition transform hover:-translate-y-1 hover:shadow-lg"
                aria-label="Email"
              >
                <FaMailBulk size={20} className="text-white" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="grid grid-cols-2 gap-2 pl-2 mx-auto">
            <div>
              <h5 className="text-gray-400 font-semibold mb-4">Links</h5>
              <ul className="space-y-2">
                <li>
                  <a href="/" className="hover:text-gray-300 transition">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/shop" className="hover:text-gray-300 transition">
                    Shop
                  </a>
                </li>
                <li>
                  <a href="/about" className="hover:text-gray-300 transition">
                    About
                  </a>
                </li>
                <li>
                  <a href="/contact" className="hover:text-gray-300 transition">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h5 className="text-gray-400 font-semibold mb-4">Help</h5>
              <ul className="space-y-2">
                {/* <li>
                  <a href="#" className="hover:text-gray-300 transition">
                    Payment Options
                  </a>
                </li> */}
                <li>
                  <a href="/faq" className="hover:text-gray-300 transition">
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="/policy/refund"
                    className="hover:text-gray-300 transition"
                  >
                    Returns Policies
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center text-gray-500 text-sm">
          <p>© 2023 Knots & Krafts. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
