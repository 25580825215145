import React, { useEffect, useState } from "react";
import { createTag, updateTag, deleteTag, getAllTags } from "../../api";

const TagManagement = () => {
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [editingTagId, setEditingTagId] = useState(null);
  const [editTagName, setEditTagName] = useState("");

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const { data } = await getAllTags();
        setTags(data);
      } catch (error) {
        console.error("Failed to fetch tags", error);
      }
    };
    fetchTags();
  }, []);

  const handleCreateTag = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createTag({ name: tagName });
      setTags([...tags, data]);
      setTagName("");
    } catch (error) {
      console.error("Failed to create tag", error);
    }
  };

  const startEditingTag = (tag) => {
    setEditingTagId(tag._id);
    setEditTagName(tag.name);
  };

  const cancelEditingTag = () => {
    setEditingTagId(null);
    setEditTagName("");
  };

  const handleUpdateTag = async (e) => {
    e.preventDefault();
    try {
      const updatedTag = { name: editTagName };
      await updateTag(editingTagId, updatedTag);
      setTags(
        tags.map((tag) =>
          tag._id === editingTagId ? { ...tag, ...updatedTag } : tag
        )
      );
      cancelEditingTag();
    } catch (error) {
      console.error("Failed to update tag", error);
    }
  };

  const handleDeleteTag = async (id) => {
    try {
      await deleteTag(id);
      setTags(tags.filter((tag) => tag._id !== id));
    } catch (error) {
      console.error("Failed to delete tag", error);
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h3 className="text-3xl font-extrabold text-gray-900 mb-8">
        Tag Management
      </h3>
      <form onSubmit={handleCreateTag} className="mb-6 space-y-4">
        <input
          type="text"
          placeholder="New Tag Name"
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          className="w-full p-3 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 rounded-md transition duration-300 ease-in-out"
        >
          Create Tag
        </button>
      </form>

      <ul className="space-y-8">
        {tags.map((tag) => (
          <li
            key={tag._id}
            className="bg-white p-6 rounded-lg shadow-lg border border-gray-200"
          >
            {editingTagId === tag._id ? (
              <form onSubmit={handleUpdateTag} className="w-full space-y-4">
                <input
                  type="text"
                  value={editTagName}
                  onChange={(e) => setEditTagName(e.target.value)}
                  className="w-full p-3 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <div className="flex space-x-2">
                  <button
                    type="submit"
                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                  >
                    Save
                  </button>
                  <button
                    onClick={cancelEditingTag}
                    type="button"
                    className="bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : (
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium text-gray-900">
                  {tag.name}
                </span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => startEditingTag(tag)}
                    className="bg-pink hover:bg-yellow text-white py-2 px-4 rounded-md transition duration-300 ease-in-out"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteTag(tag._id)}
                    className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TagManagement;
