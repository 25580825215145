// src/components/ContactSection.js
import React, { useRef, useState } from "react";
import { MdEmail, MdLocationOn, MdPhone } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactSection = () => {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Your EmailJS service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Your EmailJS template ID
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID // Your EmailJS user ID
      )
      .then((result) => {
        toast.success("Message sent successfully!");
        e.target.reset(); // Reset the form after successful submission
      })
      .catch((error) => {
        toast.error(
          `Failed to send the message. Please try again. Error: ${
            error.text || error.message
          }`
        );
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <section className="max-w-7xl mx-auto py-12 px-6 sm:px-8">
      {/* Toast Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* Section Title */}
      <div className="text-center mb-12">
        <h2 className="text-3xl sm:text-4xl text-pink-500 font-extrabold text-gray-900">
          Get In Touch With Us
        </h2>
        <p className="mt-4 text-lg text-gray-700 max-w-2xl mx-auto">
          For more information about our products and services, feel free to
          drop us an email. Our team is always here to help. Do not hesitate!
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Contact Information */}
        <div className="space-y-8">
          <div className="flex items-start">
            <MdLocationOn size={30} className="text-pink-600 mt-1" />
            <div className="ml-4">
              <h4 className="text-xl font-semibold text-gray-900">Address</h4>
              <p className="mt-2 text-gray-700">
                <span className="font-semibold">Knots & Krafts</span>
                <br />
                302, JSJ Opal Apartments, BHEL Officers Layout, Jayanagar,
                Bengaluru, Karnataka 560029, India
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <MdPhone size={30} className="text-pink-600 mt-1" />
            <div className="ml-4">
              <h4 className="text-xl font-semibold text-gray-900">Phone</h4>
              <p className="mt-2 text-gray-700">+91 94804 24751</p>
            </div>
          </div>
          <div className="flex items-start">
            <MdEmail size={30} className="text-pink-600 mt-1" />
            <div className="ml-4">
              <h4 className="text-xl font-semibold text-gray-900">Email</h4>
              <p className="mt-2 text-gray-700">
                <a
                  href="mailto:knots.krafts@gmail.com"
                  className="hover:underline"
                >
                  knots.krafts@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <form
          ref={form}
          onSubmit={sendEmail}
          className="md:col-span-2 space-y-6 bg-white p-8"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="user_name"
                className="block text-sm font-medium text-gray-700"
              >
                Your Name
              </label>
              <input
                type="text"
                name="user_name"
                id="user_name"
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                placeholder="Your Name"
                required
                aria-label="Your Name"
              />
            </div>
            <div>
              <label
                htmlFor="user_email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                name="user_email"
                id="user_email"
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                placeholder="you@example.com"
                required
                aria-label="Email Address"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block text-sm font-medium text-gray-700"
            >
              Subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
              placeholder="Subject"
              aria-label="Subject"
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              Message
            </label>
            <textarea
              name="message"
              id="message"
              rows="6"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
              placeholder="Your message..."
              required
              aria-label="Message"
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className={`w-full bg-pink text-white px-6 py-3 rounded-md shadow-sm hover:bg-pink-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 ${
                isSending ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSending}
              aria-label="Send Message"
            >
              {isSending ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
