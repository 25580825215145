// Whatsapp.jsx
import React, { useRef, useEffect } from "react";
import { BsWhatsapp } from "react-icons/bs";
import gsap from "gsap";

const Whatsapp = () => {
  // Refs for GSAP animations
  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    // Initial animation for the WhatsApp button (fade in and slide up)
    gsap.fromTo(
      buttonRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );

    // Initial animation for the tooltip (hidden)
    gsap.set(tooltipRef.current, { opacity: 0, scale: 0.8 });

    // Hover animations
    const handleMouseEnter = () => {
      gsap.to(buttonRef.current, {
        scale: 1.1,
        duration: 0.3,
        ease: "power3.out",
      });
      gsap.to(tooltipRef.current, {
        opacity: 1,
        scale: 1,
        duration: 0.3,
        ease: "power3.out",
      });
    };

    const handleMouseLeave = () => {
      gsap.to(buttonRef.current, {
        scale: 1,
        duration: 0.3,
        ease: "power3.out",
      });
      gsap.to(tooltipRef.current, {
        opacity: 0,
        scale: 0.8,
        duration: 0.3,
        ease: "power3.out",
      });
    };

    // Adding event listeners
    const buttonElement = buttonRef.current;
    buttonElement.addEventListener("mouseenter", handleMouseEnter);
    buttonElement.addEventListener("mouseleave", handleMouseLeave);

    // Cleanup event listeners on unmount
    return () => {
      buttonElement.removeEventListener("mouseenter", handleMouseEnter);
      buttonElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <a
      href="https://wa.me/9480424751" // Replace with your WhatsApp number in international format
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Chat with us on WhatsApp"
      className="fixed z-50 bottom-20 left-2"
    >
      <div
        ref={buttonRef}
        className="relative p-4 bg-green-500 text-white rounded-full shadow-lg cursor-pointer flex items-center justify-center"
      >
        <BsWhatsapp size={30} />

        {/* Animated Tooltip */}
        <span
          ref={tooltipRef}
          className="absolute bottom-full mb-2 px-3 py-1 bg-gray-800 text-white text-xs rounded opacity-0 transform translate-x-1/2 pointer-events-none"
        >
          Chat with us
          <svg
            className="absolute top-full left-1/2 transform -translate-x-1/2"
            width="10"
            height="5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="0,0 5,5 10,0" fill="#2D3748" />
          </svg>
        </span>
      </div>
    </a>
  );
};

export default Whatsapp;
