// src/components/BackToTop.jsx

import React, { useState, useEffect } from "react";
import { BsArrowUp } from "react-icons/bs";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down by 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener("scroll", toggleVisibility);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed z-50 bottom-20 mx-auto right-2 bg-pink  text-white p-1 rounded-md shadow-lg hover:bg-pink transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      } 
      md:right-4 md:bottom-8
      lg:right-6 lg:bottom-10
      `}
    >
      <BsArrowUp size={20} className="w-[20px]" />
    </button>
  );
};

export default BackToTop;
