// src/components/final_components/TopAndLatestProducts.jsx

import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import { fetchProductById } from "../../api"; // Updated import
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";

// Lazy load the ProductCard component
const ProductCard = lazy(() => import("./ProductCard"));

const TopAndLatestProducts = () => {
  // Predefined lists of product IDs
  const topProductIds = [
    "66d40068c96124ed761685da",
    "66d40068c96124ed7616893f",
    "66d40068c96124ed76168796",
    "66d40068c96124ed761689ad",
    // Add more top product IDs as needed
  ];

  const newProductIds = [
    "66d40068c96124ed761689ad",
    "66d40068c96124ed761688e2",
    "66d40068c96124ed76168851",
    "66d40068c96124ed761685a5",
    // Add more new product IDs as needed
  ];

  const [topProducts, setTopProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("new");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const topGridRef = useRef(null);
  const newGridRef = useRef(null);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        setError(null);

        // Function to fetch products by a list of IDs
        const fetchProducts = async (ids) => {
          try {
            const productPromises = ids.map((id) => fetchProductById(id));
            const responses = await Promise.all(productPromises);
            // Extract product data from responses
            const productsData = responses.map((res) => res.data);
            return productsData;
          } catch (err) {
            throw new Error("Failed to fetch some products.");
          }
        };

        // Fetch top products
        const fetchedTopProducts = await fetchProducts(topProductIds);
        setTopProducts(fetchedTopProducts);

        // Fetch new products
        const fetchedNewProducts = await fetchProducts(newProductIds);
        setNewProducts(fetchedNewProducts);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  const activeProducts = activeTab === "top" ? topProducts : newProducts;
  const activeTitle = activeTab === "top" ? "TOP PRODUCTS" : "NEW PRODUCTS";
  const activeGridRef = activeTab === "top" ? topGridRef : newGridRef;

  return (
    <section className="relative container mx-auto py-16 px-4 max-w-6xl sm:px-6 lg:px-16">
      {/* Yarn Heading */}
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-center text-gray-800 mb-8">
        Yarns
      </h1>

      {/* Tabs */}
      <div className="flex justify-center mb-10">
        <button
          onClick={() => setActiveTab("top")}
          className={`px-4 text-sm py-2 font-semibold border-b-2 ${
            activeTab === "top"
              ? "border-[#b799c8] text-[#b799c8]"
              : "border-transparent text-gray-700"
          } transition-all duration-300 hover:text-[#b799c8] hover:border-[#b799c8]`}
        >
          TOP SELLING
        </button>
        <button
          onClick={() => setActiveTab("new")}
          className={`px-4 text-sm py-2 font-semibold border-b-2 ${
            activeTab === "new"
              ? "border-[#b799c8] text-[#b799c8]"
              : "border-transparent text-gray-700"
          } transition-all duration-300 hover:text-[#b799c8] hover:border-[#b799c8]`}
        >
          NEW PRODUCTS
        </button>
      </div>

      {/* Section */}
      <Section
        title={activeTitle}
        products={activeProducts}
        gridRef={activeGridRef}
        loading={loading}
        error={error}
      />

      {/* Shop Now Button */}
      <div className="mt-2 text-center">
        <Link
          to="/shop"
          className="inline-block rounded-md bg-black text-white font-semibold px-8 py-2 hover:bg-[#A6781E] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B88E2F]"
        >
          Shop Now
        </Link>
      </div>
    </section>
  );
};

const Section = ({ title, products, gridRef, loading, error }) => (
  <div className="mb-16">
    <h2 className="text-2xl sm:text-3xl md:text-3xl font-poppins font-extrabold text-center text-gray-800 mb-8 tracking-wide">
      {title}
    </h2>
    {error ? (
      <div className="text-center text-red-500 text-xl py-20">{error}</div>
    ) : (
      <div
        ref={gridRef}
        className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"
      >
        <Suspense fallback={<SkeletonGrid />}>
          {loading
            ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonProductCard key={index} />
              ))
            : Array.isArray(products) &&
              products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
        </Suspense>
      </div>
    )}
  </div>
);

const SkeletonGrid = () => (
  <>
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonProductCard key={index} />
    ))}
  </>
);

const SkeletonProductCard = () => (
  <div className="p-4">
    <Skeleton height={200} />
    <Skeleton height={20} style={{ marginTop: 10 }} />
    <Skeleton height={20} width={80} style={{ marginTop: 10 }} />
  </div>
);

export default TopAndLatestProducts;
