// src/components/final_components/Accessories.jsx

import React, { useEffect, useState, useRef } from "react";
import ProductCard from "./ProductCard";
import { fetchProductById } from "../../api"; // Adjust the import path as needed
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Moved accessoryIds outside the component to prevent redefinition on each render
const accessoryIds = {
  featured: [
    "66daef48c96124ed76c7aff6",
    "67175e77863240f0b8560f22",
    "67175b43863240f0b8560f1f",
    // Add more IDs as needed
  ],
  popular: [
    "66daef48c96124ed76c7aff6",
    "67175e77863240f0b8560f22",
    "67175b43863240f0b8560f1f",
    // Add more IDs as needed
  ],
};

const Accessories = () => {
  const [accessories, setAccessories] = useState({
    featured: [],
    popular: [],
  });
  const [activeTab, setActiveTab] = useState("featured");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const featuredGridRef = useRef(null);
  const popularGridRef = useRef(null);

  // Function to fetch accessory data
  const loadAccessory = async (tab) => {
    try {
      setLoading(true);
      const fetchPromises = accessoryIds[tab].map((id) => fetchProductById(id));
      const responses = await Promise.all(fetchPromises);
      const products = responses
        .map((res) => res.data)
        .filter((product) => product);
      setAccessories((prev) => ({
        ...prev,
        [tab]: products,
      }));
    } catch {
      setError("Failed to load accessories.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when activeTab changes, if not already loaded
  useEffect(() => {
    if (accessories[activeTab].length === 0) {
      loadAccessory(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // Handle tab switching
  const handleTabSwitch = (tab) => {
    if (tab === activeTab) return;
    setActiveTab(tab);
  };

  // Determine active products based on the active tab
  const activeProducts =
    activeTab === "featured" ? accessories.featured : accessories.popular;
  const activeGridRef =
    activeTab === "featured" ? featuredGridRef : popularGridRef;
  const activeTitle =
    activeTab === "featured" ? "Top Selling Accessories" : "New Products";

  return (
    <section className="relative container mx-auto pb-16 px-4 max-w-6xl sm:px-6 lg:px-16">
      {/* Accessories Heading */}
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-center text-gray-800 mb-8">
        Accessories
      </h1>

      {/* Tabs for switching between Featured and Popular Accessories */}
      <div className="flex justify-center mb-10">
        <button
          onClick={() => handleTabSwitch("featured")}
          className={`ml-8 px-4 text-sm py-2 font-semibold border-b-2 ${
            activeTab === "featured"
              ? "border-[#b799c8] text-[#b799c8]"
              : "border-transparent text-gray-700"
          } transition-all text-md duration-300 hover:text-[#b799c8] hover:border-[#b799c8]`}
          aria-pressed={activeTab === "featured"}
        >
          Top Selling
        </button>
        <button
          onClick={() => handleTabSwitch("popular")}
          className={`px-4 text-sm py-2 font-semibold border-b-2 ${
            activeTab === "popular"
              ? "border-[#b799c8] text-[#b799c8]"
              : "border-transparent text-gray-700"
          } transition-all duration-300 hover:text-[#b799c8] hover:border-[#b799c8]`}
          aria-pressed={activeTab === "popular"}
        >
          New Products
        </button>
      </div>

      {/* Content Area */}
      {loading ? (
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {Array.from({ length: 8 }).map((_, index) => (
            <SkeletonProductCard key={index} />
          ))}
        </div>
      ) : error ? (
        <div className="text-center text-red-500 text-xl py-20">{error}</div>
      ) : activeProducts.length > 0 ? (
        <Section
          title={activeTitle}
          products={activeProducts}
          gridRef={activeGridRef}
        />
      ) : (
        <div className="text-center text-xl py-20 text-gray-700">
          No accessories found.
        </div>
      )}

      {/* Shop Now Button */}
      <div className="mt-4 text-center">
        <Link
          to="/shop"
          className="inline-block rounded-md bg-black text-white font-semibold px-8 py-2 hover:bg-[#A6781E] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B88E2F]"
        >
          Shop Now
        </Link>
      </div>
    </section>
  );
};

// Reusable Section Component
const Section = ({ title, products, gridRef }) => (
  <div className="mb-16">
    <h2 className="text-2xl sm:text-3xl md:text-3xl font-poppins font-extrabold text-center text-gray-800 mb-8 tracking-wide">
      {title}
    </h2>
    <div
      ref={gridRef}
      className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
    >
      {products.map((product) => (
        <ProductCard key={product._id} product={product} />
      ))}
    </div>
  </div>
);

// Skeleton Loader for Product Cards
const SkeletonProductCard = () => (
  <div className="p-4 border rounded-md shadow-sm">
    <Skeleton height={200} />
    <Skeleton height={20} style={{ marginTop: 10 }} />
    <Skeleton height={20} width={80} style={{ marginTop: 10 }} />
  </div>
);

export default Accessories;
