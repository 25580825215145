// src/components/admin/AdminDashboard.jsx

import React, {
  useState,
  useRef,
  useEffect,
  Suspense,
  lazy,
  useContext,
} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import { Menu } from "@headlessui/react"; // For accessible dropdowns
// import { ClipLoader } from "react-spinners";
import Loader from "../../components/final_components/loaders/Loader";

// Register GSAP Plugins
gsap.registerPlugin(ScrollTrigger);

// Lazy load management components for performance optimization
const AdminOrders = lazy(() =>
  import("../../components/final_components/AdminOrders")
);
const CouponManager = lazy(() =>
  import("../../components/Coupon/CouponManager")
);
const ProductManagement = lazy(() =>
  import("../../components/final_components/ProductManagement")
);
const CategoryManagement = lazy(() =>
  import("../../components/final_components/CategoryManagement ")
);
// const UserManagement = lazy(() =>
//   import("../../components/final_components/UserManagement")
// );
const TagManagement = lazy(() =>
  import("../../components/final_components/TagsManagement")
);

// Define tabs with names and corresponding components
const tabs = [
  { name: "Orders", component: <AdminOrders /> },
  { name: "Coupons", component: <CouponManager /> },
  // { name: "Users", component: <UserManagement /> },
  { name: "Categories", component: <CategoryManagement /> },
  { name: "Tags", component: <TagManagement /> },
  // { name: "Products", component: <ProductManagement /> },
];

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const contentRef = useRef(null);
  const tabsRef = useRef([]);

  // Detect if the device is mobile
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // GSAP Animation for Tab Content with Sliding Effect
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { duration: 0.5, ease: "power3.out" },
    });

    tl.fromTo(contentRef.current, { opacity: 0, x: 50 }, { opacity: 1, x: 0 });

    return () => {
      tl.kill();
    };
  }, [activeTab]);

  // GSAP Animation for Active Tab Indicator
  useEffect(() => {
    const activeIndex = tabs.findIndex((tab) => tab.name === activeTab);
    if (tabsRef.current[activeIndex]) {
      gsap.to(tabsRef.current[activeIndex], {
        borderBottomWidth: 3,
        borderColor: "#3b82f6", // Tailwind's blue-600
        duration: 0.3,
        ease: "power3.out",
      });
    }

    // Reset border for other tabs
    tabsRef.current.forEach((tab, index) => {
      if (index !== activeIndex) {
        gsap.to(tab, {
          borderBottomWidth: 0,
          borderColor: "transparent",
          duration: 0.3,
          ease: "power3.out",
        });
      }
    });
  }, [activeTab]);

  return (
    <div className="pt-[10vh] px-4 sm:px-6 lg:px-8">
      {/* Tab Navigation */}
      <div className="mb-6">
        {isMobile ? (
          // Dropdown for Mobile Devices
          <div className="relative inline-block text-left w-full">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                    {activeTab}
                    <svg
                      className="-mr-1 ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.14.976l-4.25 4.5a.75.75 0 01-1.14 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Menu.Button>

                  <Menu.Items className="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1">
                      {tabs.map((tab) => (
                        <Menu.Item key={tab.name}>
                          {({ active }) => (
                            <button
                              onClick={() => setActiveTab(tab.name)}
                              className={`${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              } block w-full text-left px-4 py-2 text-sm`}
                            >
                              {tab.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </>
              )}
            </Menu>
          </div>
        ) : (
          // Grid Layout for Desktop and Tablet Devices
          <nav className="grid grid-cols-2 sm:grid-cols-3 lg:flex lg:grid-cols-none gap-2 pb-2 border-b">
            {tabs.map((tab, index) => (
              <button
                key={tab.name}
                ref={(el) => (tabsRef.current[index] = el)}
                onClick={() => setActiveTab(tab.name)}
                className={`w-full text-center px-4 py-2 text-sm font-medium focus:outline-none ${
                  activeTab === tab.name
                    ? "text-blue-600 border-blue-600"
                    : "text-gray-600 hover:text-blue-600"
                } border-b-2`}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        )}
      </div>

      {/* Tab Content */}
      <div ref={contentRef} className="min-h-[60vh]">
        <Suspense
          fallback={
            <div className="flex justify-center items-center h-64">
              <Loader size={50} color="#3b82f6" />
            </div>
          }
        >
          {tabs.map(
            (tab) =>
              tab.name === activeTab && (
                <div key={tab.name} className="animate-fadeIn">
                  {tab.component}
                </div>
              )
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default AdminDashboard;
