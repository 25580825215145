// src/components/About.js

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* SEO Optimization with React Helmet */}
      <Helmet>
        <title>About Us - Knots and Krafts</title>
        <meta
          name="description"
          content="Discover Knots and Krafts, your destination for high-quality yarns and crafting materials. Learn about our journey and what we offer."
        />
      </Helmet>

      {/* Header Section */}
      <header className="relative h-[30vh] md:h-[40vh] bg-gray-800 flex flex-col justify-center items-center text-center">
        {/* Background Image */}
        <img
          src="./shop_hero.jpg"
          alt="Knots and Krafts Background"
          className="absolute inset-0 w-full h-full object-cover opacity-70"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
        {/* Content */}
        <div className="relative z-10 text-white px-4">
          <h1 className="text-4xl md:text-5xl font-extrabold">About Us</h1>

          <nav className="text-white text-sm mt-2">
            <Link to="/" className="hover:underline">
              Home
            </Link>
            <span className="mx-2">&gt;</span>
            <span className="text-white">About</span>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto py-12 px-6 sm:px-8">
        {/* About Section */}
        <section className="flex flex-col-reverse lg:flex-row items-center lg:items-start">
          {/* Text Content */}
          <div className="lg:w-1/2 lg:pr-12">
            <h2 className="text-3xl sm:text-4xl font-bold text-pink-600">
              About Knots and Krafts
            </h2>
            <p className="mt-6 text-gray-700 text-lg leading-relaxed">
              Knots and Krafts was born out of the idea of bringing novel yarns
              to India by an enthusiastic yarn hoarder for the budding crochet
              and knitting community.
            </p>
            <p className="mt-4 text-gray-700 text-lg leading-relaxed">
              We are dedicated to curating and delivering the finest yarns from
              around the world straight to your doorstep, all in a
              cost-effective manner.
            </p>
            <p className="mt-4 text-gray-700 text-lg leading-relaxed">
              Our mission is to offer a wide range of beautiful, sustainable,
              and high-quality yarns to help you craft your next masterpiece
              with love and care.
            </p>
            <div className="mt-8 w-full mx-auto">
              <Link
                to="/shop"
                className="inline-block px-8 bg-pink py-3 bg-gradient-to-r from-pink-500 to-pink-600 text-white rounded-md font-semibold hover:from-pink-600 hover:to-pink-700 transition duration-300 shadow-lg"
              >
                Shop Now
              </Link>
            </div>
          </div>
          {/* Image */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src="./faq.jpg"
              alt="Knots and Krafts Yarn Collection"
              className="w-full h-auto rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
        </section>

        {/* What We Offer Section */}
        <section className="mt-20">
          <h2 className="text-3xl sm:text-4xl font-bold text-pink-600 text-center">
            What We Offer
          </h2>
          <p className="mt-4 text-lg text-gray-700 text-center max-w-2xl mx-auto">
            At Knots and Krafts, we provide a curated selection of high-quality
            yarns, fibers, and materials to bring your crochet and knitting
            projects to life.
          </p>

          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {/* Acrylic */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl text-pink-600 font-semibold">Acrylic</h3>
              <p className="mt-4 text-gray-600">
                A versatile, durable, and affordable yarn perfect for both
                beginners and experts alike.
              </p>
            </div>

            {/* Wool */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl text-pink-600 font-semibold">Wool</h3>
              <p className="mt-4 text-gray-600">
                Luxurious wool yarns that are soft and warm, ideal for cozy
                winter garments and accessories.
              </p>
            </div>

            {/* Cakes */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl text-pink-600 font-semibold">Cakes</h3>
              <p className="mt-4 text-gray-600">
                Colorful cake yarns with long color transitions, perfect for
                creating stunning scarves and blankets.
              </p>
            </div>

            {/* Amigurumi */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl text-pink-600 font-semibold">Amigurumi</h3>
              <p className="mt-4 text-gray-600">
                Specially designed yarns for crafting adorable and detailed
                crochet amigurumi figures.
              </p>
            </div>

            {/* Mohair */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl text-pink-600 font-semibold">Mohair</h3>
              <p className="mt-4 text-gray-600">
                Luxurious and soft, mohair yarn adds an elegant, airy texture to
                your knitting projects.
              </p>
            </div>

            {/* T-Shirt Yarn */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl text-pink-600 font-semibold">
                T-Shirt Yarn
              </h3>
              <p className="mt-4 text-gray-600">
                Eco-friendly and strong, perfect for home décor projects like
                rugs and baskets.
              </p>
            </div>
          </div>

          <div className="mt-12 text-center">
            <Link
              to="/shop"
              className="inline-block px-8 py-3  bg-pink bg-gradient-to-r from-pink-500 to-pink-600 text-white font-semibold rounded-md hover:from-pink-600 hover:to-pink-700 transition duration-300 shadow-lg"
            >
              Explore More Yarns
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;
