// src/components/FilterComponents.jsx

import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BiFilter } from "react-icons/bi";

// Container for the entire filter component
const FilterContainer = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 1rem 0;

  @media (min-width: 768px) {
    max-width: 350px;
    padding: 1.5rem;
  }
`;

// Each filter section (Category, Tags, etc.)
const FilterSection = styled.div`
  margin-bottom: 1.5rem;

  @media (max-width: 640px) {
    margin-bottom: 1rem;
  }
`;

// Header for each section containing the icon and title
const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

// Title of each filter section
const SectionTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 640px) {
    font-size: 1rem;
  }
`;

// Wrapper for the select dropdown
const StyledSelectWrapper = styled.div`
  position: relative;
`;

// Label for the select dropdown
const StyledSelectLabel = styled.label`
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #555555;
  margin-bottom: 0.3rem;

  @media (max-width: 640px) {
    font-size: 0.85rem;
  }
`;

// Styled select dropdown
const StyledSelectDropdown = styled.select`
  width: 100%;
  padding: 0.6rem 1rem;
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  appearance: none;
  font-size: 0.95rem;
  transition: border-color 0.3s ease;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #6666ff;
    box-shadow: 0 0 0 3px rgba(102, 102, 255, 0.2);
  }

  /* Custom Chevron Icon */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 320 512'%3E%3Cpath d='M31.1 192h257.8c28.4 0 42.6 34.5 22.6 54.6l-128.9 136.3c-12.5 13.2-32.8 13.2-45.3 0L8.5 246.6C-11.6 226.5 2.6 192 31.1 192z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;

  @media (max-width: 640px) {
    padding: 0.5rem 0.8rem;
    font-size: 0.85rem;
  }
`;

// Container for the price range slider
const PriceRangeContainer = styled.div`
  margin-top: 0.5rem;

  h4 {
    font-size: 0.9rem;
    font-weight: 500;
    color: #555555;
    margin-bottom: 0.5rem;

    @media (max-width: 640px) {
      font-size: 0.8rem;
    }
  }

  /* Slider Styles */
  .slider-container {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: #dddddd;
    border-radius: 3px;
    margin: 20px 0;
  }

  /* Slider track between min and max */
  .slider-track-selected {
    position: absolute;
    height: 6px;
    background-color: #6666ff;
    border-radius: 3px;
    top: 0;
    left: 0;
    width: 0%;
  }

  /* Thumbs */
  .slider-thumb {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border: 2px solid #6666ff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0 2px rgba(102, 102, 255, 0.2);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    z-index: 2;

    &:hover,
    &:focus {
      border-color: #5555ee;
      box-shadow: 0 0 0 2px rgba(85, 85, 238, 0.3);
    }

    @media (max-width: 640px) {
      width: 18px;
      height: 18px;
    }
  }

  /* Input range styles */
  .range-input {
    position: absolute;
    width: 100%;
    height: 6px;
    top: 0;
    left: 0;
    pointer-events: all; /* Allow interactions */
    opacity: 0; /* Make them invisible but interactive */
  }

  /* z-index to prioritize max over min */
  .range-min {
    z-index: 3;
  }

  .range-max {
    z-index: 4;
  }
`;

// Styled Apply Filters button
const ApplyButton = styled.button`
  width: 100%;
  padding: 0.7rem;
  background-color: #6666ff;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #5555ee;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #4444dd;
    transform: translateY(0);
  }

  @media (max-width: 640px) {
    padding: 0.6rem;
    font-size: 0.85rem;
  }
`;

// Wrapper for the Sort By select dropdown
const SortSelectWrapper = styled(StyledSelectWrapper)`
  margin-top: 0.5rem;
`;

const FilterComponents = ({
  categories,
  tags,
  brands,
  selectedCategory,
  setSelectedCategory,
  selectedTag,
  setSelectedTag,
  selectedBrand,
  setSelectedBrand,
  priceRange,
  handlePriceChange,
  sortBy,
  setSortBy,
  handleApplyFilters,
}) => {
  const [minVal, setMinVal] = React.useState(priceRange[0]);
  const [maxVal, setMaxVal] = React.useState(priceRange[1]);

  const min = 0;
  const max = 1000;

  const rangeRef = useRef(null);

  // Update the slider track
  useEffect(() => {
    if (rangeRef.current) {
      const minPercent = (minVal / max) * 100;
      const maxPercent = (maxVal / max) * 100;
      rangeRef.current.style.left = `${minPercent}%`;
      rangeRef.current.style.width = `${maxPercent - minPercent}%`;
    }
    handlePriceChange([minVal, maxVal]);
  }, [minVal, maxVal, handlePriceChange, max]);

  const handleMinChange = (e) => {
    const value = Math.min(Number(e.target.value), maxVal - 10);
    setMinVal(value);
  };

  const handleMaxChange = (e) => {
    const value = Math.max(Number(e.target.value), minVal + 10);
    setMaxVal(value);
  };

  return (
    <FilterContainer>
      {/* Category Filter */}
      <FilterSection>
        <SectionHeader>
          <SectionTitle>
            <BiFilter /> Category
          </SectionTitle>
        </SectionHeader>
        <StyledSelectWrapper>
          <StyledSelectLabel htmlFor="category-select">
            Select Category
          </StyledSelectLabel>
          <StyledSelectDropdown
            id="category-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </StyledSelectDropdown>
        </StyledSelectWrapper>
      </FilterSection>

      {/* Tag Filter */}
      <FilterSection>
        <SectionHeader>
          <SectionTitle>
            <BiFilter /> Tags
          </SectionTitle>
        </SectionHeader>
        <StyledSelectWrapper>
          <StyledSelectLabel htmlFor="tag-select">Select Tag</StyledSelectLabel>
          <StyledSelectDropdown
            id="tag-select"
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
          >
            <option value="">All Tags</option>
            {tags.map((tag) => (
              <option key={tag._id} value={tag._id}>
                {tag.name}
              </option>
            ))}
          </StyledSelectDropdown>
        </StyledSelectWrapper>
      </FilterSection>

      {/* Brand Filter */}
      <FilterSection>
        <SectionHeader>
          <SectionTitle>
            <BiFilter /> Brands
          </SectionTitle>
        </SectionHeader>
        <StyledSelectWrapper>
          <StyledSelectLabel htmlFor="brand-select">
            Select Brand
          </StyledSelectLabel>
          <StyledSelectDropdown
            id="brand-select"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="">All Brands</option>
            {Array.isArray(brands) &&
              brands.map((brand, idx) => (
                <option key={idx} value={brand}>
                  {brand}
                </option>
              ))}
          </StyledSelectDropdown>
        </StyledSelectWrapper>
      </FilterSection>

      {/* Price Range Filter */}
      <FilterSection>
        <SectionHeader>
          <SectionTitle>
            <BiFilter /> Price Range
          </SectionTitle>
        </SectionHeader>
        <PriceRangeContainer>
          <h4>
            ₹{minVal} - ₹{maxVal}
          </h4>
          <div className="slider-container">
            {/* Slider Track */}
            <div className="slider-track"></div>
            {/* Selected Range Track */}
            <div
              className="slider-track slider-track-selected"
              ref={rangeRef}
            ></div>

            {/* Min Range Input */}
            <input
              type="range"
              min={min}
              max={max}
              value={minVal}
              onChange={handleMinChange}
              className="range-input range-min"
            />

            {/* Max Range Input */}
            <input
              type="range"
              min={min}
              max={max}
              value={maxVal}
              onChange={handleMaxChange}
              className="range-input range-max"
            />

            {/* Visual Thumbs */}
            <div
              className="slider-thumb"
              style={{ left: `${(minVal / max) * 100}%` }}
              aria-label="Minimum price"
              role="slider"
              aria-valuemin={min}
              aria-valuemax={max}
              aria-valuenow={minVal}
              tabIndex={0}
            ></div>
            <div
              className="slider-thumb"
              style={{ left: `${(maxVal / max) * 100}%` }}
              aria-label="Maximum price"
              role="slider"
              aria-valuemin={min}
              aria-valuemax={max}
              aria-valuenow={maxVal}
              tabIndex={0}
            ></div>
          </div>
        </PriceRangeContainer>
      </FilterSection>

      {/* Sort By Filter */}
      <FilterSection>
        <SectionHeader>
          <SectionTitle>
            <BiFilter /> Sort By
          </SectionTitle>
        </SectionHeader>
        <SortSelectWrapper>
          <StyledSelectLabel htmlFor="sort-select">
            Sort Products
          </StyledSelectLabel>
          <StyledSelectDropdown
            id="sort-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="bestSelling">Best Selling</option>
            <option value="newest">Newest</option>
            <option value="rating">Rating</option>
            <option value="priceLowToHigh">Price: Low to High</option>
            <option value="priceHighToLow">Price: High to Low</option>
          </StyledSelectDropdown>
        </SortSelectWrapper>
      </FilterSection>

      {/* Apply Filters Button */}
      <FilterSection>
        <ApplyButton onClick={handleApplyFilters}>
          <BiFilter /> Apply Filters
        </ApplyButton>
      </FilterSection>
    </FilterContainer>
  );
};

FilterComponents.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedTag: PropTypes.string.isRequired,
  setSelectedTag: PropTypes.func.isRequired,
  selectedBrand: PropTypes.string.isRequired,
  setSelectedBrand: PropTypes.func.isRequired,
  priceRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  handlePriceChange: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
};

export default FilterComponents;
