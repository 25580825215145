// src/components/admin/AdminOrders.jsx

import React, { useState, useEffect, useRef, useMemo } from "react";
import { getAllOrdersAdmin, updateOrderStatus } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaFilter, FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Define status colors for border styling
const statusColors = {
  placed: "border-red-500",
  processing: "border-yellow-500",
  shipped: "border-blue-500",
  completed: "border-green-500",
};

// Define possible status options
const statusOptions = ["placed", "processing", "shipped", "completed"];

// Helper function to extract price
const getPrice = (priceObj) => {
  if (typeof priceObj === "object" && priceObj !== null) {
    return priceObj.sale || priceObj.regular || 0;
  }
  return priceObj || 0;
};

// Placeholder image for products without an image
const placeholderImage = "https://via.placeholder.com/64";

// Lazy load images using the loading attribute
const LazyImage = ({ src, alt }) => (
  <img
    src={src || placeholderImage}
    alt={alt || "Product Image"}
    loading="lazy"
    className="w-16 h-16 object-cover rounded"
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = placeholderImage;
    }}
  />
);

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState({});
  const [activeStatus, setActiveStatus] = useState("placed"); // Default status
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [trackingCompany, setTrackingCompany] = useState("");
  const [trackingID, setTrackingID] = useState("");
  const [statusToUpdate, setStatusToUpdate] = useState("");

  // State for date filtering
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const tabsRef = useRef([]);
  const contentRef = useRef(null);

  // Fetch all orders when the component mounts or activeStatus changes
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);
      try {
        const { data } = await getAllOrdersAdmin();
        console.log("Fetched orders:", data); // Log data to verify structure
        const filteredByStatus = data.filter(
          (order) => order.status.toLowerCase() === activeStatus
        );
        setOrders(filteredByStatus);
        setLoading(false);
      } catch (err) {
        setError("Failed to load orders.");
        setLoading(false);
        toast.error("Failed to load orders.");
        console.error("Error fetching orders:", err);
      }
    };

    fetchOrders();
  }, [activeStatus]);

  // Apply date filtering
  useEffect(() => {
    if (startDate && endDate) {
      const filtered = orders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        return (
          orderDate >= startDate.setHours(0, 0, 0, 0) &&
          orderDate <= endDate.setHours(23, 59, 59, 999)
        );
      });
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orders);
    }
  }, [orders, startDate, endDate]);

  // GSAP Animation for Tab Content
  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 0.5, ease: "power3.out" }
    );
  }, [filteredOrders]);

  // GSAP Animation for Active Tab Indicator
  useEffect(() => {
    gsap.to(tabsRef.current, {
      borderBottomWidth: 0,
      duration: 0.3,
      ease: "power3.out",
      stagger: 0.05,
    });

    const activeIndex = statusOptions.indexOf(activeStatus);
    if (tabsRef.current[activeIndex]) {
      gsap.to(tabsRef.current[activeIndex], {
        borderBottomWidth: 2,
        borderColor: "#3b82f6", // Tailwind's blue-600
        duration: 0.3,
        ease: "power3.out",
      });
    }
  }, [activeStatus]);

  // Handle status change for an order
  const handleStatusChange = async (orderId, status) => {
    try {
      if (status === "shipped") {
        // Open modal to get tracking details
        setCurrentOrderId(orderId);
        setStatusToUpdate(status);
        setModalIsOpen(true);
      } else {
        const { data } = await updateOrderStatus(orderId, status);

        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: data.status } : order
          )
        );
        setStatusUpdate((prevState) => ({
          ...prevState,
          [orderId]: "updated",
        }));
        toast.success("Order status updated.");
      }
    } catch (err) {
      setError("Failed to update order status.");
      toast.error("Failed to update order status.");
      console.error("Error updating order status:", err);
    }
  };

  // Handle submission of tracking details
  const handleModalSubmit = async () => {
    if (!trackingCompany.trim() || !trackingID.trim()) {
      toast.error("Tracking details are required.");
      return;
    }

    try {
      const { data } = await updateOrderStatus(currentOrderId, statusToUpdate, {
        trackingCompany,
        trackingID,
      });

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === currentOrderId
            ? {
                ...order,
                status: data.status,
                trackingCompany: data.trackingCompany || trackingCompany,
                trackingID: data.trackingID || trackingID,
              }
            : order
        )
      );
      setStatusUpdate((prevState) => ({
        ...prevState,
        [currentOrderId]: "updated",
      }));
      setModalIsOpen(false);
      setTrackingCompany("");
      setTrackingID("");
      setCurrentOrderId(null);
      setStatusToUpdate("");
      toast.success("Order status updated with tracking details.");
    } catch (err) {
      setError("Failed to update order status with tracking details.");
      toast.error("Failed to update order status with tracking details.");
      console.error("Error updating order with tracking details:", err);
    }
  };

  // Render tabs for order statuses
  const renderTabs = () => (
    <div className="flex flex-wrap justify-center gap-2">
      {statusOptions.map((status, index) => (
        <button
          key={status}
          ref={(el) => (tabsRef.current[index] = el)}
          onClick={() => setActiveStatus(status)}
          className={`flex items-center px-4 py-2 font-medium text-sm rounded-md whitespace-nowrap transition-colors duration-300 ${
            activeStatus === status
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
          } border-b-2`}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </button>
      ))}
    </div>
  );

  // Render date filter
  const renderDateFilter = () => (
    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-8">
      <div className="flex items-center space-x-2">
        <FaCalendarAlt className="text-gray-600" />
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          isClearable
          placeholderText="Start Date"
          className="px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div className="flex items-center space-x-2">
        <FaCalendarAlt className="text-gray-600" />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          isClearable
          placeholderText="End Date"
          className="px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <button
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
        }}
        className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-300"
      >
        <FaFilter className="mr-2" />
        Clear Filters
      </button>
    </div>
  );

  // Memoize orders to prevent unnecessary re-renders
  const displayedOrders = useMemo(() => filteredOrders, [filteredOrders]);

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      {/* SEO Meta Tags using react-helmet */}
      <Helmet>
        <title>Admin Orders | Your Store</title>
        <meta
          name="description"
          content="Manage all customer orders, update their status, and view order details."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <h2 className="text-4xl font-extrabold text-gray-900 mb-8 text-center">
        All Orders
      </h2>

      {/* Tabs for Order Status */}
      <div className="mb-8">{renderTabs()}</div>

      {/* Date Filter */}
      {renderDateFilter()}

      {/* Loading, Error, and Orders Display */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader text-blue-600 text-xl">Loading...</div>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center">{error}</div>
      ) : displayedOrders.length === 0 ? (
        <div className="text-center text-gray-600">
          No orders in this category or date range.
        </div>
      ) : (
        <div className="space-y-8" ref={contentRef}>
          {displayedOrders.map((order) => (
            <div
              key={order._id}
              className={`bg-white p-6 rounded-lg shadow-lg border-l-4 ${
                statusColors[order.status?.toLowerCase()] || "border-gray-500"
              }`}
            >
              <div className="flex flex-col md:flex-row md:justify-between md:items-center text-gray-700">
                <div>
                  <h3 className="text-2xl font-semibold mb-2">
                    Order ID: {order._id || "N/A"}
                  </h3>
                  <p className="text-gray-600">
                    Placed on:{" "}
                    {order.createdAt
                      ? new Date(order.createdAt).toLocaleDateString()
                      : "N/A"}
                  </p>

                  {/* Customer Details */}
                  <p className="text-gray-600">
                    Customer:{" "}
                    {order.customerDetails
                      ? `${order.customerDetails.firstName} ${order.customerDetails.lastName}`
                      : "N/A"}
                  </p>
                  <p className="text-gray-600">
                    Email:{" "}
                    {order.customerDetails
                      ? order.customerDetails.email
                      : order.user?.email || "N/A"}
                  </p>
                  <p className="text-gray-600">
                    Phone:{" "}
                    {order.customerDetails
                      ? order.customerDetails.phone
                      : order.user?.phone || "N/A"}
                  </p>

                  {/* Payment Method */}
                  <p className="text-gray-600">
                    Payment Method: {order.paymentMethod || "N/A"}
                  </p>

                  {/* Total Price */}
                  <p className="text-gray-600">
                    Total: ₹{" "}
                    {typeof order.totalPrice === "object"
                      ? getPrice(order.totalPrice).toFixed(2)
                      : order.totalPrice?.toFixed(2) || "0.00"}
                  </p>

                  {/* Shipping Address */}
                  <div className="text-gray-600 mt-2">
                    <strong>Shipping Address:</strong> <br />
                    {order.shippingAddress?.address || "N/A"}, <br />
                    {order.shippingAddress?.city || "N/A"},{" "}
                    {order.shippingAddress?.postalCode || "N/A"}, <br />
                    {order.shippingAddress?.country || "N/A"}
                  </div>

                  {/* Tracking Info */}
                  {order.trackingID && (
                    <div className="text-gray-600 mt-2">
                      <strong>Tracking Info:</strong> <br />
                      Company: {order.trackingCompany || "N/A"} <br />
                      Tracking ID: {order.trackingID || "N/A"}
                    </div>
                  )}
                </div>
                <div className="mt-4 md:mt-0 w-full md:w-1/3">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Order Status
                  </label>
                  <select
                    value={order.status || "placed"}
                    onChange={(e) =>
                      handleStatusChange(order._id, e.target.value)
                    }
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    {statusOptions.map((status) => (
                      <option key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </option>
                    ))}
                  </select>
                  {statusUpdate[order._id] === "updated" && (
                    <p className="text-green-500 mt-2">Status updated</p>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <h4 className="font-semibold text-gray-800">Items Ordered</h4>
                {Array.isArray(order.orderItems) &&
                order.orderItems.length > 0 ? (
                  <ul className="mt-4 space-y-4">
                    {order.orderItems.map((item) => (
                      <li key={item._id} className="flex justify-between">
                        <div className="flex items-center space-x-4">
                          <LazyImage
                            src={
                              item.image
                                ? `https://api.knotsandkrafts.in/${item.image}`
                                : placeholderImage
                            }
                            alt={item.name || "Product Image"}
                          />
                          <div>
                            <p className="text-gray-800 font-medium">
                              {item.name || "N/A"}
                            </p>
                            <p className="text-gray-500">
                              Quantity: {item.quantity || 0}
                            </p>
                            <p className="text-gray-500">
                              Price: ₹{" "}
                              {typeof item.price === "object"
                                ? getPrice(item.price).toFixed(2)
                                : item.price?.toFixed(2) || "0.00"}
                            </p>
                          </div>
                        </div>
                        <span className="text-gray-700">
                          ₹{" "}
                          {typeof item.price === "object"
                            ? (
                                getPrice(item.price) * (item.quantity || 0)
                              ).toFixed(2)
                            : (
                                (item.price || 0) * (item.quantity || 0)
                              ).toFixed(2)}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-600">
                    No items found for this order.
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal for Tracking Details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Tracking Details"
        className="bg-white rounded-lg max-w-md mx-auto p-6 mt-24 shadow-lg transition-all duration-300"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        ariaHideApp={false}
      >
        <h2 className="text-xl font-semibold mb-4">Enter Tracking Details</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Tracking Company
          </label>
          <input
            type="text"
            value={trackingCompany}
            onChange={(e) => setTrackingCompany(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="e.g., FedEx, UPS"
            aria-label="Tracking Company"
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Tracking ID
          </label>
          <input
            type="text"
            value={trackingID}
            onChange={(e) => setTrackingID(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter Tracking ID"
            aria-label="Tracking ID"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleModalSubmit}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminOrders;

